import React, { Dispatch, FC, MouseEvent, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    height: 25,
    position: "relative",
    width: "100%",
    userSelect: "none",
  },
  scrollThumb: {
    position: "relative",
    height: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    cursor: "grab",
    "&:active": {
      cursor: "grabbing",
    },
    "&:hover": {
      opacity: 1,
    },
  },
}));

let clickPosition: undefined | number;
let offset: undefined | number;

interface Props {
  scrollBarWidth: number;
  scrollOffset: number;
  setScrollOffset: Dispatch<SetStateAction<number>>;
  setIsScrolling: Dispatch<SetStateAction<boolean>>;
  maxScrollOffset: number;
}

const Scroll: FC<Props> = ({ scrollBarWidth, scrollOffset, maxScrollOffset, setScrollOffset, setIsScrolling }) => {
  const classes = useStyles();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [style, setStyle] = useState<any>({ width: 0, left: scrollOffset + "px" });

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!scrollContainerRef.current) return;

    const rect = scrollContainerRef.current.getBoundingClientRect();
    const containerWidth = rect.width;
    clickPosition = Math.max(0, Math.min(e.clientX - rect.left, containerWidth));

    const target: any = e.nativeEvent.target;
    offset = Number.parseInt(target.style.left);
  };

  const onMouseMove = useCallback(
    (e: any) => {
      if (!scrollContainerRef.current) return;
      if (clickPosition === undefined) return;
      if (offset === undefined) return;
      setIsScrolling(true);

      const rect = scrollContainerRef.current.getBoundingClientRect();
      const containerWidth = rect.width;

      const maxThumbPosition = containerWidth - scrollBarWidth;
      const relativeX = Math.max(0, Math.min(e.clientX - rect.left, containerWidth));
      const position = Math.max(0, Math.min(offset + (relativeX - clickPosition), maxThumbPosition));

      // расчет scrollOffset
      const newScrollOffset = (position / maxThumbPosition) * maxScrollOffset;
      setScrollOffset(Math.max(0, Math.min(newScrollOffset, maxScrollOffset)));
    },
    [maxScrollOffset, scrollBarWidth, setScrollOffset, setIsScrolling]
  );

  const onMouseUp = useCallback(() => {
    clickPosition = undefined;
    offset = undefined;
    setIsScrolling(false);
  }, [setIsScrolling]);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const containerWidth = scrollContainerRef.current.offsetWidth;
    const maxPosition = containerWidth - scrollBarWidth;

    // расчет позиции
    const left = Math.min(maxPosition, (scrollOffset / maxScrollOffset) * maxPosition);

    setStyle({
      width: `${scrollBarWidth}px`,
      left: `${left}px`,
    });
  }, [scrollOffset, maxScrollOffset, scrollBarWidth]);

  return (
    <div ref={scrollContainerRef} className={classes.scrollContainer}>
      <div className={classes.scrollThumb} style={style} onMouseDown={onMouseDown} />
    </div>
  );
};

export default Scroll;
