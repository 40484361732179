import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1.15",
  },
  src: {
    borderBottom: "1px solid #BBC2CA",
  },
  dst: {},
  separator: {
    margin: "4 0px",
    color: "#666",
  },
}));

const MacRenderer: FC<any> = (props) => {
  const classes = useStyles();
  const { srcMac, dstMac } = props.data;

  if (!srcMac && !dstMac) return <></>;

  return (
    <div className={classes.container}>
      <div className={classes.src}>{srcMac ? srcMac : "-"}</div>
      <div className={classes.dst}>{dstMac ? dstMac : "-"}</div>
    </div>
  );
};

export default MacRenderer;
