import { humanDuration, getCyrillicLangName } from "functions/common";

export const columnDefs: any = [
  {
    id: "view",
    field: "view",
    cellRenderer: "ViewIconRenderer",
    headerName: "",
    minWidth: 110,
    width: 110,
    maxWidth: 110,
    lockPosition: true,
    cellClass: "centeredText",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: false,
    resizable: false,
    hide: false,
  },
  {
    headerName: "№",
    field: "id",
    width: 100,
  },
  {
    headerName: "Статус",
    field: "status",
    cellRenderer: "RecordStatusRenderer",
    width: 100,
  },
  {
    headerName: "Маркер",
    field: "labels",
    cellRenderer: "LabelsRenderer",
    width: 250,
  },
  {
    headerName: "Дата сеанса",
    field: "dateRecord",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Имя сеанса",
    field: "name",
  },
  {
    headerName: "Длительность файла",
    field: "realDurationRecord",
    valueGetter: (params: any) => {
      const { realDurationRecord } = params.data;
      return humanDuration(realDurationRecord);
    },
  },
  {
    headerName: "Длительность речи",
    field: "srcDurationSpeech",
    cellRenderer: "DurationSpeechRenderer",
    flex: 1,
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Количество слов",
    field: "wordCount",
    valueGetter: (params: any) => {
      const { wordCount } = params.data;
      return wordCount === 0 ? "" : wordCount;
    },
    width: 120,
  },
  {
    headerName: "Достоверность транскрибирования",
    field: "sttConfidence",
    valueGetter: (params: any) => {
      const { sttConfidence } = params.data;
      return sttConfidence === 0 ? "" : sttConfidence.toFixed(2);
    },
    width: 120,
  },
  {
    headerName: "Релевантность",
    field: "wordRating",
    valueGetter: (params: any) => {
      const { wordRating } = params.data;
      return wordRating === 0 ? "" : wordRating.toFixed(2);
    },
    width: 120,
  },
  {
    headerName: "Количество переведённых слов",
    field: "translateWordCount",
    valueGetter: (params: any) => {
      const { translateWordCount } = params.data;
      return translateWordCount === 0 ? "" : translateWordCount;
    },
    width: 120,
  },
  {
    headerName: "Достоверность перевода",
    field: "translateConfidence",
    valueGetter: (params: any) => {
      const { translateConfidence } = params.data;
      return translateConfidence === 0 ? "" : translateConfidence.toFixed(2);
    },
    width: 120,
  },
  {
    headerName: "Язык речевого сообщения",
    field: "lang",
    cellRenderer: "LangRenderer",
    comparator: (nodeA: { data: { lang: string } }, nodeB: { data: { lang: string } }) => {
      const localizedValueA = getCyrillicLangName(nodeA.data.lang);
      const localizedValueB = getCyrillicLangName(nodeB.data.lang);

      return localizedValueA.localeCompare(localizedValueB);
    },
  },
  {
    headerName: "Достоверность языка речевого сообщения",
    field: "langConfidence",
    valueGetter: (params: any) => {
      const { langConfidence } = params.data;
      return langConfidence === 0 ? "" : langConfidence.toFixed(2);
    },
  },
  {
    headerName: "Смена языка",
    field: "isLidChange",
    cellRenderer: "BooleanRenderer",
  },
  {
    headerName: "Пол диктора",
    cellRenderer: "GenderRenderer",
    field: "srcGender",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Качество речи",
    cellRenderer: "SpeechQuality",
    field: "srcQuality",
    width: 50,
    minWidth: 50,
  },
  {
    headerName: "Возраст диктора",
    cellRenderer: "SpeakerAgeRenderer",
    field: "srcAge",
    width: 60,
    minWidth: 60,
  },
  {
    headerName: "Реверберация",
    cellRenderer: "ReverbRenderer",
    field: "srcReverb",
    width: 50,
    minWidth: 50,
  },
  {
    headerName: "Количество дикторов",
    cellRenderer: "DiarizationCountRenderer",
    field: "srcDiarizationCount",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Диктор",
    cellRenderer: "SpeakersNameRenderer",
    field: "srcSpeakersName",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Автоинформатор",
    cellRenderer: "AutoinformatorNameRenderer",
    field: "srcAutoinformatorName",
    minWidth: 70,
  },
  {
    headerName: "Количество страниц в факсимильном сообщении",
    field: "faxCount",
    minWidth: 50,
  },
  {
    headerName: "Факсимильное сообщение",
    field: "isFaxExist",
    cellRenderer: "BooleanRenderer",
    minWidth: 40,
  },
  {
    headerName: "Соотношение сигнал/шум",
    cellRenderer: "SignalNoiseRenderer",
    field: "srcSnr",
    width: 60,
    minWidth: 60,
  },
  {
    headerName: "Количество каналов",
    field: "channelCount",
    minWidth: 30,
  },
  {
    headerName: "Длительность файла (по паспорту)",
    field: "durationRecord",
    valueGetter: (params: any) => {
      const { durationRecord } = params.data;
      return humanDuration(durationRecord);
    },
  },
  {
    headerName: "Имя терминала",
    field: "srcName",
    cellRenderer: "TerminalNameRenderer",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Номер",
    field: "srcNumber",
    cellRenderer: "PhoneNumberRenderer",
    width: 100,
    minWidth: 70,
  },
  {
    headerName: "IP-адрес",
    field: "srcIpAddress",
    cellRenderer: "IpAddressRenderer",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Порт",
    field: "srcPort",
    cellRenderer: "PortRenderer",
    width: 60,
    minWidth: 50,
  },
  {
    headerName: "MAC-адрес",
    field: "srcMac",
    cellRenderer: "MacRenderer",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Призводительский MAC-адрес",
    field: "srcMacName",
    cellRenderer: "OperatorMacRenderer",
    width: 80,
    minWidth: 70,
  },
  {
    headerName: "Название страны",
    field: "srcCountry",
    cellRenderer: "CountryRenderer",
    width: 100,
    minWidth: 70,
  },
  {
    headerName: "Название города",
    field: "srcCity",
    cellRenderer: "CityRenderer",
    width: 100,
    minWidth: 70,
  },
  {
    headerName: "Адрес абонента",
    field: "srcAddress",
    cellRenderer: "AddressRenderer",
    minWidth: 70,
  },
  {
    headerName: "Имя КПС",
    field: "nameKps",
  },
  {
    headerName: "iDirectChan",
    field: "iDirectChan",
  },
  {
    headerName: "iDirectAdr1",
    field: "iDirectAdr1",
  },
  {
    headerName: "skinnyAddr",
    field: "skinnyAddr",
  },
  {
    headerName: "skinnyChan",
    field: "skinnyChan",
  },
  {
    headerName: "SINCKEY",
    field: "sinckey",
  },
  {
    headerName: "IMEI",
    field: "srcIMEI",
    cellRenderer: "UniversalRenderer",
    width: 60,
    minWidth: 40,
  },
  {
    headerName: "IMSI",
    field: "srcIMSI",
    cellRenderer: "UniversalRenderer",
    width: 60,
    minWidth: 40,
  },
  {
    headerName: "TMSI",
    field: "srcTMSI",
    cellRenderer: "UniversalRenderer",
    width: 60,
    minWidth: 40,
  },
  {
    headerName: "Стандарт",
    field: "standard",
  },
  {
    headerName: "Сеть",
    field: "network",
  },
  {
    headerName: "Принадлежность",
    field: "belong",
  },
  {
    headerName: "Тип вызова",
    field: "callType",
  },
  {
    headerName: "Имя источника(PostWorks)",
    field: "bSSourceName",
  },
  {
    headerName: "Базовая станция",
    field: "baseStation",
  },
  {
    headerName: "БС LAC",
    field: "bSLac",
  },
  {
    headerName: "БС СID",
    field: "bSCid",
  },
  {
    headerName: "Пост",
    field: "postId",
  },
  {
    headerName: "Частота",
    field: "frequency",
  },
  {
    headerName: "Имя источника данных(PostWorks)",
    field: "bSDataSourceName",
  },
  {
    headerName: "Тип данных",
    field: "codec",
  },
  {
    headerName: "Источник данных",
    field: "dataSource",
  },
  {
    headerName: "Тематика речевого сообщения",
    field: "textTheme",
  },
  {
    headerName: "Идентификатор документа",
    field: "esbDocUuid",
  },
  {
    headerName: "Алиас документа",
    field: "esbDocAlias",
  },
  {
    headerName: "Автор документа",
    field: "esbDocAuthor",
  },
  {
    headerName: "Идентификатор машины ИШД",
    field: "esbMachineId",
  },
  {
    headerName: "Дата создания",
    field: "dateCreated",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Postwin: Устройство",
    field: "postwinDevice",
  },
  {
    headerName: "Postwin: Начало",
    field: "postwinTimestamp",
    cellRenderer: "DateRenderer",
  },
  {
    headerName: "Postwin: Источник",
    field: "postwinSource",
  },
  {
    headerName: "Postwin: Номер собеседника",
    field: "postwinTalkerNumber",
  },
];
