import React, { FC, FormEvent, useEffect, useState } from "react";
import TreeGraphService from "services/TreeGraphService";
import plt from "customPalette";
import moment from "moment";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

//components
import GraphChart from "./Graph";
import TreeChart from "./Tree";
import Picker from "components/Picker";
import SelectNamespace from "components/SelectNamespace";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NoData from "components/NoData";
import { BuildByType } from "types/treeGraph";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    height: "calc(100vh - 90px)",
    display: "flex",
  },
  pickers: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  form: {
    width: 215,
    paddingRight: 10,
    borderRight: "1px solid " + plt.greyFriends[1],
    marginLeft: theme.spacing(1),
  },
  from: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  to: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: 205,
    margin: theme.spacing(1, 0),
  },
  title: {
    marginRight: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.54)",
  },
  button: {
    marginTop: theme.spacing(3),
  },
  btns: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  label: {
    backgroundColor: "white",
  },
  graph: {
    flexGrow: 1,
    margin: theme.spacing(0.5),
  },
}));

const TreeGraphWrapper: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //переход из таблицы результатов
  const location = useLocation<
    { type: BuildByType; value: string; isGraph: boolean; nameSpaceId: number } | undefined
  >();

  // Инициализируем начальные значения с учетом параметров из location
  const [value, setValue] = useState<string>(location.state?.value || "");
  const [type, setType] = useState<BuildByType>(location.state?.type || "NUMBER");
  const [isGraph, setIsGraph] = useState<boolean>(location.state?.isGraph ?? true);

  const [depthMax, setDepthMax] = useState<number>(5);
  const [output, setOutput] = useState<any>(null);
  const [namespaceId, setNamespaceId] = useState(location.state?.nameSpaceId ?? 0);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsEmpty(false); // Сбрасываем состояние при новом запросе

    try {
      if (isGraph) {
        const response = await TreeGraphService.getGraph({
          type,
          namespaceId,
          startOfRange: moment(startDate).toISOString(true),
          endOfRange: moment(endDate).toISOString(true),
        });

        // Проверяем на пустоту данных
        if (!response.data || (Array.isArray(response.data) && response.data.length === 0)) {
          setIsEmpty(true);
          setOutput(null);
        } else {
          setOutput(response.data);
        }
      } else {
        const response = await TreeGraphService.getTree({
          record: value,
          namespaceId,
          type,
          depthMax: depthMax,
          startOfRange: moment(startDate).toISOString(true),
          endOfRange: moment(endDate).toISOString(true),
        });

        // Проверяем на пустоту данных
        if (!response.data || (Array.isArray(response.data) && response.data.length === 0)) {
          setIsEmpty(true);
          setOutput(null);
        } else {
          setOutput(response.data);
        }
      }
    } catch (error) {
      console.log("Ошибка построения дерева/графа", error);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(showErrorAlert(error.message));
      setIsEmpty(false);
      setOutput(null);
    }
  };

  const handleTypeChange = (newIsGraph: boolean) => {
    setIsGraph(newIsGraph);
    setOutput(null); // Очищаем данные при переключении типа
    setIsEmpty(false); // Сбрасываем состояние при смене типа
  };

  const onStartDateChange = (dt: Date) => {
    setStartDate(dt);
  };

  const onEndDateChange = (dt: Date) => {
    setEndDate(dt);
  };

  useEffect(() => {
    const startDay = new Date(moment().startOf("month").format("YYYY-MM-DD") + " 00:00:00");
    const endDay = new Date(moment().add(1, "day").format("YYYY-MM-DD") + " 00:00:00");
    setEndDate(endDay);
    setStartDate(startDay);
  }, []);

  // Автоматически шлём запрос, если переходим из таблицы
  useEffect(() => {
    if (location.state && startDate && endDate) {
      console.log(location.state);
      // Создаем и диспатчим submit event
      const formEvent = (new Event("submit", { cancelable: true }) as unknown) as FormEvent<HTMLFormElement>;
      handleSubmit(formEvent);
    }
    // eslint-disable-next-line
  }, [location.state, startDate, endDate]);

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <FormControl className={classes.formControl}>
          <InputLabel id="label_graph_type" className={classes.label}>
            Тип
          </InputLabel>
          <Select
            fullWidth
            labelId="label_graph_type"
            value={isGraph ? "graph" : "tree"}
            onChange={(e) => handleTypeChange(e.target.value === "graph")}
          >
            <MenuItem value="graph">Граф</MenuItem>
            <MenuItem value="tree">Дерево</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="label_graph_f" className={classes.label}>
            Связать по
          </InputLabel>
          <Select
            fullWidth
            labelId="label_graph_f"
            value={type}
            onChange={(e) => setType(e.target.value as "NUMBER" | "MAC-ADDRESS" | "SPEAKER" | "IP-ADDRESS")}
          >
            <MenuItem value="NUMBER">Номер телефона</MenuItem>
            <MenuItem value="MAC-ADDRESS">MAC-адрес</MenuItem>
            <MenuItem value="SPEAKER">Диктор</MenuItem>
            <MenuItem value="IP-ADDRESS">Ip-адрес</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <SelectNamespace namespaceId={namespaceId} setNamespaceId={setNamespaceId} showCreateBtn={false} />
        </FormControl>

        {!isGraph && (
          <>
            <FormControl className={classes.formControl}>
              <TextField
                label="Значение"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                required
                title="Укажите значение"
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                label="Количество уровней"
                type="number"
                value={depthMax}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 2 && value <= 5) {
                    setDepthMax(value);
                  }
                }}
              />
            </FormControl>
          </>
        )}

        <FormControl className={classes.formControl}>
          <div className={classes.from}>C</div>
          <Picker date={startDate} onDateChange={onStartDateChange} />
        </FormControl>

        <FormControl className={classes.formControl}>
          <div className={classes.to}>По</div>
          <Picker date={endDate} onDateChange={onEndDateChange} />
        </FormControl>

        <Button type="submit" variant="contained" color="primary" className={classes.button} fullWidth>
          Построить
        </Button>
      </form>

      {isEmpty ? (
        <div className={classes.graph} style={{ marginTop: "40vh" }}>
          <NoData text="Попробуйте изменить параметры поиска или выбрать другой временной период" />
        </div>
      ) : (
        output && (
          <div className={classes.graph}>{isGraph ? <GraphChart data={[output]} /> : <TreeChart data={output} />}</div>
        )
      )}
    </Paper>
  );
};

export default TreeGraphWrapper;
