import React, { FC, FocusEvent, MouseEvent, useCallback, useMemo } from "react";
import { Word as WordType } from "types/record";
import clsx from "clsx";
import { RootState } from "redux/types";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey, red, yellow } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import { useWordRightClick } from "hooks/records/useWordRightClick";
import ContextMenu from "components/ContextMenu";
import { Group, Rule } from "types/queryBuilder";
import { generateUuid } from "functions/common";
import WordService from "services/WordService";
import { Word as WordDict } from "types/word";
import { showSuccessAlert } from "redux/actions/alertActions";

const useStyles = makeStyles(() => ({
  word: {
    display: "inline-block",
  },
  p: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: yellow[200] + "!important",
    },
  },
  active: {
    backgroundColor: yellow[300] + "!important",
  },
  padding: {
    padding: "2px 4px",
  },
  input: {
    display: "inline-block",
    border: "none",
    background: "none",
    fontSize: "inherit",
    padding: "2px 4px",
    "&:focus": {
      outline: "1px solid " + grey[400],
      borderRadius: 5,
    },
  },
  confidence: {
    marginBottom: -1,
    borderBottom: "1px dashed " + red[300],
    "&:focus": {
      borderBottom: "none",
    },
  },
}));

interface Props {
  word: WordType;
  currentTime: number;
  color: string | undefined;
  bgColor: string | undefined;
  onWordEdit: (event: FocusEvent<HTMLDivElement>, word: WordType) => void;
  dictionaries: WordDict[];
}

const Word: FC<Props> = ({ word, currentTime, color, bgColor, onWordEdit, dictionaries }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispacth = useDispatch();
  const { editMode, confidenceHighlight } = useSelector((state: RootState) => state.settings);
  const { word: w, start, end, lang, color: wColor, bgColor: wBgColor, confidence } = word;

  const isActive = currentTime >= start && currentTime <= end;
  const hasSpace = lang !== "chinese";

  const style = useMemo(() => {
    if (editMode) return;
    return {
      color: color ? color : wColor === "" ? "inherit" : wColor,
      borderBottomColor: bgColor ? bgColor : wBgColor === "" ? "inherit" : wBgColor,
      borderBottomWidth: "2px",
      "border-bottom-style": wBgColor ? "solid" : "",
      marginBottom: wBgColor ? -2 : 0,
    };
  }, [color, wColor, bgColor, wBgColor, editMode]);

  const handleClick = useCallback(() => {
    if (editMode) return;
    currTimeSubscriber.next(start);
  }, [start, editMode]);

  const { x, y, isVisible, showMenu, hideMenu } = useWordRightClick();

  const handleContextMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      showMenu(event.pageX, event.pageY);
    },
    [showMenu]
  );

  const removeDotAtEnd = (str: string): string => {
    if (str.endsWith(".")) {
      return str.slice(0, -1);
    }
    return str;
  };

  const handleSearch = useCallback(() => {
    const currentSearch = new URLSearchParams(window.location.search);
    const filterParam = currentSearch.get("f");

    const cleanWord = removeDotAtEnd(w);

    let currentGroup: Group = {
      type: "group",
      uuid: "1",
      operation: "AND",
      filters: [],
    };

    if (filterParam) {
      try {
        const parsed = JSON.parse(filterParam);
        if (parsed.type === "group" && parsed.uuid && parsed.operation && Array.isArray(parsed.filters)) {
          currentGroup = parsed as Group;
        }
      } catch (e) {
        console.error("Неправильный фильтр:", e);
      }
    }

    const newWordFilter: Rule = {
      type: "filter",
      uuid: generateUuid(),
      filter: "Слова",
      condition: "=",
      value: JSON.stringify({
        strong: [],
        vector: [cleanWord],
        phrase: [],
        dictionary: [],
      }),
    };

    // Проверяем, есть ли уже фильтр по словам
    const existingWordFilterIndex = currentGroup.filters.findIndex(
      (f): f is Rule => f.type === "filter" && f.filter === "Слова"
    );

    let newFilters: Array<Rule | Group>;
    if (existingWordFilterIndex !== -1) {
      // Если есть, заменяем его
      newFilters = [...currentGroup.filters];
      newFilters[existingWordFilterIndex] = newWordFilter;
    } else {
      // Если нет, добавляем новый
      newFilters = [...currentGroup.filters, newWordFilter];
    }

    const newGroup: Group = {
      ...currentGroup,
      filters: newFilters,
    };

    const { path } = routes.results;
    const url = path + "?f=" + JSON.stringify(newGroup);
    history.push(encodeURI(url));

    hideMenu();
  }, [w, hideMenu, history]);

  const handleAddToDictionary = useCallback(
    async (dictionaryId: number) => {
      if (!dictionaries) return;

      const cleanWord = removeDotAtEnd(w);

      try {
        const dictionary = dictionaries.find((d) => d.id === dictionaryId);

        if (!dictionary) return;

        const updatedWords = dictionary.words ? `${dictionary.words}\n${cleanWord}` : cleanWord;

        const updatedDictionary: WordDict = {
          ...dictionary,
          words: updatedWords,
        };

        await WordService.update(updatedDictionary);
        dispacth(showSuccessAlert(`Слово "${cleanWord}" добавлено в словарь`));
      } catch (error) {
        console.error("Ошибка при обновлении словаря:", error);
      } finally {
        hideMenu();
      }
    },
    [w, hideMenu, dictionaries, dispacth]
  );

  if (editMode) {
    return (
      <div
        className={clsx(classes.input, { [classes.confidence]: confidence < confidenceHighlight })}
        contentEditable
        onBlur={(e) => onWordEdit(e, word)}
        title={"достоверность: " + confidence}
      >
        {w}
      </div>
    );
  }

  return (
    <div
      style={style}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      className={clsx(classes.word, {
        [classes.p]: !editMode,
        [classes.active]: isActive,
        [classes.padding]: hasSpace,
      })}
    >
      {w}
      <ContextMenu
        x={x}
        y={y}
        isVisible={isVisible}
        onSearch={handleSearch}
        onAddToDictionary={handleAddToDictionary}
        hideMenu={hideMenu}
        dictionaries={dictionaries}
        wordText={w}
      />
    </div>
  );
};

export default Word;
