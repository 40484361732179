import React, { FC, ChangeEvent, useState, useCallback } from "react";
import useNamespaces from "hooks/namespace/useNamespaces";
import NamespaceService, { BodySpace } from "services/NamespaceService";
import CreateNamespaceDialog from "../pages/namespaces/components/CreateNamespaceDialog";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "self-end",
  },
  formControl: {
    flexGrow: 1,
  },
  btn: {
    marginLeft: 20,
  },
});

interface Props {
  disabled?: boolean;
  namespaceId: number;
  setNamespaceId: (id: number) => void;
  size?: string;
  showCreateBtn?: boolean;
}

const SelectNamespace: FC<Props> = ({ disabled, namespaceId, setNamespaceId, size, showCreateBtn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { namespaces, getNamespaces } = useNamespaces();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const namespaceIdSrt = namespaceId === 0 ? "" : String(namespaceId);

  const showBtn = showCreateBtn === undefined || showCreateBtn;

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const handleCloseCreateDialog = (data?: BodySpace) => {
    setOpenCreateDialog(false);
    if (data !== undefined) {
      NamespaceService.create(data)
        .then(getNamespaces)
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setNamespaceId(event.target.value as number);
  };

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.formControl} disabled={disabled}>
        <InputLabel id="select-namespace-label">Пространство</InputLabel>
        <Select labelId="select-namespace-label" value={namespaceIdSrt} onChange={handleChange} fullWidth>
          {namespaces
            .slice()
            .sort((a, b) => b.id - a.id)
            .map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {showBtn && (
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={() => setOpenCreateDialog(true)}
          size={size === "small" ? "small" : "medium"}
          className={classes.btn}
        >
          Создать
        </Button>
      )}

      <CreateNamespaceDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
    </div>
  );
};

export default SelectNamespace;
