import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ExportTask, Task } from "types/task";
import { Group, Rule } from "types/queryBuilder";
import { generateUuid } from "functions/common";
import TaskExportTable from "./TaskExportTable";
import { convertFilterName } from "functions/common";

// components
import Technologies from "./Technologies";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { IconButton, Input } from "@material-ui/core";
import FileCopyIcon from "components/icons/FileCopyIcon";
import { grey } from "@material-ui/core/colors";
import { useDispatch } from "react-redux";
import { showSuccessAlert } from "redux/actions/alertActions";
import { SearchSettings } from "components/searchFilter/InputDialogWordsTab";
import { convertToString } from "components/searchFilter/components/InputDateEditComponent";
import { setAppFilter } from "redux/actions/settingsActions";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "100%",
  },
  styleBtn: {
    marginLeft: theme.spacing(1),
    color: grey[500],
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  task: Task;
}

interface State {
  name: string;
  comment: string;
  isActive: boolean;
  isForce: boolean;
}

const EditTaskDialog: FC<Props> = ({ open, onClose, task }) => {
  const dispatch = useDispatch();
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();

  const initialState: State = {
    name: task.name,
    comment: task.comment,
    isActive: task.isActive,
    isForce: task.taskDetail.techDetail.isForce,
  };

  const [state, setState] = useState<State>(initialState);
  const { name, comment, isActive, isForce } = state;

  const [filter, setFilter] = useState(defaultFilter);
  const [technologies, setTechnologies] = useState<any[]>(task.taskDetail.techDetail.techs);
  const [exports, setExports] = useState<ExportTask[]>(task.taskDetail?.exports ?? []);

  const disabled = name.length === 0 || technologies.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState((prev) => ({ ...prev, isActive: checked }));
  };

  const handleCheckedIsForce = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState((prev) => ({
      ...prev,
      isForce: checked,
    }));
  };

  const handleSave = () => {
    onClose({ ...state, id: task.id, techs: technologies, filter: JSON.stringify(filter), exports });
  };

  useEffect(() => {
    setState({
      name: task.name,
      comment: task.comment,
      isActive: task.isActive,
      isForce: task.taskDetail.techDetail.isForce,
    });
    setTechnologies(task.taskDetail.techDetail.techs);
    setExports(task.taskDetail?.exports ?? []);
    try {
      const filter: Group = JSON.parse(task.taskDetail.filterDetail);
      setFilter(filter);
    } catch (err) {
      setFilter(defaultFilter);
    }
  }, [task, defaultFilter]);

  const formatWords = (words: SearchSettings): string => {
    const allValues = Object.values(words);
    const flattenedValues = allValues.flat();
    return flattenedValues.join(", ");
  };

  const filterText = filter.filters
    ?.map((item: Rule | Group) => {
      if (item.type === "filter") {
        if (item.filter === "Слова") {
          return `${item.filter} ${item.condition} ${formatWords(JSON.parse(item.value))}`;
        } else if (item.filter === "Дата сеанса" && item.condition === "=") {
          return `${item.filter} ${item.condition} ${convertToString(item.value)}`;
        } else {
          return `${item.filter} ${item.condition} ${item.value}`;
        }
      }
    })
    .join("\n");

  const saveFilter = () => {
    dispatch(setAppFilter(JSON.stringify(filter)));
    dispatch(showSuccessAlert("Фильтр скопирован."));
  };

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Редактировать задание</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Имя" fullWidth variant="standard" name="name" onChange={handleChange} value={name} />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Input placeholder={convertFilterName(JSON.stringify(filter))} title={filterText} fullWidth disabled />
              </div>
              <IconButton
                size="small"
                onClick={saveFilter}
                className={classes.styleBtn}
                disableRipple
                color="primary"
                title="Копировать"
              >
                <FileCopyIcon />
              </IconButton>
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={isActive} onChange={handleChecked} name="isActive" color="primary" />}
              label="Активно"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={isForce} onChange={handleCheckedIsForce} name="isForce" color="primary" />}
              label="Переобработать"
            />
          </Grid>
          <Grid item xs={12}>
            <Technologies technologies={technologies} setTechnologies={setTechnologies} />
          </Grid>
          <Grid item xs={12}>
            <TaskExportTable exports={exports} setExports={setExports} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EditTaskDialog;
