import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState, SettingsState } from "redux/types";
import { drawerClose, drawerOpen } from "redux/actions/drawerActions";
import { fetchNavigationModules } from "redux/asyncAcions/navigation";
import SettingsService from "services/admin/SettingsService";
import { setAllSettings } from "redux/actions/settingsActions";
import { defaultState as defaultSettings } from "redux/reducers/settingsReducer";
import { defaultPageSettings } from "redux/reducers/pageSettingsReducer";
import { PageSettingsState } from "redux/types/pageSettings";
import { pagesSetSettings } from "redux/actions/pageSettingsActions";
import { Key } from "types/settings";

// components
import Navigation from "layouts/user/navigation/Navigation";
import Header from "layouts/user/header/Header";
import Notification from "components/Notification";
import ErrorBoundary from "components/ErrorBoundary";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";

// icons
import TwitchCloseIcon from "components/icons/TwitchCloseIcon";
import TwitchOpenIcon from "components/icons/TwitchOpenIcon";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "64px 1fr",
    gridTemplateColumns: "auto 1fr",
    height: "100vh",
  },
  aside: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    gridColumn: "1 / 2",
    borderRight: "1px solid #bdc3c7",
  },
  header: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgb(29,145,136)",
    // background: "linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)",
    background:
      theme.palette.primary.gradient ?? "linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)",
    gridColumn: "1 / 3",
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  navigation: {},
  content: {
    boxSizing: "border-box",
    overflow: "hidden",
    gridColumn: "2 / 3",
  },
  toggleButton: {
    height: 45,
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    cursor: "pointer",
  },
  menuTitle: {
    margin: 0,
    padding: 0,
  },
  alert: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "30vw",
    zIndex: theme.zIndex.drawer + 10,
  },
  sidebarOpen: {
    width: 280,
  },
  sidebarClosed: {
    width: 56,
    gridColumn: "1 / 2",
  },
  "@global": {
    // Стили для WebKit (Chrome, Safari, Edge)
    "::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 0 1px rgba(255, 255, 255, 0.5)",
    },
  },
}));

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const widthOpen = 280;
  const widthClose = 56;
  const classes = useStyles();
  const { open } = useSelector((state: RootState) => state.drawer);
  const dispatch = useDispatch();

  const [loadSettings, setLoadSettings] = useState(true);

  const firstRender = useRef(true);

  const handleOpen = () => {
    open ? handleDrawerClose() : handleDrawerOpen();
  };

  const handleDrawerOpen = () => {
    dispatch(drawerOpen());
  };

  const handleDrawerClose = () => {
    dispatch(drawerClose());
  };

  useEffect(() => {
    dispatch(fetchNavigationModules());
  }, [dispatch]);

  useEffect(() => {
    if (firstRender.current) {
      SettingsService.getByKye({ key: Key.PAGE_SETTINGS }).then((res) => {
        const { value } = res.data;
        try {
          const savedSettings = JSON.parse(value);
          const settings: PageSettingsState = {
            ...defaultPageSettings,
            ...savedSettings,
          };
          dispatch(pagesSetSettings(settings));
        } catch (error) {}
      });

      SettingsService.getByKye({ key: Key.SETTINGS })
        .then((res) => {
          const { value } = res.data;
          try {
            const savedSettings = JSON.parse(value);
            const settings: SettingsState = {
              ...defaultSettings,
              ...savedSettings,
            };
            dispatch(setAllSettings(settings));
          } catch (error) {}
        })
        .finally(() => {
          setLoadSettings(false);
        });

      firstRender.current = false;
    }
  }, [dispatch]);

  if (loadSettings) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <header className={classes.header}>
        <Header />
      </header>

      <aside
        style={{
          width: open ? widthOpen : widthClose,
        }}
        className={clsx(classes.aside, "app_aside", open ? classes.sidebarOpen : classes.sidebarClosed)}
      >
        <div
          onClick={handleOpen}
          className={classes.toggleButton}
          style={{ justifyContent: open ? "flex-end" : "center" }}
        >
          {open ? <TwitchCloseIcon /> : <TwitchOpenIcon />}
        </div>
        <div className={classes.navigation}>
          <Navigation />
        </div>
      </aside>

      <main className={clsx(classes.content, "main-content")}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>

      <Notification />
    </div>
  );
};

export default Layout;
