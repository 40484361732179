import { WaveformType } from "types/player";
import { Dictionary } from "types/keyword";
import { ChannelState } from "types/player";

export const SETTINGS_SET_ROWS_PER_PAGE = "SETTINGS_SET_ROWS_PER_PAGE";
export const SETTINGS_SET_RESULT_PANEL_SIZE = "SETTINGS_SET_RESULT_PANEL_SIZE";
export const SETTINGS_SET_ALL = "SETTINGS_SET_ALL";

// STT
export const SETTINGS_SET_STT_FONT = "SETTINGS_SET_STT_FONT";
export const SETTINGS_SET_STT_FONT_SIZE = "SETTINGS_SET_STT_FONT_SIZE";
export const SETTINGS_SET_STT_BACKGROUND_COLOR = "SETTINGS_SET_STT_BACKGROUND_COLOR";
export const SETTINGS_SET_STT_TEXT_COLOR = "SETTINGS_SET_STT_TEXT_COLOR";
export const SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER = "SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER";
export const SETTINGS_SET_STT_SHOW_TRANSLATE = "SETTINGS_SET_STT_SHOW_TRANSLATE";
export const SETTINGS_SET_STT_EDITE_MODE = "SETTINGS_SET_EDITE_MODE";
export const SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT = "SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT";

// PLAYER
export const SETTINGS_SET_PLAYER_IS_PAN = "SETTINGS_SET_PLAYER_IS_PAN";
export const SETTINGS_SET_PLAYER_IS_ADE_CLICK = "SETTINGS_SET_PLAYER_IS_ADE_CLICK";
export const SETTINGS_SET_PLAYER_IS_NORMALIZE = "SETTINGS_SET_PLAYER_IS_NORMALIZE";
export const SETTINGS_SET_PLAYER_IS_NOISE = "SETTINGS_SET_PLAYER_IS_NOISE";
export const SETTINGS_SET_PLAYER_IS_KTCH = "SETTINGS_SET_PLAYER_IS_KTCH";
export const SETTINGS_SET_PLAYER_IS_ECHO = "SETTINGS_SET_PLAYER_IS_ECHO";
export const SETTINGS_SET_PLAYER_CHANNEL = "SETTINGS_SET_PLAYER_CHANNEL";
export const SETTINGS_SET_PLAYER_GAIN = "SETTINGS_SET_PLAYER_GAIN";
export const SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS = "SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS";
export const SETTINGS_SET_PLAYER_WAVEFORM_TYPE = "SETTINGS_SET_PLAYER_WAVEFORM_TYPE";
export const SETTINGS_SET_KEYWORDS_LAYOUT = "SETTINGS_SET_KEYWORDS_LAYOUT";
export const SETTINGS_SET_ZOOM = "SETTINGS_SET_ZOOM";
export const SET_SHOW_SEGMENTS = "SET_SHOW_SEGMENTS";
export const SET_PLAYBACK_SPEED = "SET_PLAYBACK_SPEED";
export const SET_AUTOPLAY = "SET_AUTOPLAY";
export const SET_ACTIVECHANNELS = "SET_ACTIVECHANNELS";
export const SET_LOOPING = "SET_LOOPING";

//Соотношение сторон
export const SET_RATIO_RATE = "SET_RATIO_RATE";

// SORT это запоминане по какому столбцу пользователь поставил сортировку? Нужно сделать, чтобы при клике работало
export const SETTINGS_SET_SORT_NAME = "SETTINGS_SET_SORT_NAME";
export const SETTINGS_SET_SORT_ORDER = "SETTINGS_SET_SORT_ORDER";

// DIARIZATION
export const SETTINGS_SET_DIARIZATION_HIGHLIGHT = "SETTINGS_SET_DIARIZATION_HIGHLIGHT";

//FILTER
export const SET_APP_FILTER = "SET_APP_FILTER";
export const SET_DICTIONARY = "SET_DICTIONARY";
export const SET_AUDIO_SRC = "SET_AUDIO_SRC";

//REDUX_PROVIDER
export const SET_APP = "SET_APP";

//THEME
export const SETTINGS_SET_THEME_PRIMARY_COLOR = "SETTINGS_SET_THEME_PRIMARY_COLOR";
export const SETTINGS_SET_THEME_TYPOGRAPHY = "SETTINGS_SET_THEME_TYPOGRAPHY";
export const SETTINGS_SET_THEME_COMPLETE = "SETTINGS_SET_THEME_COMPLETE";

export interface SettingsState {
  rowsPerPage: number;
  resultPanelSize: number;
  debounce: number;
  sttFont: string;
  sttFontSize: number;
  sttTextColor: string;
  sttBackgroundColor: string;
  sttSyncTextAndPlayer: boolean;
  sttShowKeywords: boolean;
  sttShowTranslate: boolean;
  playerIsPan: 0 | 1;
  playerIsAdeClick: 0 | 1;
  playerIsNormalize: 0 | 1;
  playerIsNoise: 0 | 1;
  playerIsKTCH: 0 | 1;
  playerIsEcho: 0 | 1;
  playerChannel: 0 | 1 | -1;
  playerGain: number;
  playerPlayOnlyVoiceSegments: boolean;
  playerWaveformType: WaveformType;
  playerZoom: number;
  sortName: string;
  sortOrder: string;
  diarizationHighlight: boolean;
  editMode: boolean;
  confidenceHighlight: number; // порог подсветки слова при редактировании
  showSegments: boolean;
  playbackSpeed: number;
  autoplay: boolean;
  activeChannels: { left: boolean; right: boolean };
  looping: boolean;
  ratioRate: string;
  appFilter: string;
  dictionary: Dictionary[];
  audioSrc: string;
  themePrimaryColor: string;
  themeSecondaryColor: string;
  themeFontFamily: string;
}

export type SettingsAction =
  | { type: typeof SETTINGS_SET_ALL; payload: SettingsState }
  | { type: typeof SETTINGS_SET_ROWS_PER_PAGE; payload: number }
  | { type: typeof SETTINGS_SET_RESULT_PANEL_SIZE; payload: number }
  | { type: typeof SETTINGS_SET_STT_BACKGROUND_COLOR; payload: string }
  | { type: typeof SETTINGS_SET_STT_TEXT_COLOR; payload: string }
  | { type: typeof SETTINGS_SET_STT_FONT; payload: string }
  | { type: typeof SETTINGS_SET_STT_FONT_SIZE; payload: number }
  | { type: typeof SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER; payload: boolean }
  | { type: typeof SETTINGS_SET_STT_SHOW_TRANSLATE; payload: boolean }
  | { type: typeof SETTINGS_SET_STT_EDITE_MODE; payload: boolean }
  | { type: typeof SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_IS_PAN; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_IS_ADE_CLICK; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_IS_NORMALIZE; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_IS_NOISE; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_IS_KTCH; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_IS_ECHO; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_CHANNEL; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_GAIN; payload: number }
  | { type: typeof SETTINGS_SET_PLAYER_WAVEFORM_TYPE; payload: WaveformType }
  | { type: typeof SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS; payload: boolean }
  | { type: typeof SETTINGS_SET_ZOOM; payload: number }
  | { type: typeof SETTINGS_SET_SORT_NAME; payload: string }
  | { type: typeof SETTINGS_SET_SORT_ORDER; payload: string }
  | { type: typeof SETTINGS_SET_DIARIZATION_HIGHLIGHT; payload: boolean }
  | { type: typeof SET_SHOW_SEGMENTS; payload: boolean }
  | { type: typeof SET_PLAYBACK_SPEED; payload: number }
  | { type: typeof SET_AUTOPLAY; payload: boolean }
  | { type: typeof SET_ACTIVECHANNELS; payload: ChannelState }
  | { type: typeof SET_LOOPING; payload: boolean }
  | { type: typeof SET_RATIO_RATE; payload: string }
  | { type: typeof SET_APP_FILTER; payload: string }
  | { type: typeof SET_DICTIONARY; payload: Dictionary[] }
  | { type: typeof SET_AUDIO_SRC; payload: string }
  | { type: typeof SETTINGS_SET_THEME_PRIMARY_COLOR; payload: string }
  | { type: typeof SETTINGS_SET_THEME_TYPOGRAPHY; payload: { fontFamily: string } }
  | { type: typeof SETTINGS_SET_THEME_COMPLETE; payload: { primaryColor: string; fontFamily: string } };
