import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Record } from "types";
import { AdminTypeFilter } from "types/admin";
import { FilterType, Group, Rule } from "types/queryBuilder";
import { RootState } from "redux/types";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { generateUuid, getCyrillicLangName } from "functions/common";
import { showErrorAlert } from "redux/actions/alertActions";
import { setPageRecordsTableSettings } from "redux/actions/pageSettingsActions";
import { useHistory } from "react-router-dom";
import { BuildByType } from "types/treeGraph";

// ag-grid
import {
  ColumnResizedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import localization from "components/agGrid/localization";
import { columnDefs } from "./columnDefs";
import { processCellForClipboard } from "components/agGrid/functions";
import RangeInfo from "components/agGrid/RangeInfo";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import BooleanRenderer from "components/agGrid/renderers/BooleanRenderer";
import LabelsRenderer from "components/agGrid/renderers/LabelsRenderer";
import FloatRenderer from "components/agGrid/renderers/FloatRenderer";
import RecordStatusRenderer from "components/agGrid/renderers/RecordStatusRenderer";
import LangRenderer from "components/agGrid/renderers/LangRenderer";
import DurationSpeechRenderer from "components/agGrid/renderers/DurationSpeechRenderer";
import GenderRenderer from "components/agGrid/renderers/GenderRenderer";
import SpeechQuality from "components/agGrid/renderers/SpeechQuality";
import SpeakerAgeRenderer from "components/agGrid/renderers/SpeakerAgeRenderer";
import ReverbRenderer from "components/agGrid/renderers/ReverbRenderer";
import DiarizationCountRenderer from "components/agGrid/renderers/DiarizationCountRenderer";
import SpeakersNameRenderer from "components/agGrid/renderers/SpeakersNameRenderer";
import AutoinformatorNameRenderer from "components/agGrid/renderers/AutoinformatorNameRenderer";
import SignalNoiseRenderer from "components/agGrid/renderers/SignalNoiseRenderer";
import TerminalNameRenderer from "components/agGrid/renderers/TerminalNameRenderer";
import IpAddressRenderer from "components/agGrid/renderers/IpAddressRenderer";
import PortRenderer from "components/agGrid/renderers/PortRenderer";
import OperatorMacRenderer from "components/agGrid/renderers/OperatorMacRenderer";
import CountryRenderer from "components/agGrid/renderers/CountryRenderer";
import CityRenderer from "components/agGrid/renderers/CityRenderer";
import AddressRenderer from "components/agGrid/renderers/AddressRenderer";
import UniversalRenderer from "components/agGrid/renderers/UniversalRenderer";
import PhoneNumberRenderer from "components/agGrid/renderers/PhoneNumberRenderer";
import ViewIconRenderer from "components/agGrid/renderers/ViewIconRenderer";
import MacRenderer from "components/agGrid/renderers/MacRenderer";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rangeInfo: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 15,
    bottom: 0,
    right: 0,
  },
}));

const defaultColDef = {
  resizable: true,
  suppressMenu: true,
  sortable: true,
  wrapText: true,
  whiteSpace: "normal",
  cellClass: "centeredText",
};

const rowHeight = 50;

interface Props {
  rowData: Record[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  availableFilters: AdminTypeFilter[];
  setFilter: Function;
}

const RecordsTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection, availableFilters, setFilter }) => {
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { records: pageSettings } = useSelector((state: RootState) => state.pageSettings);
  const [rangeInfo, setRangeInfo] = useState<{ open: boolean; values: number[] }>({ open: false, values: [] });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowStyle = (params: any) => {
    const isViewed = params.node.data?.isViewed ?? false;
    if (isViewed) {
      return { backgroundColor: "#e0e0e0" };
    }
    return { backgroundColor: "#fff" };
  };

  const getRowNodeId = (data: Record) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageRecordsTableSettings(colState));
      }
    },
    [dispatch]
  );

  const contextFilter = useCallback(
    (params: GetContextMenuItemsParams, op: "=" | "!=") => {
      const { column, node, value } = params;

      // название поля латиницей
      const colName = column.getColId();

      const availableFilter = availableFilters.find((f) => f.jsonName === colName);

      // если поля нет в доступных фильтрах
      if (availableFilter === undefined) {
        dispatch(showErrorAlert("Фильтрация по полю не поддерживается."));
        return;
      }
      // если значение поля не удалось определить
      if (value === undefined) {
        dispatch(showErrorAlert("Не удалось определить значение ячейки."));
        return;
      }

      // правило для добавления
      const rule: Rule = {
        type: "filter",
        uuid: generateUuid(),
        filter: availableFilter.name,
        condition: op,
        value: String(value),
      };

      // При пкм смотреть тип Фильтра и если это number или float, то ставить знак ">", в остальных случаях "="
      if (availableFilter.type === FilterType.Number || availableFilter.type === FilterType.Float) {
        rule.condition = ">";
      }

      // Если поле = Дата сеанса
      if (colName === "dateRecord") {
        try {
          const cellDate = moment(value).set("hour", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0);
          const nextDay = moment(value)
            .add(1, "day")
            .set("hour", 0)
            .set("minutes", 0)
            .set("seconds", 0)
            .set("milliseconds", 0);
          rule.value = `${cellDate.toISOString(true)}\n${nextDay.toISOString(true)}`;
        } catch (e) {}
      }

      // Если тип значение логический
      if (typeof value === "boolean") {
        rule.value = value ? "да" : "нет";
      }

      // cellDbValue - занчение которое лежит в базе
      const cellDbValue = node.data[colName];

      // если отображаемое значение равно пустоте
      if (value === "") {
        rule.value = String(cellDbValue);
      }

      // поля исключения для которых надо добавлять то что в базе
      const exception = ["realDurationRecord", "srcDurationSpeech", "dstDurationSpeech", "durationRecord"];
      if (exception.includes(colName)) {
        rule.value = String(cellDbValue);
      }

      // Если поле = Язык речевого сообщения, то значения берём на кирилице
      if (colName === "lang") {
        rule.value = getCyrillicLangName(value);
      }

      setFilter((prev: Group) => ({ ...prev, filters: [...prev.filters, rule] }));
    },
    [availableFilters, setFilter, dispatch]
  );

  //TODO: Доделать url для пространства
  // // Функция для парсинга URL
  // const getNamespaceIdFromUrl = (searchParams: string): number => {
  //   try {
  //     if (!searchParams) return 0;

  //     console.log(searchParams)
  //     const filterString = searchParams.substring(3);
  //     const filterObject: any = JSON.parse(filterString);
  //     console.log(JSON.stringify(filterObject))

  //     const findNamespaceFilter = (group: Group | Rule): string | null => {
  //       if (group.type === 'filter') {
  //         if (group.filter === 'Пространства' && group.condition === '=') {
  //           return group.value;
  //         }
  //         return null;
  //       }

  //       if (group.type === 'group') {
  //         for (const filter of group.filters) {
  //           const result = findNamespaceFilter(filter);
  //           if (result) return result;
  //         }
  //       }

  //       return null;
  //     };

  //     const namespaceValue = findNamespaceFilter(filterObject);
  //     return namespaceValue ? parseInt(namespaceValue, 10) : 0;
  //   } catch (error) {
  //     console.error('Error parsing URL filters:', error);
  //     return 0;
  //   }
  // };

  const handleGraphNavigation = useCallback(
    (value: string, type: BuildByType, isGraph: boolean, namespaceId?: number) => {
      history.push("/graph", {
        type,
        value,
        isGraph,
        namespaceId,
      });
    },
    [history]
  );

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const baseItems = [
        {
          name: "Фильтровать по значению",
          action: () => {
            contextFilter(params, "=");
          },
        },
        {
          name: "Исключить из выборки",
          action: () => {
            contextFilter(params, "!=");
          },
        },
        "separator",
        "copy",
      ];

      const columnId = params.column.getColId();
      let graphType: BuildByType | null = null;
      // Получаем namespaceId из URL
      // const namespaceId = getNamespaceIdFromUrl(location.search);
      const namespaceId = 0;

      switch (columnId) {
        case "srcMac":
        case "dstMac":
          graphType = "MAC-ADDRESS";
          break;
        case "srcIpAddress":
        case "dstIpAddress":
          graphType = "IP-ADDRESS";
          break;
        case "srcNumber":
        case "dstNumber":
          graphType = "NUMBER";
          break;
        case "srcSpeakersName":
        case "dstSpeakersName":
          graphType = "SPEAKER";
          break;
        default:
          break;
      }

      if (graphType !== null) {
        return [
          ...baseItems.slice(0, -1),
          {
            name: "Построить граф связей",
            action: () => {
              handleGraphNavigation(params.value, graphType as BuildByType, true, namespaceId);
            },
          },
          {
            name: "Построить дерево связей",
            action: () => {
              if (params.value) {
                console.log(params.node.data.srcSpeakersId[0], params.node.data.dstSpeakersId[0]);
                let speakersId;
                if (columnId === "srcSpeakersName") {
                  speakersId = params.node.data.srcSpeakersId[0];
                }
                if (columnId === "dstSpeakersName") {
                  speakersId = params.node.data.dstSpeakersId[0];
                }
                const idToString = speakersId?.toString();
                graphType === "SPEAKER"
                  ? handleGraphNavigation(idToString, graphType as BuildByType, false, namespaceId)
                  : handleGraphNavigation(params.value, graphType as BuildByType, false, namespaceId);
              }
            },
            disabled: !params.value,
          },
          "separator",
          "copy",
        ];
      }

      return baseItems;
    },
    [contextFilter, handleGraphNavigation]
  );

  const suppressKeyboardEvent = (event: SuppressKeyboardEventParams) => {
    const { key } = event.event;
    return key === "ArrowLeft" || key === "ArrowRight";
  };

  const onRangeSelectionChanged = (event: any) => {
    try {
      const { api, finished } = event;
      if (finished) {
        const cellRangesArr = api.getCellRanges();
        if (cellRangesArr !== null) {
          const [cellRanges] = cellRangesArr;
          const colId = cellRanges?.startColumn?.colId ?? "";

          const startRowIndex = cellRanges?.startRow?.rowIndex ?? 0;
          const endRowIndex = cellRanges?.endRow?.rowIndex ?? 0;
          let start = startRowIndex;
          let end = endRowIndex;
          // если выделяли снизу вверх, startRowIndex будет больше endRowIndex
          if (startRowIndex > endRowIndex) {
            start = endRowIndex;
            end = startRowIndex;
          }
          const values = [];
          for (let i = start; i <= end; i++) {
            const node = api.getDisplayedRowAtIndex(i);
            const value = node.data[colId];
            if (typeof value === "number") {
              values.push(value);
            }
          }
          setRangeInfo({ open: true, values });
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    // TODO: Для графов. Берём тут значение из url
    // ищем в фильтре Rule, Пространство или namespaceId
    // записываем его айди в стейт, далее забираем и подставляем
    // если такого нет ретёрн, а дефолтное поставим 0, тогда мы типа его всегда отправляем
  }, []);

  return (
    <div
      className="ag-theme-balham"
      style={{ height: "calc(-370px + 100vh)", transition: "height 0.3s ease", fontSize: "14px" }}
    >
      <AgGridReact
        rowHeight={rowHeight}
        headerHeight={rowHeight}
        suppressKeyboardEvent={suppressKeyboardEvent}
        ref={gridRef}
        animateRows={false}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        getRowStyle={getRowStyle}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        enableRangeSelection
        suppressCopyRowsToClipboard
        onColumnResized={onColumnResized}
        suppressDragLeaveHidesColumns
        getContextMenuItems={getContextMenuItems}
        onRangeSelectionChanged={onRangeSelectionChanged}
        suppressMultiRangeSelection
        scrollbarWidth={8}
        processCellForClipboard={processCellForClipboard}
        frameworkComponents={{
          DateRenderer,
          BooleanRenderer,
          LabelsRenderer,
          FloatRenderer,
          RecordStatusRenderer,
          LangRenderer,
          ViewIconRenderer,
          DurationSpeechRenderer,
          GenderRenderer,
          SpeechQuality,
          SpeakerAgeRenderer,
          ReverbRenderer,
          DiarizationCountRenderer,
          SpeakersNameRenderer,
          AutoinformatorNameRenderer,
          SignalNoiseRenderer,
          TerminalNameRenderer,
          IpAddressRenderer,
          PortRenderer,
          OperatorMacRenderer,
          CountryRenderer,
          CityRenderer,
          AddressRenderer,
          UniversalRenderer,
          PhoneNumberRenderer,
          MacRenderer,
        }}
      />
      {rangeInfo.open && rangeInfo.values.length > 1 && (
        <div className={classes.rangeInfo}>
          <RangeInfo values={rangeInfo.values} />
        </div>
      )}
    </div>
  );
};

export default RecordsTable;
