import React, { FC } from "react";
import { Record } from "types/record";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import clsx from "clsx";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 425px)",
  },
  wrapper: {
    marginLeft: -2,
  },
  checkbox: {
    color: theme.palette.text.primary,
  },
}));

const ValueRenderer: FC<{ value: any }> = ({ value }) => {
  const classes = useStyles();

  if (typeof value === "boolean") {
    return (
      <div className={classes.wrapper}>
        {value ? (
          <CheckBoxIcon className={classes.checkbox} />
        ) : (
          <CheckBoxOutlineBlankIcon className={classes.checkbox} />
        )}
      </div>
    );
  }

  if (Array.isArray(value)) {
    return "array";
  }

  return value;
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  flex: 1,
};

const columnDefs = [
  {
    headerName: "Поле",
    field: "key",
  },
  {
    headerName: "Значение",
    field: "val",
    cellRenderer: "ValueRenderer",
  },
];

interface Props {
  record: Record;
}

const exclude = [
  "id",
  "namespaceId",
  "dateCreated",
  "dateChanged",
  "name",
  "dateRecord",
  "srcIpAddress",
  "dstIpAddress",
  "srcPort",
  "dstPort",
  "srcNumber",
  "dstNumber",
  "srcName",
  "dstName",
  "durationRecord",
  "realDurationRecord",
  "message",
  "channelCount",
  "isNeedProcess",
  "isViewed",
  "labels",
];

const RecordTable: FC<Props> = ({ record }) => {
  const classes = useStyles();
  const rowData = Object.entries(record)
    .map(([key, val]) => ({ key, val }))
    .filter(({ key }) => !exclude.includes(key));
  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="single"
        enableRangeSelection
        suppressCopyRowsToClipboard
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          ValueRenderer,
        }}
      />
    </div>
  );
};

export default RecordTable;
