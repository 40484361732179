import { AxiosResponse } from "axios";
import api from "http/api";
import { treeGraph as path } from "http/urls";
import { BuildByType } from "types/treeGraph";

function TreeGraphService() {
  // Построить дерево
  function getTree(body: {
    record: string;
    namespaceId: number;
    type: BuildByType;
    depthMax: number;
    startOfRange: string;
    endOfRange: string;
  }): Promise<AxiosResponse> {
    const url = path.getTree;
    return api.post(url, body);
  }

  // Построить граф
  function getGraph(body: {
    type: BuildByType;
    namespaceId: number;
    startOfRange: string;
    endOfRange: string;
  }): Promise<AxiosResponse> {
    const url = path.getGraph;
    return api.post(url, body);
  }

  return Object.freeze({
    getTree,
    getGraph,
  });
}

export default TreeGraphService();
