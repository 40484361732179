import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "14px!important",
    textAlign: "center",
  },
}));

interface Props {
  text?: string;
}

const NoData: FC<Props> = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {"< Нет данных для отображения >"}
      <br />
      <br />
      {text ?? text}
    </div>
  );
};

export default NoData;
