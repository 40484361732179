import React, { FC, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getSocketUrl } from "./http/functions";
import { routes as r } from "routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/types";
import { AdminTechLid } from "./types/admin";
import AuthService from "services/AuthService";
import TechService from "./services/admin/TechService";
import AdminSourceService from "./services/admin/AdminSourceService";
import { WsData, WsDataType } from "./types/ws";
import { ws } from "http/urls";
import { subscriber as speakerStatusSubscriber } from "subscribers/SpeakerStatusSubscriber";
import { subscriber as messageSubscriber } from "subscribers/MessageSubscriber";
import { subscriber as recordSubscriber } from "./subscribers/RecordStatusSubscriber";
import { store } from "providers/ReduxProvider";
import { setWsIsConnect } from "./redux/actions/wsActions";
import { catchError } from "./functions/common";
import {
  hideSystemError,
  setSystemLidLanguages,
  setSystemServices,
  setSystemSources,
  showSystemError,
} from "./redux/actions/systemActions";

// layouts
import Layout from "layouts/user/Layout";
import AdminLayout from "layouts/admin/AdminLayout";

// pages
import Admin from "pages/admin/Admin";
import Login from "pages/auth/Login";
import Entry from "pages/entry/Entry";
import Error404 from "pages/404/Error404";
import Sources from "pages/sources/Sources";
import Tasks from "pages/tasks/Tasks";
import Dictionaries from "pages/dictionaries/Dictionaries";
import Namespaces from "pages/namespaces/Namespaces";
import DictWords from "pages/dictionaries/words/DictWords";
import Events from "pages/events/Events";
import Statistics from "pages/statistics/Statistics";
import Words from "pages/words/Words";
import Speakers from "pages/speakers/Speakers";
import Objects from "pages/objects/Objects";
import Labels from "pages/labels/Labels";
import Lists from "pages/lists/Lists";
import Compare from "pages/compare/Compare";
import Lid from "pages/lid/Lid";
import AmModels from "pages/amModels/AmModels";
import LmModels from "pages/lmModels/LmModels";
import BaseStations from "pages/baseStations/BaseStations";
import CodeCity from "pages/codeCity/CodeCity";
import IpAddresses from "pages/ipAddresses/IpAddresses";
import MacAddresses from "pages/macAddresses/MacAddresses";
import Map from "pages/map/Map";
import Analytica from "pages/analytica/Analytica";
import Filters from "pages/filters/Filters";
import PhoneNumbers from "pages/phoneNumbers/PhoneNumbers";
import WordsCloud from "pages/wordsCloud/WordsCloud";
import Charts from "pages/charts/Charts";
import Results from "pages/results/Results";
import TreeGraphWrapper from "pages/graph/treeGraph/TreeGraphWrapper";

type ProtectedRouteProps = {
  isLoggedIn: boolean;
} & RouteProps;

type AdminRouteProps = {
  isLoggedIn: boolean;
  isAdmin: boolean;
} & RouteProps;

const ProtectedRoute = ({ isLoggedIn, ...routeProps }: ProtectedRouteProps) => {
  return isLoggedIn ? <Route {...routeProps} /> : <Redirect to="/login" />;
};

const AdminRoute = ({ isLoggedIn, isAdmin, ...routeProps }: AdminRouteProps) => {
  return isLoggedIn && isAdmin ? <Route {...routeProps} /> : <Redirect to="/" />;
};

const App: FC = () => {
  const { isLoggedIn, isAdmin } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const wsConnect = useCallback(() => {
    const state = store.getState();
    const url = getSocketUrl(`${ws}?authorization=${state.auth.accessToken}`);
    const socket = new WebSocket(url);

    socket.onopen = () => {
      console.log("%c[WS OPEN] Соединение установлено", "background:#222;color:#3399ff;padding:3px 5px;");
      dispatch(setWsIsConnect(true));
    };

    socket.onmessage = (event) => {
      const { data } = event;
      const wsData: WsData = JSON.parse(data);
      // console.log("%c[WS MESSAGE] Получены данные с сервера:", "background:#222;color:#bada55;padding:3px 5px;");
      // console.log(wsData);
      if (wsData.type === WsDataType.SYSTEM) {
      }

      if (wsData.type === WsDataType.PUSH) {
        enqueueSnackbar(wsData.data, { variant: "success" });
      }

      if (wsData.type === WsDataType.SPEAKER_UPDATE_STATUS) {
        speakerStatusSubscriber.next(wsData);
      }

      if (wsData.type === WsDataType.RECORD_UPDATE_STATUS) {
        recordSubscriber.next(wsData);
      }

      if (wsData.type === WsDataType.MESSAGE_NEW) {
        messageSubscriber.next(wsData);
      }

      if (wsData.type === WsDataType.SYSTEM_ERROR_MESSAGE) {
        // если сообщение об ошибки равно пустой строке, скрываем
        if (wsData.data.message === "") {
          dispatch(hideSystemError());
        }
        // если сообщение об ошибки не равно пустой строке, показываем
        if (wsData.data.message !== "") {
          dispatch(showSystemError(wsData.data.message));
        }
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `%c[WS CLOSE] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`,
          "background:#222;color:#ffff4d;padding:3px 5px;"
        );
      } else {
        console.log("%c[WS CLOSE] Соединение прервано", "background:#222;color:#ffff4d;padding:3px 5px;");
      }
      setTimeout(wsConnect, 10000);
      dispatch(setWsIsConnect(false));
    };

    socket.onerror = (error: any) => {
      console.log(`%c[WS ERROR] ${error.message}`, "background:#222;color:#ff3333;padding:3px 5px;");
      socket.close();
    };
  }, [enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) return;

    wsConnect();
  }, [wsConnect, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;

    // сервисы
    AuthService.getServices()
      .then((res) => {
        const services = res?.data?.services ?? [];
        dispatch(setSystemServices(services));
      })
      .catch(catchError);

    // языки
    TechService.getTechByName("lid")
      .then(({ data }) => {
        const lid = data.techDetail as AdminTechLid;
        dispatch(setSystemLidLanguages(lid.languages));
      })
      .catch(catchError);

    // источники доступные в системе file, sftp, paragraf...
    AdminSourceService.getAvailableSources()
      .then(({ data }) => {
        dispatch(setSystemSources(data));
      })
      .catch(catchError);
  }, [dispatch, isLoggedIn]);

  return (
    <Router>
      <Switch>
        {/* Страница администратора */}
        <AdminRoute isLoggedIn={isLoggedIn} isAdmin={isAdmin} path={r.admin.path}>
          <AdminLayout>
            <Admin />
          </AdminLayout>
        </AdminRoute>

        {/* Главная */}
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={r.entry}>
          <Layout>
            <Entry />
          </Layout>
        </ProtectedRoute>

        {/* Результаты */}
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={r.results.path}>
          <Layout>
            <Results />
          </Layout>
        </ProtectedRoute>

        {/* sources Источники */}
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={r.sources.path}>
          <Layout>
            <Sources />
          </Layout>
        </ProtectedRoute>

        {/* tasks Задания */}
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={r.tasks.path}>
          <Layout>
            <Tasks />
          </Layout>
        </ProtectedRoute>

        {/* namespaces Пространства */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.namespaces.path}>
          <Layout>
            <Namespaces />
          </Layout>
        </ProtectedRoute>

        {/* dictionary Словари подсветки текста */}
        <ProtectedRoute isLoggedIn={isLoggedIn} exact path={r.dictionaries.path}>
          <Layout>
            <Dictionaries />
          </Layout>
        </ProtectedRoute>
        {/* Словари > Слова */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.dictionaries.words.path}>
          <Layout>
            <DictWords />
          </Layout>
        </ProtectedRoute>

        {/* filter Фильтры */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.filters.path}>
          <Layout>
            <Filters />
          </Layout>
        </ProtectedRoute>

        {/* events Контрольные события */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.events.path}>
          <Layout>
            <Events />
          </Layout>
        </ProtectedRoute>

        {/* statistic Статистика */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.statistic.path}>
          <Layout>
            <Statistics />
          </Layout>
        </ProtectedRoute>

        {/* statistic Графики */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.charts.path}>
          <Layout>
            <Charts />
          </Layout>
        </ProtectedRoute>

        {/* words Ключевые слова */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.words.path}>
          <Layout>
            <Words />
          </Layout>
        </ProtectedRoute>

        {/* speakers Дикторы */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.speakers.path}>
          <Layout>
            <Speakers />
          </Layout>
        </ProtectedRoute>

        {/* objects Объекты */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.objects.path}>
          <Layout>
            <Objects />
          </Layout>
        </ProtectedRoute>

        {/* labels Маркеры */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.labels.path}>
          <Layout>
            <Labels />
          </Layout>
        </ProtectedRoute>

        {/* lists Списки */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.lists.path}>
          <Layout>
            <Lists />
          </Layout>
        </ProtectedRoute>

        {/* compare Сравнения списков */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.compare.path}>
          <Layout>
            <Compare />
          </Layout>
        </ProtectedRoute>

        {/* lid Диалектно-языковая группы */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.lid.path}>
          <Layout>
            <Lid />
          </Layout>
        </ProtectedRoute>

        {/* am-models Языковые модели */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.amModels.path}>
          <Layout>
            <AmModels />
          </Layout>
        </ProtectedRoute>

        {/* lm-models Тематические модели */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.lmModels.path}>
          <Layout>
            <LmModels />
          </Layout>
        </ProtectedRoute>

        {/* base-stations Базовые станции */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.baseStations.path}>
          <Layout>
            <BaseStations />
          </Layout>
        </ProtectedRoute>

        {/* code-city Коды городов */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.codeCity.path}>
          <Layout>
            <CodeCity />
          </Layout>
        </ProtectedRoute>

        {/* phone-numbers Телефонные номера */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.phoneNumbers.path}>
          <Layout>
            <PhoneNumbers />
          </Layout>
        </ProtectedRoute>

        {/* ip-address IP-адреса */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.ipAddress.path}>
          <Layout>
            <IpAddresses />
          </Layout>
        </ProtectedRoute>

        {/* mac-address Mac-адреса */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.macAddress.path}>
          <Layout>
            <MacAddresses />
          </Layout>
        </ProtectedRoute>

        {/* map Карта */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.map.path}>
          <Layout>
            <Map />
          </Layout>
        </ProtectedRoute>

        {/* analytica Аналитика */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.analytica.path}>
          <Layout>
            <Analytica />
          </Layout>
        </ProtectedRoute>

        {/* graphs Графы */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.graph.path}>
          <Layout>
            <TreeGraphWrapper />
          </Layout>
        </ProtectedRoute>

        {/* wordsCloud Облако слов */}
        <ProtectedRoute isLoggedIn={isLoggedIn} path={r.wordsCloud.path}>
          <Layout>
            <WordsCloud />
          </Layout>
        </ProtectedRoute>

        {/* Авторизация */}
        <Route path={r.login}>
          <Login />
        </Route>

        {/* 404 */}
        <Route path="*">
          <Layout>
            <Error404 />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
