import React, { FC, useEffect, useRef } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

// material ui
import { Divider, IconButton, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import PlayArrow from "@material-ui/icons/PlayArrow";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import { grey } from "@material-ui/core/colors";
import StopIcon from "@material-ui/icons/Stop";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SettingsIcon from "@material-ui/icons/Settings";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0,
    backgroundColor: "rgba(0, 150, 136, .1)",
  },
  btns: {
    margin: "9px 12px 0 16px",
  },
  channelsContainer: {
    display: "flex",
    alignItems: "center",
  },
  channelButton: {
    width: 18,
    height: 36,
    cursor: "pointer",
    "&.disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  },
  leftChannel: {
    width: 19,
    height: 40,
    marginRight: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: "5px 0 0 5px",
  },
  rightChannel: {
    width: 19,
    height: 40,
    display: "flex",
    alignItems: "center",
    marginRight: 5,
    borderRadius: "0 5px 5px 0",
  },
  activeChannel: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#3b9188",
    },
  },
  inactiveChannel: {
    backgroundColor: grey[400],
  },
  btn: {
    border: "1px solid " + grey[400],
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
    borderRadius: 5,
  },
  icon: {
    color: grey[400],
  },
  mr5: {
    marginRight: 5,
  },
  waveform: {
    flexGrow: 1,
    position: "relative",
  },
  canvas: {
    width: "100%",
    height: 100,
  },
  mTop15: {
    marginTop: "-15px",
  },
  time: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: 125,
    marginTop: 8,
    padding: "0 20px",
    color: grey[700],
    fontSize: theme.typography.fontSize + 5,
  },
  row: {
    display: "flex",
    marginBottom: 5,
  },
  kv: {
    height: 25,
    display: "flex",
    margin: "0 -16px 0 -16px",
    backgroundColor: "#f2f2f2",
  },
  fs: {
    width: 275,
  },
  kvl: {
    width: 2025,
    position: "relative",
  },
  w1: {
    border: "1px solid " + grey[600],
    position: "absolute",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
    height: 25,
    zIndex: theme.zIndex.modal,
    cursor: "pointer",
    fontSize: theme.typography.fontSize + 4,
    color: grey[800],
    padding: "0 5px",
    "&:hover": {
      zIndex: theme.zIndex.modal + 10,
    },
  },
  p1: {
    position: "absolute",
    left: 142,
    width: 1,
    height: 125,
    backgroundColor: grey[600],
    zIndex: theme.zIndex.modal + 1,
  },
  player: {
    height: 115,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
  },
  speedIcon: {
    fontWeight: 600,
    fontSize: "17px",
    marginTop: "5px",
  },
  activeSpeedBtn: {
    // если не 1х стоит, то цвет на hover меняю, чтобы видно было? Или лишнее?
    backgroundColor: "#3b9188",
  },
  scrollContainer: {
    height: 10,
    backgroundColor: "#f5f5f5",
    position: "relative",
    cursor: "pointer",
    marginTop: 5,
    width: "100%",
  },
  scrollThumb: {
    position: "relative",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    cursor: "grab",
    "&:active": {
      cursor: "grabbing",
    },
    "&:hover": {
      opacity: 1,
    },
  },
  progressCanvas: {
    zIndex: 2,
  },
  top34: {
    top: 34,
  },
  mr8: {
    marginRight: "8px",
  },
  ml8: {
    marginLeft: "8px",
  },
}));

const EmptyPlayer: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  //чтобы та же циферка была
  const currentSpeed = useSelector((state: RootState) => state.settings.playbackSpeed);

  useEffect(() => {
    if (canvasRef.current === null) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (ctx === null) return;

    const dpr = window.devicePixelRatio || 1;
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = 100 * dpr;
    const padding = 5;

    ctx.scale(dpr, dpr);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();
    ctx.strokeStyle = grey[400];
    ctx.lineWidth = 1;
    ctx.moveTo(0, canvas.height / 2 + padding);
    ctx.lineTo(canvas.width, canvas.height / 2 + padding);
    ctx.stroke();
  }, [theme]);

  return (
    <div className={classes.root}>
      <div className={classes.player}>
        <div className={classes.btns}>
          <div className={classes.row}>
            <div className={classes.btn} title="Предыдущая запись">
              <SkipPreviousIcon className={classes.icon} />
            </div>

            <div className={classes.btn} title="Предыдущий сегмент">
              <RotateLeftIcon className={classes.icon} />
            </div>

            <div className={classes.btn}>
              <PlayArrow fontSize="large" className={classes.icon} />
            </div>

            <div className={classes.btn} title="Стоп">
              <StopIcon className={classes.icon} />
            </div>

            <div className={classes.btn} title="Следующий сегмент">
              <RotateRightIcon className={classes.icon} />
            </div>

            <div className={classes.btn} title="Следующая запись">
              <SkipNextIcon className={classes.icon} />
            </div>
          </div>

          <div className={classes.row}>
            {/* Настройки */}
            <div className={classes.btn} title="Настройки">
              <SettingsIcon className={classes.icon} />
            </div>

            {/* Наушники по условию */}
            <div className={classes.channelsContainer}>
              <div title="Левый канал" className={clsx(classes.inactiveChannel, classes.leftChannel)}>
                <img src="/img/left-headset-cut.png" alt="left headset" width={13} />
              </div>
              <div title="Правый канал" className={clsx(classes.inactiveChannel, classes.rightChannel)}>
                <img src="/img/right-headset-cut.png" alt="right headset" width={13} />
              </div>
            </div>
            <div className={classes.btn}>
              <IconButton color="primary" size="medium" title="Увеличить масштаб" style={{ cursor: "unset" }}>
                <img src="/img/iconzoomindisabled.svg" alt="Увеличить масштаб" width={24} className={classes.icon} />
              </IconButton>
            </div>
            <div className={classes.btn}>
              <IconButton color="primary" size="medium" title="Уменьшить масштаб" style={{ cursor: "unset" }}>
                <img
                  src="/img/iconzoomoutdisabled.svg"
                  alt="Уменьшить масштаб"
                  width={24}
                  className={classes.icon}
                  style={{ strokeWidth: 3 }}
                />
              </IconButton>
            </div>
            <div className={clsx(classes.btn)} title="Скорость воспроизведения">
              <span className={clsx(classes.icon, classes.speedIcon)}>{currentSpeed}x</span>
            </div>
            {/* Громкость */}
            <div className={classes.btn} title="Громкость">
              <VolumeUpIcon className={classes.icon} />
            </div>
          </div>
        </div>
        <Divider orientation="vertical" className={classes.mr8} />
        <div className={classes.waveform}>
          <canvas ref={canvasRef} className={classes.canvas} />
        </div>
        <Divider orientation="vertical" className={classes.ml8} />
        <div className={classes.time}>
          <div>{"00:00"}</div>
          <div>{"00:00"}</div>
        </div>
      </div>
    </div>
  );
};

export default EmptyPlayer;
