import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import { FormControl, IconButton, MenuItem, Popover, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import { RecordFileViewType } from "types";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: "#fff",
  },
  menuPaper: {
    minWidth: 220,
    paddingBottom: theme.spacing(2),
  },
  ratioItem: {
    width: 150,
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 120,
    padding: "6px 16px 6px 15px",
    marginTop: "3px",
  },
  ratioBox: {
    display: "flex",
    width: "150px",
    height: "32px",
    border: "1px solid #CCC",
    borderRadius: "4px",
    overflow: "hidden",
    marginRight: theme.spacing(1),
  },
  ratioSegment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "bold",
  },
  ratioContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    width: "160px",
    height: 32,
  },
  ratioBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    borderRadius: 4,
    height: "100%",
    flexShrink: 0,
  },
  border: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  formControl: {
    width: "170px",
    margin: "0px 25px 10px 25px",
  },
  firstTitle: {
    margin: "20px 25px 10px 24px",
  },
  secondTitle: {
    marginLeft: "24px",
    marginTop: "8px",
  },
}));

interface RatioOption {
  value: string;
  value2: string;
  blocks: [number, number];
}

interface RatioButtonProps {
  currentRatio: string;
  onRatioChange: (ratio: string) => void;
  currentViewType: RecordFileViewType;
  onViewTypeChange: (viewType: RecordFileViewType) => void;
}

const RatioTypeMenuButton: FC<RatioButtonProps> = ({
  currentRatio,
  onRatioChange,
  currentViewType,
  onViewTypeChange,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ratioOptions: RatioOption[] = [
    { value: "75%", value2: "25%", blocks: [3, 1] },
    { value: "66%", value2: "33%", blocks: [2, 1] },
    { value: "50%", value2: "50%", blocks: [1, 1] },
    { value: "33%", value2: "66%", blocks: [1, 2] },
    { value: "25%", value2: "75%", blocks: [1, 3] },
  ];

  const viewTypeOptions = [
    { value: RecordFileViewType.AUDIO, label: "Аудио" },
    { value: RecordFileViewType.VIDEO, label: "Видео" },
    { value: RecordFileViewType.TEXT, label: "Текст" },
    { value: RecordFileViewType.BITS, label: "Бинарный (Текст)" },
  ];

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRatioSelect = (ratio: string) => {
    onRatioChange(ratio);
    handleClose();
  };

  const handleViewTypeChange = (event: ChangeEvent<{ value: any }>) => {
    onViewTypeChange(event.target.value as RecordFileViewType);
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-controls="ratio-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.iconButton}
        style={{ padding: 8, marginBottom: 6 }}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        id="ratio-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography className={classes.firstTitle}>Тип отображения:</Typography>
        <FormControl className={classes.formControl}>
          <Select
            labelId="view-type-select-label"
            id="view-type-select"
            value={currentViewType}
            onChange={handleViewTypeChange}
          >
            {viewTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.secondTitle}>
          <Typography>Соотношение сторон:</Typography>
        </div>
        {ratioOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleRatioSelect(option.value)}
            className={classes.menuItem}
            selected={currentRatio === option.value}
          >
            <div className={classes.ratioContainer}>
              <div className={`${classes.ratioBlock} ${classes.border}`} style={{ width: option.value2 }}>
                {option.value2}
              </div>
              <div className={`${classes.ratioBlock} ${classes.border}`} style={{ width: option.value }}>
                {option.value}
              </div>
            </div>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default RatioTypeMenuButton;
