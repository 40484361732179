import React, { useState, FC, useCallback, useEffect, ChangeEvent } from "react";
import { Source } from "types/source";
import { useDispatch } from "react-redux";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import useSources from "hooks/useSources";
import { showSuccessAlert } from "redux/actions/alertActions";
import SourceService from "services/SourceService";
import { addRow, deselectAll, removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { Permission } from "types/permission";
import { setPageSourcesTableSettings } from "redux/actions/pageSettingsActions";
import { catchError } from "functions/common";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import SourcesTable from "./components/SourcesTable";
import CreateUpdateDialog from "./components/CreateUpdateDialog";
import SetSourcePermissions from "./components/SetSourcePermissions";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  empty: {
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  progress: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: 10,
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  ml4: {
    marginLeft: theme.spacing(4),
  },
  mr10: {
    marginRight: 10,
  },
  pagination: {
    paddingTop: 5,
  },
}));

const Sources: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState<Source[]>([]);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openSetPermissionsDialog, setOpenSetPermissionsDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const { error, sources, getSources } = useSources();

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageSourcesTableSettings(colState));
    }
  };

  const deselect = () => {
    deselectAll(gridApi);
    setSelectedRows([]);
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (gridApi) {
      gridApi.setQuickFilter(event.target.value);
    }
  };

  const handleCloseCreateDialog = (data?: Source) => {
    setOpenCreateDialog(false);

    if (data === undefined) return;
    SourceService.create(data)
      .then(({ data }) => addRow(data, gridApi))
      .catch(catchError);
  };

  const handleCloseEditDialog = (data?: any) => {
    setOpenEditDialog(false);
    if (data !== undefined) {
      SourceService.update(data)
        .then((res) => {
          updateRow(res.data, gridApi);
          deselect();
        })
        .catch(catchError);
    }
  };

  const onCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteConfirmationDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      SourceService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch(catchError);
    }
  };

  const isDisabledRemove = useCallback(() => {
    if (selectedRows.length === 0) return true;
    return !selectedRows.every((n) => n.isCanWrite);
  }, [selectedRows]);

  const isDisabledUpdate = useCallback(() => {
    if (selectedRows.length !== 1) return true;
    return !selectedRows[0].isCanWrite;
  }, [selectedRows]);

  // изменение прав
  const handleCloseChangePermissionsDialog = (data?: Permission[]) => {
    setOpenSetPermissionsDialog(false);
    if (data !== undefined && selectedRows.length === 1) {
      const { id } = selectedRows[0];
      SourceService.setPermissions(id, data)
        .then(() => {
          dispatch(showSuccessAlert("Права изменены"));
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    if (error === undefined) return;
    catchError(error);
  }, [error]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.actions}>
              <div className={classes.buttons}>
                <div>
                  <IconButton
                    className={classes.mr10}
                    onClick={() => setOpenSettingsTableDialog(true)}
                    title="Настройка колонок таблицы"
                    size="small"
                  >
                    <GridOnIcon fontSize="inherit" />
                  </IconButton>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.refresh}
                    onClick={() => getSources()}
                    variant="contained"
                  >
                    Обновить
                  </Button>
                  <TextField
                    className={classes.search}
                    placeholder="Имя источника"
                    onChange={onQuickFilterChanged}
                    InputProps={{
                      className: classes.searchInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={isDisabledUpdate()}
                    onClick={() => setOpenSetPermissionsDialog(true)}
                  >
                    Права
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={isDisabledRemove()}
                    onClick={() => setOpenDeleteConfirmationDialog(true)}
                  >
                    Удалить
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length !== 1}
                    onClick={() => setOpenEditDialog(true)}
                  >
                    Редактировать
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.button}
                    onClick={() => setOpenCreateDialog(true)}
                  >
                    Создать
                  </Button>
                </div>
              </div>
            </div>

            <SourcesTable
              rowData={sources}
              setGridApi={setGridApi}
              setColumnApi={setColumnApi}
              onSelection={onSelection}
            />
          </Paper>
        </Grid>
      </Grid>

      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      <ConfirmationDialog open={openDeleteConfirmationDialog} onClose={onCloseDeleteDialog} />

      <CreateUpdateDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />

      {selectedRows.length === 1 && (
        <>
          <CreateUpdateDialog open={openEditDialog} onClose={handleCloseEditDialog} source={selectedRows[0]} />
          <SetSourcePermissions
            open={openSetPermissionsDialog}
            onClose={handleCloseChangePermissionsDialog}
            id={selectedRows[0].id}
          />
        </>
      )}
    </div>
  );
};

export default Sources;
