import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1.15",
  },
  src: {
    borderBottom: "1px solid #BBC2CA",
  },
  dst: {},
  separator: {
    margin: "4 0px",
    color: "#666",
  },
}));

const OperatorMacRenderer: FC<any> = (props) => {
  const classes = useStyles();
  const { srcMacName, dstMacName } = props.data;

  if (!srcMacName && !dstMacName) return <></>;

  return (
    <div className={classes.container}>
      <div className={classes.src}>{srcMacName ? srcMacName : "-"}</div>
      <div className={classes.dst}>{dstMacName ? dstMacName : "-"}</div>
    </div>
  );
};

export default OperatorMacRenderer;
