import React, { FC } from "react";
import ReactECharts from "echarts-for-react";
import { registerTheme } from "echarts";
import customPalette from "customPalette";
import { Record, TreeChartProps } from "types/treeGraph";

const color = customPalette.shades[0];

registerTheme("my_theme", {
  tree: {
    itemStyle: {
      borderWidth: 1,
      borderColor: color,
      color,
    },
    lineStyle: {
      width: "1",
      color,
    },
    symbolSize: "8",
    symbol: "emptyCircle",
    smooth: false,
    color: customPalette.greyFriends,
    //лэйбл у ноды
    label: {
      position: "top",
      color: "#000",
      fontSize: 14,
    },
    graph: {
      itemStyle: {
        borderWidth: 1,
        borderColor: color,
        color,
      },
      lineStyle: {
        width: "1",
        color,
      },
      symbolSize: "8",
      symbol: "emptyCircle",
      smooth: false,
      color: customPalette.greyFriends,
      //лэйбл у ноды
      label: {
        position: "top",
        color: "#000",
        fontSize: 14,
      },
    },
  },
});

const TreeChart: FC<TreeChartProps> = ({ data }) => {
  const formatTreeData = (data: Record[], root: string) => {
    const treeMap: { [key: string]: any } = {}; // Вспомогательная структура для хранения узлов отношений

    // 1. Создаем узлы в карте treeMap
    data.forEach((record) => {
      record.node.forEach((node) => {
        // Создаем родительский узел, если его еще не существует
        if (!treeMap[node.firstPeak]) {
          treeMap[node.firstPeak] = {
            name: node.firstPeak,
            children: [],
            value: 1, // Значение по умолчанию
          };
        }

        // Создаем дочерний узел, если его еще не существует
        if (!treeMap[node.secondPeak]) {
          treeMap[node.secondPeak] = {
            name: node.secondPeak,
            children: [],
            value: node.weight, // Устанавливаем вес узла
          };
        }

        // Добавляем дочерний узел в массив `children` родительского узла
        treeMap[node.firstPeak].children.push(treeMap[node.secondPeak]);
      });
    });

    // 2. Возвращаем дерево с указанного `root`
    return [treeMap[root]];
  };

  const getOption = () => {
    const treeData = formatTreeData(data.records, data.root);

    return {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        formatter: (param: any) => {
          // Проверяем, является ли узел корневым
          if (param.data.name === data.root) {
            return `${param.data.name}`; // Для корневого узла показываем только имя
          }
          return `${param.data.name}<br/>Вес: ${param.data.value}`; // Для остальных показываем имя и вес
        },
      },
      series: [
        {
          type: "tree",
          data: treeData, // Данные для дерева
          top: "1%",
          left: "7%",
          bottom: "1%",
          right: "20%",
          symbolSize: 14,
          edgeShape: "polyline", // Линии могут быть полилиниями или кривыми
          label: {
            position: "top",
            verticalAlign: "middle",
            align: "right",
            fontSize: 14,
          },
          leaves: {
            label: {
              position: "top",
              verticalAlign: "middle",
              align: "left",
            },
          },
          roam: "scale", //зум
          edgeLabel: {
            show: true, // Показываем лейблы на связях
            position: "middle", // Лейбл в центре связи
            formatter: (param: any) => {
              return `${param.data.value}`; // Отображаем вес связи
            },
            fontSize: 14,
            color: "#555",
          },
          expandAndCollapse: true, // Возможность сворачивания/разворачивания узлов
          animationDuration: 750,
          initialTreeDepth: -1, // Показываем все уровни
        },
      ],
    };
  };

  return (
    <ReactECharts option={getOption()} style={{ width: "100%", height: "calc(100vh - 115px)" }} theme="my_theme" />
  );
};

export default TreeChart;
