import React, { useState, FC, ReactNode, useRef, useEffect } from "react";
import { Record, RecordDetail, RecordFileViewType } from "types/record";

// components
import TabTechnologies from "./tabTechnologies/TabTechnologies";
import TabStt from "./tabSTT/TabSTT";
import NoData from "components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TabKeywords from "./tabKeywords/TabKeywords";
import { IconButton, Typography } from "@material-ui/core";
import RatioTypeMenuButton from "./RatioTypeMenuButton";
import { ChevronRight } from "@material-ui/icons";
import { ChevronLeft } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`result-tabpanel-${index}`}
      aria-labelledby={`result-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tabsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // background: "linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)",
    background:
      theme.palette.primary.gradient ?? "linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)",
    padding: "6px 8px 0px 0px",
    height: "51px",
  },
  tabsInnerContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    overflow: "hidden",
  },
  tabsWrapper: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    position: "relative",
  },
  tabs: {
    display: "flex",
    gap: theme.spacing(2),
    transition: "transform 0.3s ease",
    whiteSpace: "nowrap",
    "& > :first-child": {
      paddingLeft: "32px",
    },
  },
  tab: {
    cursor: "pointer",
    padding: "11px 16px 11px 16px",
    fontWeight: 500,
    color: "#f8f8f8",
    fontSize: 14,
  },
  activeTab: {
    color: "#fff",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  arrow: {
    cursor: "pointer",
    padding: theme.spacing(1),
    color: "#f8f8f8",
    marginBottom: "6px",
    "&:hover": {
      color: "#fff",
    },
    "&:disabled": {
      cursor: "default",
      color: "#aaa",
    },
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 320px)",
  },
}));

interface Props {
  recordDetail: RecordDetail | undefined;
  selectedRecord: Record | undefined;
  filterString: string;
  viewType: RecordFileViewType;
  currentRatio: string;
  onRatioChange: (newRatio: string) => void;
  onViewTypeChange: (viewType: RecordFileViewType) => void;
}

const ResultDetail: FC<Props> = ({
  recordDetail,
  selectedRecord,
  filterString,
  viewType,
  currentRatio,
  onRatioChange,
  onViewTypeChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  //для прокрутки табов
  const [offset, setOffset] = useState(0);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });
  const tabsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const tabs = ["ПРОСМОТР", "КЛЮЧЕВЫЕ СЛОВА", "ПОДРОБНО"];
  const tabWidth = 120;

  const isDrawlerOpen = useSelector((state: RootState) => state.drawer.open);

  // Проверяем, нужно ли показывать стрелки
  const checkOverflow = () => {
    if (tabsRef.current && wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.offsetWidth;
      const tabsWidth = tabsRef.current.scrollWidth;

      setShowArrows({
        left: offset < 0,
        right: offset > -(tabsWidth - wrapperWidth),
      });
    }
  };

  // Обработчик клика по стрелкам
  const handleScroll = (direction: "left" | "right") => {
    const step = tabWidth; // Шаг прокрутки (ширина одного таба)
    if (direction === "left") {
      setOffset((prev) => Math.min(prev + step, 0));
    } else {
      const maxOffset = tabsRef.current ? -(tabsRef.current.scrollWidth - wrapperRef.current!.offsetWidth) : 0;
      setOffset((prev) => Math.max(prev - step, maxOffset));
    }
  };

  const handleTabChange = (index: number) => {
    setValue(index);

    // Проверяем, виден ли выбранный таб
    if (tabsRef.current && wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.offsetWidth;
      const tabLeft = index * tabWidth;
      const tabRight = tabLeft + tabWidth;

      if (tabLeft < -offset) {
        setOffset(-tabLeft);
      } else if (tabRight > -offset + wrapperWidth) {
        setOffset(-(tabRight - wrapperWidth));
      }
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("ratioChange", checkOverflow);
    return () => window.removeEventListener("ratioChange", checkOverflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, currentRatio, isDrawlerOpen]);

  if (!recordDetail || !selectedRecord) {
    return (
      <div className={classes.root}>
        <div className={classes.tabsContainer}>
          <div className={classes.tabsInnerContainer}>
            {showArrows.left && (
              <IconButton className={classes.arrow} onClick={() => handleScroll("left")} disabled={offset >= 0}>
                <ChevronLeft />
              </IconButton>
            )}
            <div className={classes.tabsWrapper} ref={wrapperRef}>
              <div className={classes.tabs} ref={tabsRef} style={{ transform: `translateX(${offset}px)` }}>
                {tabs.map((tab, index) => (
                  <Typography
                    key={index}
                    className={`${classes.tab} ${value === index ? classes.activeTab : ""}`}
                    onClick={() => handleTabChange(index)}
                  >
                    {tab}
                  </Typography>
                ))}
              </div>
            </div>
            {showArrows.right && (
              <IconButton
                className={classes.arrow}
                onClick={() => handleScroll("right")}
                disabled={
                  tabsRef.current && wrapperRef.current
                    ? offset <= -(tabsRef.current.scrollWidth - wrapperRef.current.offsetWidth)
                    : true
                }
              >
                <ChevronRight />
              </IconButton>
            )}
          </div>
          <RatioTypeMenuButton
            currentRatio={currentRatio}
            onRatioChange={onRatioChange}
            currentViewType={viewType}
            onViewTypeChange={onViewTypeChange}
          />
        </div>
        <TabPanel value={value} index={0}>
          <div className={classes.noData}>
            <NoData />
          </div>
        </TabPanel>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        <div className={classes.tabsInnerContainer}>
          {showArrows.left && (
            <IconButton className={classes.arrow} onClick={() => handleScroll("left")} disabled={offset >= 0}>
              <ChevronLeft />
            </IconButton>
          )}
          <div className={classes.tabsWrapper} ref={wrapperRef}>
            <div className={classes.tabs} ref={tabsRef} style={{ transform: `translateX(${offset}px)` }}>
              {tabs.map((tab, index) => (
                <Typography
                  key={index}
                  className={`${classes.tab} ${value === index ? classes.activeTab : ""}`}
                  onClick={() => handleTabChange(index)}
                >
                  {tab}
                </Typography>
              ))}
            </div>
          </div>
          {showArrows.right && (
            <IconButton
              className={classes.arrow}
              onClick={() => handleScroll("right")}
              disabled={
                tabsRef.current && wrapperRef.current
                  ? offset <= -(tabsRef.current.scrollWidth - wrapperRef.current.offsetWidth)
                  : true
              }
            >
              <ChevronRight />
            </IconButton>
          )}
        </div>
        <RatioTypeMenuButton
          currentRatio={currentRatio}
          onRatioChange={onRatioChange}
          currentViewType={viewType}
          onViewTypeChange={onViewTypeChange}
        />
      </div>
      <TabPanel value={value} index={0}>
        <TabStt
          recordDetail={recordDetail}
          selectedRecord={selectedRecord}
          filterv2={filterString}
          viewType={viewType}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {selectedRecord ? <TabKeywords selectedRecord={selectedRecord} filterv2={filterString} /> : <NoData />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabTechnologies record={selectedRecord} />
      </TabPanel>
    </div>
  );
};

export default ResultDetail;
