import React, { FC, memo } from "react";
import clsx from "clsx";

// mui
import { grey } from "@material-ui/core/colors";
import { darken, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  channelsContainer: {
    display: "flex",
    alignItems: "center",
  },
  channelButton: {
    width: 18,
    height: 36,
    cursor: "pointer",
    "&.disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  },
  leftChannel: {
    width: 19,
    height: 40,
    marginRight: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: "5px 0 0 5px",
  },
  rightChannel: {
    width: 19,
    height: 40,
    display: "flex",
    alignItems: "center",
    marginRight: 5,
    borderRadius: "0 5px 5px 0",
  },
  activeChannel: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.15), // Затемняем основной цвет на 15%
    },
  },
  inactiveChannel: {
    backgroundColor: grey[400],
  },
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
  },
}));

const ChannelButtons: FC<{
  activeChannels: { left: boolean; right: boolean };
  isMono: boolean;
  toggleChannel: (channel: "left" | "right") => void;
}> = memo(({ activeChannels, isMono, toggleChannel }) => {
  const classes = useStyles();
  return (
    <div className={classes.channelsContainer}>
      <div
        title="Левый канал"
        onClick={() => toggleChannel("left")}
        className={clsx(classes.channelButton, classes.leftChannel, {
          [classes.activeChannel]: activeChannels.left,
          [classes.inactiveChannel]: !activeChannels.left,
          [classes.disabled]: isMono,
        })}
      >
        <img src="/img/left-headset-cut.png" alt="left headset" width={13} />
      </div>
      <div
        title="Правый канал"
        onClick={() => toggleChannel("right")}
        className={clsx(classes.channelButton, classes.rightChannel, {
          [classes.activeChannel]: activeChannels.right && !isMono,
          [classes.inactiveChannel]: !activeChannels.right || isMono,
          [classes.disabled]: isMono,
        })}
      >
        <img src="/img/right-headset-cut.png" alt="right headset" width={13} />
      </div>
    </div>
  );
});

export default ChannelButtons;
