import { Divider, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessAlert } from "redux/actions/alertActions";
import { Word } from "types/word";

const useStyles = makeStyles({
  menu: {
    position: "absolute",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    borderRadius: "4px",
    direction: "ltr",
    // если мимикрировать под agGrid
    // fontSize: '12px',
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    // fontWeight: 400,
    color: "#474747",
  },
  submenu: {
    listStyle: "none",
    margin: 0,
    padding: "0px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    zIndex: 1001,
    minWidth: "150px",
    maxHeight: "200px",
    overflowY: "auto",
    borderRadius: "4px",
    position: "absolute",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
  },
  submenuItem: {
    padding: "10px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grey[200],
    },
    "&:last-child": {
      borderBottom: "none",
    },
    //для длинного названия словарей ограничивать длину?
    maxWidth: 300,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  hover: {
    "&:hover": {
      backgroundColor: grey[200],
    },
  },
});

interface ContextMenuProps {
  x: number;
  y: number;
  isVisible: boolean;
  onSearch: () => void;
  onAddToDictionary: (dictionaryId: number) => void;
  hideMenu: () => void;
  dictionaries: Word[];
  wordText?: string;
}

const ContextMenu: FC<ContextMenuProps> = ({
  x,
  y,
  isVisible,
  onSearch,
  onAddToDictionary,
  hideMenu,
  dictionaries,
  wordText,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const dictionariesRef = useRef<HTMLUListElement | null>(null);
  const [isDictionariesVisible, setDictionariesVisible] = useState(false);
  const [dictionaryMenuPosition, setDictionaryMenuPosition] = useState<{
    horizontal: "left" | "right";
    vertical: "top" | "bottom";
  }>({
    horizontal: "right",
    vertical: "bottom",
  });

  const removeDotAtEnd = (str: string): string => {
    if (str.endsWith(".")) {
      return str.slice(0, -1);
    }
    return str;
  };

  const handleCopyDirectly = (event: any) => {
    event.stopPropagation();

    try {
      const textToCopy = wordText || "";
      const cleanWord = removeDotAtEnd(textToCopy);

      const tempElement = document.createElement("textarea");
      tempElement.value = cleanWord;
      tempElement.style.position = "fixed";
      tempElement.style.opacity = "0";
      document.body.appendChild(tempElement);
      tempElement.focus();
      tempElement.select();

      const successful = document.execCommand("copy");
      document.body.removeChild(tempElement);

      if (successful) {
        dispatch(showSuccessAlert("Текст скопирован"));
      } else {
        console.log("Не удалось скопировать текст");
      }
    } catch (error) {
      console.error("Ошибка при копировании:", error);
    } finally {
      hideMenu();
    }
  };

  // Закрытие меню при клике вне его области
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        hideMenu();
        setDictionariesVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideMenu]);

  useEffect(() => {
    if (isDictionariesVisible && dictionariesRef.current && menuRef.current) {
      const menuRect = menuRef.current.getBoundingClientRect();
      const dictionariesRect = dictionariesRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      const newPosition = { horizontal: "right" as "left" | "right", vertical: "bottom" as "top" | "bottom" };

      // Проверяем, хватает ли места справа
      if (menuRect.right + dictionariesRect.width > screenWidth) {
        newPosition.horizontal = "left";
      }

      // Проверяем, хватает ли места снизу
      if (menuRect.bottom + dictionariesRect.height > screenHeight) {
        newPosition.vertical = "top";
      }

      setDictionaryMenuPosition(newPosition);
    }
  }, [isDictionariesVisible]);

  if (!isVisible) return null;

  return (
    <div
      ref={menuRef}
      style={{
        top: y,
        left: x,
      }}
      className={classes.menu}
    >
      <ul style={{ listStyle: "none", margin: 0, padding: 0, lineHeight: 1.65 }}>
        <li style={{ padding: "10px 20px 5px 20px", cursor: "pointer" }} className={classes.hover} onClick={onSearch}>
          Поиск по слову
        </li>
        <li
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 20px 5px 20px",
            cursor: "pointer",
          }}
          onClick={() => setDictionariesVisible((prev) => !prev)}
          className={classes.hover}
        >
          <span>Добавить в ключевые слова</span>
          <span
            style={{
              fontSize: 12,
              paddingLeft: 5,
              color: grey[600],
            }}
          >
            &rarr;
          </span>
        </li>
        {isDictionariesVisible && (
          <ul
            ref={dictionariesRef}
            style={{
              top: dictionaryMenuPosition.vertical === "bottom" ? 0 : "auto",
              bottom: dictionaryMenuPosition.vertical === "top" ? 0 : "auto",
              left: dictionaryMenuPosition.horizontal === "right" ? "100%" : "auto",
              right: dictionaryMenuPosition.horizontal === "left" ? "100%" : "auto",
            }}
            className={classes.submenu}
          >
            {dictionaries.map((dictionary) => (
              <li
                key={dictionary.id}
                className={classes.submenuItem}
                onClick={() => {
                  onAddToDictionary(dictionary.id);
                  setDictionariesVisible(false);
                  hideMenu();
                }}
                title={dictionary.name}
              >
                {dictionary.name}
              </li>
            ))}
          </ul>
        )}
        <Divider />
        <li
          style={{ padding: "5px 20px 10px 20px", cursor: "pointer" }}
          className={classes.hover}
          onClick={(e) => {
            handleCopyDirectly(e);
          }}
        >
          Копировать
        </li>
      </ul>
    </div>
  );
};

export default ContextMenu;
