import React, { FC, ReactElement, useState } from "react";
import { IconButton, Popover, Slider, makeStyles, Tooltip, Typography } from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { Link } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  volumeContainer: {
    padding: theme.spacing(2),
    width: 200,
  },
  sliderContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    marginLeft: "4px",
    height: "220px",
  },
  sliderLeft: {
    color: theme.palette.audioWaveform?.leftSegmentColor,
    marginRight: theme.spacing(1),
  },
  sliderRight: {
    color: theme.palette.audioWaveform?.rightSegmentColor,
    marginRight: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  volumeValue: {
    marginTop: theme.spacing(1),
  },
  slidersContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  valueLabelLeft: {
    backgroundColor: theme.palette.audioWaveform?.leftSegmentColor,
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 0.5),
    fontSize: "12px",
    transform: "translateX(10px)",
  },
  valueLabelRight: {
    backgroundColor: theme.palette.audioWaveform?.rightSegmentColor,
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 0.5),
    fontSize: "12px",
    transform: "translateX(10px)",
  },
  icon: {
    color: "white",
  },
  mtop: {
    marginTop: theme.spacing(1),
  },
  sliderWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  headset: {
    marginLeft: theme.spacing(1),
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
}));

interface ValueLabelProps {
  children: ReactElement;
  open: boolean;
  value: number;
}

// Кастомный компонент для отображения значения
function ValueLabelLeftComponent(props: ValueLabelProps) {
  const classes = useStyles();
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="right"
      title={<span className={classes.valueLabelLeft}>{value}%</span>}
      classes={{
        tooltip: classes.valueLabelLeft,
      }}
    >
      {children}
    </Tooltip>
  );
}

function ValueLabelRightComponent(props: ValueLabelProps) {
  const classes = useStyles();
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="right"
      title={<span className={classes.valueLabelRight}>{value}%</span>}
      classes={{
        tooltip: classes.valueLabelRight,
      }}
    >
      {children}
    </Tooltip>
  );
}

interface VolumeControlProps {
  leftVolume: number;
  rightVolume: number;
  setLeftVolume: (volume: number) => void;
  setRightVolume: (volume: number) => void;
  activeChannels: {
    left: boolean;
    right: boolean;
  };
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

const VolumeControl: FC<VolumeControlProps> = ({
  leftVolume,
  rightVolume,
  setLeftVolume,
  setRightVolume,
  activeChannels,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [syncVolume, setSyncVolume] = useState<boolean>(true);
  const open = Boolean(anchorEl);
  const id = open ? "volume-popover" : undefined;

  // Преобразование значений для отображения в процентах (0-100)
  const leftVolumePercent = Math.round(((leftVolume - 0.1) / 10) * 100);
  const rightVolumePercent = Math.round(((rightVolume - 0.1) / 10) * 100);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSyncVolume = () => {
    setSyncVolume(!syncVolume);
  };

  const handleLeftVolumeChange = (_event: any, newValue: number | number[]) => {
    const value = newValue as number;
    // Преобразование процентов обратно в исходный диапазон (0.1-10.1)
    const scaledValue = 0.1 + (value / 100) * 10;
    setLeftVolume(scaledValue);

    // Если синхронизация включена, изменяем громкость правого канала
    if (syncVolume && activeChannels.right) {
      setRightVolume(scaledValue);
    }
  };

  const handleRightVolumeChange = (_event: any, newValue: number | number[]) => {
    const value = newValue as number;
    // Преобразование процентов обратно в исходный диапазон (0.1-10.1)
    const scaledValue = 0.1 + (value / 100) * 10;
    setRightVolume(scaledValue);

    // Если синхронизация включена, изменяем громкость левого канала
    if (syncVolume && activeChannels.left) {
      setLeftVolume(scaledValue);
    }
  };

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <VolumeUpIcon className={classes.icon} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.volumeContainer}>
          <div className={classes.slidersContainer}>
            <div className={classes.sliderWrapper}>
              <div className={classes.sliderContainer}>
                <Slider
                  className={classes.sliderLeft}
                  orientation="vertical"
                  value={leftVolumePercent}
                  onChange={handleLeftVolumeChange}
                  disabled={!activeChannels.left}
                  min={0}
                  max={100}
                  marks={marks}
                  valueLabelDisplay="auto"
                  ValueLabelComponent={ValueLabelLeftComponent}
                />
              </div>
            </div>

            <div className={classes.sliderWrapper}>
              <div className={classes.sliderContainer}>
                <Slider
                  className={classes.sliderRight}
                  orientation="vertical"
                  value={rightVolumePercent}
                  onChange={handleRightVolumeChange}
                  disabled={!activeChannels.right}
                  min={0}
                  max={100}
                  marks={marks}
                  valueLabelDisplay="auto"
                  ValueLabelComponent={ValueLabelRightComponent}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <Typography>Левый</Typography>
            <IconButton onClick={toggleSyncVolume}>
              <Link className={syncVolume ? classes.primaryColor : ""} fontSize="large" />
            </IconButton>
            <Typography>Правый</Typography>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default VolumeControl;
