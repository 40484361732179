import React, { FC } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import customPalette from "customPalette";
import { GraphData, MatrixGraphProps } from "types/treeGraph";

const color = customPalette.shades[0];

echarts.registerTheme("my_theme", {
  tree: {
    itemStyle: {
      borderWidth: 1,
      borderColor: color,
      color,
    },
    lineStyle: {
      width: "1",
      color,
    },
    symbolSize: "8",
    symbol: "emptyCircle",
    smooth: false,
    color: customPalette.greyFriends,
    //лэйбл у ноды
    label: {
      position: "top",
      color: "#000",
      fontSize: 14,
    },
    graph: {
      itemStyle: {
        borderWidth: 1,
        borderColor: color,
        color,
      },
      lineStyle: {
        width: "1",
        color,
      },
      symbolSize: "8",
      symbol: "emptyCircle",
      smooth: false,
      color: customPalette.greyFriends,
      //лэйбл у ноды
      label: {
        position: "top",
        color: "#000",
        fontSize: 14,
      },
    },
  },
});

const GraphChart: FC<MatrixGraphProps> = ({ data }) => {
  const formatGraphData = (data: GraphData[]) => {
    const nodes: any[] = [];
    const edges: any[] = [];
    const nodeMap: { [key: string]: boolean } = {};
    const nodeCategories: string[] = [];

    if (data) {
      data.forEach((item) => {
        if (!item.adjacencyMatrix || !item.adjacencyMatrix.nodes) {
          console.log("неверный формат данных", item);
          return; // если данных нет
        }

        const matrix = item.adjacencyMatrix.nodes;

        // Добавляем узлы и связи
        Object.keys(matrix).forEach((source) => {
          if (!nodeMap[source]) {
            nodes.push({
              id: source,
              name: source,
              symbolSize: 30,
              category: source,
            });
            nodeMap[source] = true;
            nodeCategories.push(source);
          }

          Object.keys(matrix[source]).forEach((target) => {
            if (!nodeMap[target]) {
              nodes.push({
                id: target,
                name: target,
                symbolSize: 30,
                category: target,
              });
              nodeMap[target] = true;
              nodeCategories.push(target);
            }

            if (matrix[source][target] > 0) {
              edges.push({
                source: source,
                target: target,
                value: matrix[source][target],
                lineStyle: {
                  width: Math.min(10, 2 + matrix[source][target] * 0.5),
                  color: "#aaa",
                },
              });
            }
          });
        });
      });
    }

    const uniqueCategories = Array.from(new Set(nodeCategories));

    return { nodes, edges, uniqueCategories };
  };

  const getOption = () => {
    const { nodes, edges, uniqueCategories } = formatGraphData(data);

    return {
      tooltip: {
        trigger: "item",
        formatter: (param: any) => {
          if (param.dataType === "edge") {
            return `${param.data.source} - ${param.data.target}<br/>Вес: ${param.data.value}`;
          }
          return `${param.data.name}`;
        },
      },
      legend: {
        data: uniqueCategories, // Легенда по узлам
        orient: "vertical",
        left: "left",
        top: "top",
      },
      draggable: false, //Оставляем или убираем?
      series: [
        {
          type: "graph",
          layout: "force",
          data: nodes,
          links: edges,
          categories: uniqueCategories.map((category, index) => ({
            name: category,
          })),
          edgeLabel: {
            show: true,
            formatter: (param: any) => {
              return `${param.data.value}`;
            },
          },
          roam: "scale", //маштабирование колёсиком
          label: {
            show: true,
            position: "bottom",
            formatter: "{b}", // Имя узла. Что показываем?
          },
          force: {
            repulsion: 300,
            edgeLength: [50, 150],
          },
          // Удаляем стрелки
          lineStyle: {
            color: "source",
            curveness: 0.3,
          },
          emphasis: {
            focus: "adjacency",
            lineStyle: {
              width: 10,
            },
          },
        },
      ],
    };
  };

  return (
    <div>
      <ReactECharts option={getOption()} style={{ width: "100%", height: "calc(100vh - 115px)" }} theme={"my_theme"} />
    </div>
  );
};

export default GraphChart;
