import React, { FC } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  date: Date | undefined;
  onDateChange: (dt: Date) => void;
  disabled?: boolean;
  required?: boolean;
}

const Picker: FC<Props> = ({ date, onDateChange, disabled, required }) => {
  registerLocale("ru", ru);

  return (
    <DatePicker
      required={required}
      // popperPlacement='Введите дату'
      placeholderText="Введите дату"
      timeInputLabel="Введите дату"
      selected={date}
      onChange={onDateChange}
      showTimeSelect
      timeFormat="HH:mm:ss"
      timeIntervals={15}
      timeCaption="время"
      locale={ru}
      dateFormat="dd-MM-yyyy HH:mm:ss"
      className="date-picker"
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
      disabled={disabled}
    />
  );
};

export default Picker;
