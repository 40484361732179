import React, { FC, useMemo } from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  kv: {
    height: 25,
    display: "flex",
    position: "relative",
    overflow: "visible", // не ставить hidden, чтобы было видно слова на последних секундах. Нужно проверить очень длинное слово.
  },
  fs: {
    flexShrink: 0,
  },
  kvl: {
    position: "relative",
    flexGrow: 1,
    minWidth: 0,
  },
  w1: {
    border: "1px solid " + grey[600],
    position: "absolute",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
    height: 25,
    zIndex: theme.zIndex.modal,
    cursor: "pointer",
    fontSize: theme.typography.fontSize + 2,
    color: grey[800],
    padding: "0 5px",
    whiteSpace: "nowrap",
    "&:hover": {
      zIndex: theme.zIndex.modal + 1,
    },
    userSelect: "none",
  },
}));

interface KeywordsOverlayProps {
  markers: { color: string; words: { word: string; start: number; end?: number }[] }[];
  duration: number;
  onClick: (start: number) => void;
}

const KeywordsOverlay: FC<KeywordsOverlayProps> = ({ markers, duration, onClick }) => {
  const classes = useStyles();

  const wordsToRender = useMemo(() => {
    const allWords: { word: string; start: number; end?: number; color: string }[] = [];
    markers.forEach((marker) => {
      marker.words.forEach((word) => {
        allWords.push({ ...word, color: marker.color });
      });
    });

    return allWords;
  }, [markers]);

  return (
    <div className={classes.kv}>
      <div className={classes.fs} />
      <div className={classes.kvl}>
        {wordsToRender.map((word, index) => {
          const left = (word.start / duration) * 100 + "%";

          return (
            <div
              key={index}
              className={classes.w1}
              style={{ left, backgroundColor: word.color }}
              onClick={() => onClick(word.start)}
            >
              {word.word}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KeywordsOverlay;
