import { useCallback, useEffect } from "react";

export const useAudioHotkeys = (callbacks: {
  playPause: () => void;
  prevSegment: () => void;
  nextSegment: () => void;
}) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const activeElement = document.activeElement;
      if (activeElement?.tagName === "TEXTAREA" || activeElement?.tagName === "INPUT") return;

      switch (e.key) {
        case " ":
          e.preventDefault();
          callbacks.playPause();
          break;
        case "ArrowLeft":
          e.preventDefault();
          callbacks.prevSegment();
          break;
        case "ArrowRight":
          e.preventDefault();
          callbacks.nextSegment();
          break;
      }
    },
    [callbacks]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return { handleKeyDown };
};
