import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1.15",
  },
  src: {
    borderBottom: "1px solid #BBC2CA",
  },
  dst: {},
  separator: {
    margin: "4 0px",
    color: "#666",
  },
}));

const reverb: any = {
  none: "Нет",
  small: "Низкая",
  medium: "Средняя",
  high: "Высокая",
  extreme: "Экстремальная",
};

const ReverbRenderer: FC<any> = (props) => {
  const classes = useStyles();
  const { srcReverb, dstReverb } = props.data;

  if (srcReverb === "" && dstReverb === "") return <></>;

  return (
    <div className={classes.container}>
      <div className={classes.src}>{srcReverb === "" ? "-" : reverb[srcReverb]}</div>
      <div className={classes.dst}>{dstReverb === "" ? "-" : reverb[dstReverb]}</div>
    </div>
  );
};

export default ReverbRenderer;
