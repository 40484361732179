import React, { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setThemePrimaryColor } from "redux/actions/settingsActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  blue,
  blueGrey,
  brown,
  cyan,
  deepPurple,
  green,
  grey,
  indigo,
  pink,
  purple,
  red,
  teal,
} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 96px)",
    padding: theme.spacing(1),
  },
  colorGrid: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  colorSwatch: {
    width: 40,
    height: 40,
    borderRadius: 4,
    cursor: "pointer",
    border: "2px solid transparent",
    transition: "border-color 0.2s ease",
    "&:hover": {
      boxShadow: "0 0 5px rgba(0,0,0,0.2)",
    },
  },
  selectedSwatch: {
    border: `2px solid ${theme.palette.divider}`,
    boxShadow: "0 0 8px rgba(0,0,0,0.3)",
  },
}));

const Theme: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.settings);

  const colors = useMemo(() => {
    return [
      teal[300],
      red[300],
      pink[200],
      purple[200],
      deepPurple[300],
      indigo[300],
      blue[300],
      cyan[300],
      green[300],
      brown[300],
      grey[500],
      blueGrey[300],
    ];
  }, []);

  const handleChangeColor = (color: string) => {
    dispatch(setThemePrimaryColor(color));
  };

  // Определяем текущий цвет
  const currentColor = settings.themePrimaryColor || teal[300];

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Основной цвет
          </Typography>
          <div className={classes.colorGrid}>
            {colors.map((color) => (
              <div
                key={color}
                className={`${classes.colorSwatch} ${color === currentColor ? classes.selectedSwatch : ""}`}
                style={{ backgroundColor: color }}
                onClick={() => handleChangeColor(color)}
                title={color}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Theme;
