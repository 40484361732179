import React, { FC } from "react";
import { createMuiTheme, ThemeProvider, ThemeOptions } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { createExtendedPalette, createAudioWaveformColors, getSecondaryColor } from "functions/theme";
import { teal } from "@material-ui/core/colors";

const MaterialUiThemeProvider: FC = ({ children }) => {
  const settings = useSelector((state: RootState) => state.settings);

  // Получаем параметры темы из settings
  const primaryColor = settings.themePrimaryColor || teal[300];
  const secondaryColor = settings.themeSecondaryColor || getSecondaryColor(primaryColor);
  const fontFamily = settings.themeFontFamily || "Arial, sans-serif";

  // Создаем объект темы
  const themeOptions: ThemeOptions = {
    palette: {
      primary: {
        ...createExtendedPalette(primaryColor),
        contrastText: "#FFF",
      },
      secondary: {
        ...createExtendedPalette(secondaryColor),
      },
      audioWaveform: createAudioWaveformColors(primaryColor),
    },
    typography: {
      body1: {
        fontFamily: fontFamily,
      },
      button: {
        fontFamily: fontFamily,
      },
    },
  };

  const theme = createMuiTheme(themeOptions, ruRU);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialUiThemeProvider;
