import React, { FC } from "react";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { humanFileSize } from "functions/common";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: 200,
  },
}));

const defaultColDef = {
  resizable: true,
};

const columnDefs = [
  {
    headerName: "Имя",
    field: "name",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    flex: 1,
  },
  {
    headerName: "Размер",
    field: "size",
    maxWidth: 100,
    valueGetter: (params: any) => {
      const { size } = params.data;
      if (size) {
        return humanFileSize(size);
      }
      return "";
    },
  },
];

interface Props {
  rowData: File[];
  setGridApi: Function;
  onSelection: Function;
}

const SettingsTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
      />
    </div>
  );
};

export default SettingsTable;
