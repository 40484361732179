import { useCallback, useState } from "react";

interface ContextMenuProps {
  x: number;
  y: number;
  isVisible: boolean;
  showMenu: (x: number, y: number) => void;
  hideMenu: () => void;
}

export const useWordRightClick = (): ContextMenuProps => {
  const [menuState, setMenuState] = useState({
    x: 0,
    y: 0,
    isVisible: false,
  });

  const showMenu = useCallback((x: number, y: number) => {
    setMenuState({ x, y, isVisible: true });
  }, []);

  const hideMenu = useCallback(() => {
    setMenuState((prevState) => ({ ...prevState, isVisible: false }));
  }, []);

  return {
    x: menuState.x,
    y: menuState.y,
    isVisible: menuState.isVisible,
    showMenu,
    hideMenu,
  };
};
