import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { Statistic as StatisticType } from "types/statistic";
import StatisticsService from "services/StatisticsService";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "../../types/queryBuilder";
import { generateUuid } from "../../functions/common";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";
import Progress from "components/Progress";
import StatisticsCard from "./components/StatisticsCard";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  paperWrapper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  input: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    font: "inherit",
    color: "currentColor",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    height: 30,
    margin: "0 0 0 10px",
    display: "block",
    padding: "5px 15px",
    background: "none",
    boxSizing: "content-box",
    borderRadius: 4,
    fontSize: "1rem",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.87)",
    },
    "&:focus": {
      border: "1px solid rgba(0, 0, 0, 0)",
      outline: "1px solid " + theme.palette.primary.main,
    },
  },
  img: {},
  button: {
    marginLeft: theme.spacing(1),
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input2: {
    flexGrow: 1,
    marginRight: 15,
  },
}));

interface State {
  loading: boolean;
  statistic: StatisticType | null;
  error: undefined | Error;
}

const Statistics: FC = () => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const initialState: State = useMemo(
    () => ({
      loading: false,
      statistic: null,
      error: undefined,
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<Group>(defaultFilter);

  const [state, setState] = useState<State>(initialState);
  const { loading, statistic, error } = state;

  const handleSearch = useCallback(() => {
    const searchStr = JSON.stringify(filter);
    const queryString = "?filterv2=" + searchStr;
    setState((prev) => ({ ...prev, loading: true }));
    StatisticsService.getAll(queryString)
      .then((res) => {
        setState(() => ({ statistic: res.data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState((prev) => ({ statistic: null, loading: false, error: err }));
      });
  }, [filter]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error, catchError]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paperWrapper}>
        <div className={classes.search}>
          <HistoryFilters setFilter={setFilter} />

          <div className={classes.input2}>
            <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
          </div>

          <Button variant="contained" color="primary" onClick={handleSearch}>
            Построить
          </Button>
        </div>
      </Paper>
      {loading && (
        <Paper className={classes.paperWrapper}>
          <Progress />
        </Paper>
      )}
      <div style={{ display: "flex", gap: "0.5rem" }}>
        {statistic && <StatisticsCard statistic={statistic} filter={JSON.stringify(filter)} />}
      </div>
    </div>
  );
};

export default Statistics;
