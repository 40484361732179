export default {
  genericGradient: ["#5ac7a7", "#76d79d", "#9ce58e", "#c8f07e", "#f9f871"],
  matchingGradient: ["#00a6bd", "#0092c7", "#507ac0", "#7f5da5", "#983c78"],
  spotPalette: ["#008078", "#c1fcf5", "#92006b"],
  twistedSpotPalette: ["#92006b", "#ffe5fe", "#926c82"],
  classyPalette: ["#334b48", "#96b1ad", "#88a6e0", "#5273a9"],
  cubePalette: ["#005d2e", "#ffa17a"],
  switchPalette: ["#00342f", "#af1285", "#f3eada"],
  smallSwitchPalette: ["#dfefe7", "#4e8a71"],
  skipGradient: ["#abf4a2", "#74bc6e", "#3f863d"],
  naturalPalette: ["#00aa9c", "#eefcfa", "#f3eada"],
  matchingPalette: ["#334b48", "#96b1ad", "#d492ba", "#9c5e85"],
  squashPalette: ["#d18fd5", "#afa956"],
  greyFriends: ["#334b48", "#96b1ad"],
  dottingPalette: ["#96b1ad", "#88a6e0", "#a4abbd"],
  skipShadeGradient: ["#62ccad", "#82e1aa", "#abf4a2"],
  threedom: ["#b797e9", "#db9856"],
  highlightPalette: ["#005b53", "#f3eada", "#ffa17a"],
  neighborPalette: ["#00a26c", "#1e654b", "#1e3028"],
  discreetPalette: ["#00aa9c", "#e5fffb", "#ffa17a"],
  dustPalette: ["#334b48", "#96b1ad", "#88a6e0"],
  collective: ["#00bbcf", "#6ca7ff"],
  friendPalette: ["#00534c", "#af1285", "#ff75d6"],
  pinPalette: ["#005b53", "#f3eada", "#92006b"],
  shades: ["#1d9188", "#006e66", "#004c46", "#002d28"],
  randomShades: ["#a7ffff", "#168e85", "#003e38", "#001a0f"],
};
