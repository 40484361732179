import React, { FC } from "react";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import { AccessDeleteRecord } from "types/admin";
import BooleanRenderer from "components/agGrid/renderers/BooleanRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "№",
    field: "id",
    width: 100,
    checkboxSelection: true,
  },
  {
    headerName: "Имя",
    field: "name",
  },
  {
    headerName: "Удалять записи",
    field: "isCanDelete",
    cellRenderer: "BooleanRenderer",
    flex: 1,
  },
  {
    headerName: "Имена пространств",
    field: "isShowNameSpaceName",
    cellRenderer: "BooleanRenderer",
    flex: 1,
  },
  {
    headerName: "Имена дикторов",
    field: "isShowSpeakersName",
    cellRenderer: "BooleanRenderer",
    flex: 1,
  },
  {
    headerName: "Имена Автоинформаторов",
    field: "isShowAutoInformatorsName",
    cellRenderer: "BooleanRenderer",
    flex: 1,
  },
];

interface Props {
  rowData: AccessDeleteRecord[];
  setGridApi: Function;
  onSelection: Function;
}

const AccessTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: AccessDeleteRecord) => String(data.id);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="single"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          DateRenderer,
          BooleanRenderer,
        }}
      />
    </div>
  );
};

export default AccessTable;
