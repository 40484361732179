import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ICellRendererParams } from "ag-grid-community";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1.15",
  },
  src: {
    borderBottom: "1px solid #BBC2CA",
  },
  dst: {},
  separator: {
    margin: "4px 0",
    color: "#666",
  },
}));

interface UniversalRendererParams extends ICellRendererParams {
  colDef: {
    cellRendererParams: {
      type: string;
    };
  };
}

const UniversalRenderer: FC<UniversalRendererParams> = (props) => {
  const classes = useStyles();

  // Получаем тип из параметров столбца
  const type = props.colDef.cellRendererParams?.type;
  if (!type) {
    return null;
  }

  // srcIMEI, dstIMEI, srcIMSI, dstIMSI
  const srcKey = `src${type}`;
  const dstKey = `dst${type}`;

  const { data } = props;

  if (!data || (!data[srcKey] && !data[dstKey])) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.src}>{data[srcKey] || "-"}</div>
      <div className={classes.dst}>{data[dstKey] || "-"}</div>
    </div>
  );
};

export default UniversalRenderer;
