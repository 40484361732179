import {
  SETTINGS_SET_ALL,
  SETTINGS_SET_DIARIZATION_HIGHLIGHT,
  SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS,
  SETTINGS_SET_PLAYER_CHANNEL,
  SETTINGS_SET_PLAYER_GAIN,
  SETTINGS_SET_PLAYER_IS_ADE_CLICK,
  SETTINGS_SET_PLAYER_IS_ECHO,
  SETTINGS_SET_PLAYER_IS_KTCH,
  SETTINGS_SET_PLAYER_IS_NOISE,
  SETTINGS_SET_PLAYER_IS_NORMALIZE,
  SETTINGS_SET_PLAYER_IS_PAN,
  SETTINGS_SET_PLAYER_WAVEFORM_TYPE,
  SETTINGS_SET_RESULT_PANEL_SIZE,
  SETTINGS_SET_ROWS_PER_PAGE,
  SETTINGS_SET_SORT_NAME,
  SETTINGS_SET_SORT_ORDER,
  SETTINGS_SET_STT_BACKGROUND_COLOR,
  SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT,
  SETTINGS_SET_STT_EDITE_MODE,
  SETTINGS_SET_STT_FONT,
  SETTINGS_SET_STT_FONT_SIZE,
  SETTINGS_SET_STT_SHOW_TRANSLATE,
  SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER,
  SETTINGS_SET_STT_TEXT_COLOR,
  SETTINGS_SET_ZOOM,
  SettingsAction,
  SettingsState,
  SET_SHOW_SEGMENTS,
  SET_PLAYBACK_SPEED,
  SET_AUTOPLAY,
  SET_ACTIVECHANNELS,
  SET_LOOPING,
  SET_APP_FILTER,
  SET_RATIO_RATE,
  SET_DICTIONARY,
  SET_AUDIO_SRC,
  SETTINGS_SET_THEME_PRIMARY_COLOR,
  SETTINGS_SET_THEME_TYPOGRAPHY,
  SETTINGS_SET_THEME_COMPLETE,
} from "redux/types/settingsTypes";
import { ChannelState, WaveformType } from "types/player";
import { Dictionary } from "types/keyword";

export function setPlayerIsPan(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_PAN,
    payload,
  };
}

export function setPlayerIsAdeClick(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_ADE_CLICK,
    payload,
  };
}

export function setPlayerIsNormalize(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_NORMALIZE,
    payload,
  };
}

export function setPlayerIsNoise(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_NOISE,
    payload,
  };
}

export function setPlayerIsKTCH(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_KTCH,
    payload,
  };
}

export function setPlayerIsEcho(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_IS_ECHO,
    payload,
  };
}

export function setPlayerChannel(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_CHANNEL,
    payload,
  };
}

export function setPlayerGain(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_GAIN,
    payload,
  };
}

export function setWaveformType(payload: WaveformType): SettingsAction {
  return {
    type: SETTINGS_SET_PLAYER_WAVEFORM_TYPE,
    payload,
  };
}

export function setZoom(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_ZOOM,
    payload,
  };
}

export function setRowsPerPage(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_ROWS_PER_PAGE,
    payload,
  };
}

export function setSttFont(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_STT_FONT,
    payload,
  };
}

export function setSttFontSize(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_STT_FONT_SIZE,
    payload,
  };
}

export function setSttTextColor(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_STT_TEXT_COLOR,
    payload,
  };
}

export function setSttBackGroundColor(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_STT_BACKGROUND_COLOR,
    payload,
  };
}

export function setSttSyncTextAndPlayer(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER,
    payload,
  };
}

export function setSttShowTranslate(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_SHOW_TRANSLATE,
    payload,
  };
}

export function setSttEditeMode(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_STT_EDITE_MODE,
    payload,
  };
}

export function setSttConfidenceHighlight(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT,
    payload,
  };
}

export function setResultPanelSize(payload: number): SettingsAction {
  return {
    type: SETTINGS_SET_RESULT_PANEL_SIZE,
    payload,
  };
}

export function setAllSettings(payload: SettingsState): SettingsAction {
  return {
    type: SETTINGS_SET_ALL,
    payload,
  };
}

export function setPlayOnlyVoiceSegments(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS,
    payload,
  };
}

export function setSortName(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_SORT_NAME,
    payload,
  };
}

export function setSortOrder(payload: string): SettingsAction {
  return {
    type: SETTINGS_SET_SORT_ORDER,
    payload,
  };
}

export function setDiarizationHighLight(payload: boolean): SettingsAction {
  return {
    type: SETTINGS_SET_DIARIZATION_HIGHLIGHT,
    payload,
  };
}

export function setShowSegments(show: boolean): SettingsAction {
  return {
    type: SET_SHOW_SEGMENTS,
    payload: show,
  };
}

export function setPlaybackSpeed(index: number): SettingsAction {
  return {
    type: SET_PLAYBACK_SPEED,
    payload: index,
  };
}

export function setAutoPlay(enable: boolean): SettingsAction {
  return {
    type: SET_AUTOPLAY,
    payload: enable,
  };
}

export function setActiveChannels(state: ChannelState): SettingsAction {
  return {
    type: SET_ACTIVECHANNELS,
    payload: state,
  };
}

export function setLoopingEnable(enable: boolean): SettingsAction {
  return {
    type: SET_LOOPING,
    payload: enable,
  };
}

export function setRatioRate(payload: string): SettingsAction {
  return {
    type: SET_RATIO_RATE,
    payload,
  };
}

export function setAppFilter(payload: string): SettingsAction {
  return {
    type: SET_APP_FILTER,
    payload,
  };
}

export function setDictionary(payload: Dictionary[]): SettingsAction {
  return {
    type: SET_DICTIONARY,
    payload,
  };
}

export function setAudioSrc(payload: string): SettingsAction {
  return {
    type: SET_AUDIO_SRC,
    payload,
  };
}

export function setThemePrimaryColor(color: string): SettingsAction {
  return {
    type: SETTINGS_SET_THEME_PRIMARY_COLOR,
    payload: color,
  };
}

export function setThemeTypography(fontFamily: string): SettingsAction {
  return {
    type: SETTINGS_SET_THEME_TYPOGRAPHY,
    payload: { fontFamily },
  };
}

export function setThemeComplete(primaryColor: string, fontFamily: string): SettingsAction {
  return {
    type: SETTINGS_SET_THEME_COMPLETE,
    payload: { primaryColor, fontFamily },
  };
}
