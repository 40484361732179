import React, { FC, useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import localization from "components/agGrid/localization";
import { ChangeDetectionStrategyType } from "ag-grid-react/lib/changeDetectionService";

// components
import FilterService from "services/admin/FilterService";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

// icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  disableTransition: {
    transition: "none",
  },
  table: {
    height: 450,
    width: 500,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  item: {
    width: 500,
  },
  btns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  iconBtn: {
    marginBottom: 5,
  },
  folder: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export interface TableModel {
  id: number;
  parent: number;
  name: string;
  isCanSelected: boolean;
}

interface Props {
  anchorEl: any;
  id?: string;
  ids: number[];
  params: any;
  onSelection?: Function;
}

export function convertToItems(ids: number[], list: any[]): any[] {
  let result: any[] = [];
  for (let i = 0; i < ids.length; i++) {
    let exist = list.find((p) => p.id === ids[i]);
    if (!exist) {
      exist = { id: ids[i], name: `не найден(${ids[i]})` };
    }
    result.push(exist);
  }

  return result;
}

export const InputTreeEditComponent: FC<Props> = ({ anchorEl, id, ids, params, onSelection }) => {
  const classes = useStyles();

  const defaultColDef = {
    resizable: true,
    sortable: true,
  };

  const columnUser = useMemo(
    () => [
      {
        headerName: "№",
        field: "id",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 100,
      },
      {
        headerName: "Имя",
        field: "name",
        flex: 1,
      },
    ],
    []
  );

  const columnAvalible = useMemo(
    () => [
      {
        headerName: "№",
        field: "id",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 100,
      },
      {
        headerName: "Имя",
        field: "name",
        flex: 1,
        cellRenderer: "NameRenderer",
      },
    ],
    []
  );

  const [avalibledGridApi, setAvalibledGridApi] = useState<GridApi | undefined>(undefined);
  const [avalibledRows, setAvalibledRows] = useState<TableModel[]>([]);
  const [avalibledSelectedRows, setAvalibledSelectedRows] = useState<TableModel[]>([]);

  const [userGridApi, setUserGridApi] = useState<GridApi | undefined>(undefined);
  const [userRows, setUserRows] = useState<TableModel[]>([]);
  const [userSelectedRows, setUserSelectedRows] = useState<TableModel[]>([]);

  const [breadcrumbs, setBreadcrumbs] = useState<TableModel[]>([
    { id: 0, name: "Главная", parent: 1, isCanSelected: false },
  ]);

  const getRowNodeId = (data: TableModel) => data.id.toString();

  const addToBreadCrumb = (model: TableModel) => {
    const _model = model;
    setBreadcrumbs((prev) => {
      let list = [...prev];
      list.push(_model);
      return list;
    });

    setAvalibledSelectedRows([]);
  };

  const goToBreadCrumb = (event: any, model: TableModel) => {
    event.preventDefault();

    setBreadcrumbs((prev) => {
      const bc: TableModel[] = [];
      for (let i = 0; i < prev.length; i++) {
        const b = prev[i];
        if (b.id === model.id) {
          bc.push(b);
          break;
        }
        bc.push(b);
      }
      return bc;
    });
  };

  const NameRenderer: FC<{ value: string; data: any }> = ({ value, data }) => {
    const classes = useStyles();

    if (data.speakerType === "folder") {
      return (
        <span className={classes.folder} onClick={() => addToBreadCrumb(data)}>
          {value}
        </span>
      );
    }

    return <span>{value}</span>;
  };

  const OnClose = () => {
    if (!userGridApi) {
      return;
    }

    const list = userRows;
    console.log(list);
    onSelection?.(list.map((p) => p.id).join("\n"), "editAndClose");
  };

  const onUserSelection = () => {
    if (userGridApi) {
      const rows = userGridApi.getSelectedRows();
      setUserSelectedRows(rows);
    }
  };

  const onAvalibledSelection = () => {
    if (avalibledGridApi) {
      const rows = avalibledGridApi.getSelectedRows();
      setAvalibledSelectedRows(rows);
    }
  };

  const removeFromUser = () => {
    const ids = userSelectedRows.map((p) => p.id);
    setUserRows((prev: any) => {
      return prev.filter((p: any) => !ids.includes(p.id));
    });

    setAvalibledRows((prev: any) => {
      return prev.map((p: any) => {
        p.isCanSelected = !ids.includes(p.id);
        return p;
      });
    });

    setUserSelectedRows([]);
  };

  const addToUser = () => {
    const ids = avalibledSelectedRows.map((p) => p.id);

    setUserRows((prev: any) => {
      return prev.concat(avalibledSelectedRows);
    });

    setAvalibledRows((prev: any) => {
      return prev.map((p: any) => {
        p.isCanSelected = !ids.includes(p.id);
        return p;
      });
    });

    setAvalibledSelectedRows([]);
    avalibledGridApi?.deselectAll();
  };

  const isRowSelectable = (e: any) => {
    return e.data && e.data.isCanSelected;
  };

  useEffect(() => {
    if (!params || !params.url) {
      console.log(`Ошибка: параметры params не содержат url`);
      return;
    }

    const urlByids = params.url + "/getByIds";
    FilterService.doCustomPostQuery(urlByids, { ids: ids })
      .then((res) => {
        const items = convertToItems(ids, res.data);
        setUserRows(items);

        const userIds = items.map((p) => p.id);
        setAvalibledRows((prev: any) => {
          return prev.map((p: any) => {
            p.isCanSelected = !userIds.includes(p.id);
            return p;
          });
        });
      })
      .catch((err) => {
        console.log(`Ошибка: ${err.response.data}`);
      });
  }, [ids, params]);

  useEffect(() => {
    if (!params || !params.url) {
      console.log(`Ошибка: параметры params не содержат url`);
      return;
    }

    const ids = userRows.map((p) => p.id);

    const last = breadcrumbs[breadcrumbs.length - 1].id;
    const url = params.url + `/${last}/0/1000`;
    FilterService.doCustomGetQuery(url)
      .then((res) => {
        // TODO: если убрать из зависимостей userRows, то не будет работать
        res.data.forEach((p) => (p.isCanSelected = !ids.includes(p.id)));
        setAvalibledRows(res.data);
      })
      .catch((err) => {
        console.log(`Ошибка: ${err.response.data}`);
      });
  }, [params, breadcrumbs, userRows]);

  // console.log(`InputTableEditComponent render anchorEl:${anchorEl}`);
  return (
    <Popover
      className={classes.disableTransition}
      open
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={OnClose}
    >
      {/* <TextField
        placeholder="Поиск"
        onChange={onQuickFilterChanged}
        variant="outlined"
        size="small"
        style={{
          width: "100%",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}

      <div className={classes.container}>
        <div className={classes.item}>
          <Typography variant="h6" component="h6" style={{ marginBottom: "10px" }}>
            Доступные
          </Typography>

          <div>
            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumbs.map((bc) => (
                <Link key={bc.id} color="inherit" href="/" onClick={(e: any) => goToBreadCrumb(e, bc)}>
                  {bc.name}
                </Link>
              ))}
            </Breadcrumbs>
          </div>

          <div className={clsx(classes.table, "ag-theme-balham")}>
            <AgGridReact
              onSelectionChanged={onAvalibledSelection}
              getRowNodeId={getRowNodeId}
              onGridReady={(params: any) => setAvalibledGridApi(params.api)}
              isRowSelectable={isRowSelectable}
              defaultColDef={defaultColDef}
              rowData={avalibledRows}
              rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
              deltaRowDataMode={true}
              columnDefs={columnAvalible}
              localeText={localization}
              rowSelection={"multiple"}
              suppressCopyRowsToClipboard
              frameworkComponents={{
                NameRenderer,
              }}
            />
          </div>
        </div>
        <div className={classes.btns}>
          <IconButton disabled={userSelectedRows.length === 0} onClick={removeFromUser} title="Удалить">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>

          <IconButton
            className={classes.iconBtn}
            disabled={avalibledSelectedRows.length === 0}
            onClick={addToUser}
            title="Добавить"
          >
            <ArrowForwardIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div className={classes.item}>
          <Typography variant="h6" component="h6" style={{ marginBottom: "10px" }}>
            Выбранные
          </Typography>
          <div>
            <Breadcrumbs aria-label="breadcrumb" style={{ visibility: "hidden" }}>
              {breadcrumbs.map((bc) => (
                <Link
                  key={bc.id}
                  color="inherit"
                  href="/"
                  // onClick={(e: any) => handleBreadCrumbClick(e, bc.root)}
                >
                  {bc.name}
                </Link>
              ))}
            </Breadcrumbs>
          </div>

          <div className={clsx(classes.table, "ag-theme-balham")}>
            <AgGridReact
              onSelectionChanged={onUserSelection}
              getRowNodeId={getRowNodeId}
              onGridReady={(params: any) => setUserGridApi(params.api)}
              defaultColDef={defaultColDef}
              rowData={userRows}
              rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
              deltaRowDataMode={true}
              columnDefs={columnUser}
              localeText={localization}
              rowSelection="multiple"
              suppressCopyRowsToClipboard
              suppressDragLeaveHidesColumns
              animateRows
            />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default InputTreeEditComponent;
