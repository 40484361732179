import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import SpeakerService from "services/SpeakerService";
import TaskService from "services/TaskService";
import { Speaker, SpeakerFile, SpeakerModel, UserFile } from "types/speaker";
import { CreateSpeakerTask, TaskDetailType } from "types/task";
import { addRow, deselectAll, removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { routes } from "routes";
import { subscriber } from "subscribers/SpeakerStatusSubscriber";
import { setPageSpeakersTableSettings } from "redux/actions/pageSettingsActions";

// components
import CreateSpeakerDialog from "./components/CreateSpeakerDialog";
import UpdateDialog from "./components/UpdateDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import SpeakersTable from "./components/SpeakersTable";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";
import SelectSpeakerFolderDialog from "components/SelectSpeakerFolderDialog";
import CreateFolderDialog from "components/CreateFolderDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import ImportDialog from "./components/ImportDialog";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  bc: {
    marginBottom: 10,
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface State {
  loading: boolean;
  speakers: Speaker[];
  error: undefined | Error;
}

const initialState: State = {
  loading: false,
  speakers: [],
  error: undefined,
};

const Speakers: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { path } = routes.speakers;

  const [pag, setPag] = useState<{ root: number; name: string }[]>([]);
  const [query, setQuery] = useState("");
  const [root, setRoot] = useState(0);
  const [state, setState] = useState<State>(initialState);
  const { speakers, error } = state;

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openImportZipDialog, setOpenImportZipDialog] = useState(false);
  const [openCreateSpeakerTask, setOpenCreateSpeakerTask] = useState(false); // задание на переобрабоку
  const [openMoveDialog, setOpenMoveDialog] = useState(false); // переместить
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Speaker[]>([]);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageSpeakersTableSettings(colState));
    }
  };

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  // клик по хлебной крошке
  const handleBreadCrumbClick = (event: any, root: number) => {
    event.preventDefault();
    if (pag.length === 0) return;
    if (root === 0) {
      setRoot(0);
      setPag([]);
      return;
    }
    const q = [];
    for (let i = 0; i < pag.length; i++) {
      if (pag[i].root === root) {
        q.push(pag[i]);
        break;
      }
      q.push(pag[i]);
    }
    const last = q.slice(-1)[0];
    setPag(q);
    setRoot(last.root);
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onSelection = () => {
    gridApi && setSelectedRows(gridApi.getSelectedRows());
  };

  // клик по именя в таблице
  const handleSpeakerNameClick = (speaker: Speaker) => {
    const { id: root, name, speakerType } = speaker;
    if (speakerType !== "folder") return;
    setSelectedRows([]);
    setPag((prev) => [...prev, { root, name }]);
    setRoot(root);
  };

  // перемещение
  const handleCloseMoveDialog = (rootId?: number) => {
    setOpenMoveDialog(false);
    if (rootId !== undefined) {
      if (rootId === root) return;

      const body = {
        rootId,
        ids: selectedRows.map((el) => el.id),
      };
      SpeakerService.moveSpeakers(body)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // задание на переобработку
  const handleCloseCreateSpeakerTask = (confirm: boolean) => {
    setOpenCreateSpeakerTask(false);
    if (confirm) {
      const task: CreateSpeakerTask = {
        id: -1,
        name: "Переобработать дикторов",
        comment: "",
        isActive: true,
        taskDetail: {
          type: TaskDetailType.CreateSpeaker,
          ids: selectedRows.map((s) => s.id),
        },
      };
      TaskService.create(task)
        .then(() => {
          dispatch(showSuccessAlert("Задание на переобработку создано"));
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // удаление диктора
  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((e) => e.id).join(",");
      SpeakerService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // создание диктора
  const handleCloseCreateDialog = (data?: { speaker: Speaker; userFiles: UserFile[] }) => {
    setOpenCreateDialog(false);
    if (data) {
      const { speaker, userFiles } = data;
      const fd = new FormData();
      fd.append("speaker", JSON.stringify({ ...speaker, parentId: root }));
      for (let i = 0; i < userFiles.length; i++) {
        const f = userFiles[i];
        fd.append(f.uuid, f.file);
      }
      SpeakerService.create(fd)
        .then(({ data }) => {
          addRow(data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // редактирование диктора
  const handleCloseUpdateDialog = (data?: { speaker: Speaker; userFiles: UserFile[] }) => {
    setOpenUpdateDialog(false);
    if (data) {
      const { speaker, userFiles } = data;
      const fd = new FormData();
      fd.append("speaker", JSON.stringify(speaker));
      for (let i = 0; i < userFiles.length; i++) {
        const f = userFiles[i];
        fd.append(f.uuid, f.file);
      }
      SpeakerService.update(fd)
        .then(({ data }) => {
          updateRow(data, gridApi);
          deselectAll(gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // создание папки
  const handleCloseCreateFolderDialog = (data?: { name: string; comment: string }) => {
    setOpenCreateFolderDialog(false);
    if (data) {
      const fd = new FormData();
      fd.append(
        "speaker",
        JSON.stringify({
          parentId: root,
          speakerType: "folder",
          name: data.name,
          comment: data.comment,
        })
      );
      SpeakerService.create(fd)
        .then(getSpeakers)
        .catch((err) => catchError(err.response.data));
    }
  };

  // импорт из файлов
  const handleCloseImportDialog = (files?: File[]) => {
    setOpenImportDialog(false);
    if (files) {
      const fd = new FormData();
      for (let i = 0; i < files.length; i++) {
        fd.append("files", files[i]);
      }
      SpeakerService.importSpeakers(root, fd)
        .then(getSpeakers)
        .catch((err) => catchError(err.response.data));
    }
  };

  // импорт из zip архива
  const handleCloseImportZipDialog = (files?: File[]) => {
    setOpenImportZipDialog(false);
    if (files) {
      const fd = new FormData();
      for (let i = 0; i < files.length; i++) {
        fd.append("files", files[i]);
      }
      SpeakerService.importSpeakersZip(root, fd)
        .then(getSpeakers)
        .catch((err) => catchError(err.response.data));
    }
  };

  const sortSpeakers = useCallback(() => {
    const sorting = (a: Speaker, b: Speaker) => a.id - b.id;
    const folder = speakers.filter((el) => el.speakerType === "folder").sort(sorting);
    const speaker = speakers.filter((el) => el.speakerType === "speaker").sort(sorting);
    const autoinformator = speakers.filter((el) => el.speakerType === "autoinformator").sort(sorting);
    return [...folder, ...speaker, ...autoinformator];
  }, [speakers]);

  const getSpeakers = useCallback(() => {
    setState({ error: undefined, speakers: [], loading: true });
    const queryString = "?q=" + query;
    SpeakerService.getAll(root, queryString)
      .then(({ data }) => {
        setState({ speakers: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ speakers: [], loading: false, error: err });
      });
  }, [root, query]);

  useEffect(() => {
    error && catchError(error);
  }, [catchError, error]);

  useEffect(getSpeakers, [getSpeakers]);

  useEffect(() => {
    subscriber.subscribe((wsData) => {
      const { id, status, message, models, files } = wsData.data;
      if (gridApi) {
        gridApi.forEachNode((node) => {
          if (node.id === String(id)) {
            const speakerModels = node.data.models;
            const speakerFiles = node.data.files;

            speakerModels.forEach((speakerModel: SpeakerModel) => {
              models.forEach((m) => {
                if (speakerModel.id === m.id) {
                  speakerModel.status = m.status;
                  speakerModels.message = m.message;
                }
              });
            });

            speakerFiles.forEach((speakerFile: SpeakerFile) => {
              files.forEach((f) => {
                if (speakerFile.id === f.id) {
                  speakerFile.status = f.status;
                  speakerFile.message = f.message;
                }
              });
            });

            node.setData({ ...node.data, models: speakerModels, files: speakerFiles, status, message });
          }
        });
      }
    });
  }, [gridApi]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <Paper className={classes.root}>
      <div className={classes.bc}>
        {pag.length === 0 && (
          <Breadcrumbs aria-label="breadcrumb">
            <Typography>Главная</Typography>
          </Breadcrumbs>
        )}

        {pag.length !== 0 && (
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href={path} onClick={(e: any) => handleBreadCrumbClick(e, 0)}>
              Главная
            </Link>
            {pag.map((el) => (
              <Link key={el.root} color="inherit" href={path} onClick={(e: any) => handleBreadCrumbClick(e, el.root)}>
                {el.name}
              </Link>
            ))}
          </Breadcrumbs>
        )}
      </div>

      <div className={classes.actions}>
        <div className={classes.buttons}>
          <IconButton
            className={classes.mr10}
            onClick={() => setOpenSettingsTableDialog(true)}
            title="Настройка колонок таблицы"
            size="small"
          >
            <GridOnIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.searchWrapper}>
            <TextField
              className={classes.search}
              placeholder="Поиск..."
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Удалить
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenMoveDialog(true)}
              disabled={selectedRows.length === 0}
            >
              Переместить
            </Button>

            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenImportZipDialog(true)}
            >
              Импортировать ZIP
            </Button>

            <Button color="primary" size="small" className={classes.button} onClick={() => setOpenImportDialog(true)}>
              Импортировать
            </Button>

            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateFolderDialog(true)}
            >
              Создать папку
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className={classes.button}
              onClick={() => setOpenCreateDialog(true)}
            >
              Создать диктора
            </Button>
          </div>
        </div>
      </div>

      <SpeakersTable
        rowData={sortSpeakers()}
        setGridApi={setGridApi}
        setColumnApi={setColumnApi}
        onSelection={onSelection}
        handleSpeakerNameClick={handleSpeakerNameClick}
      />

      <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
      <ConfirmationDialog
        open={openCreateSpeakerTask}
        onClose={handleCloseCreateSpeakerTask}
        message="Переобработать выделенных дикторов?"
      />

      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      <CreateSpeakerDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      <CreateFolderDialog open={openCreateFolderDialog} onClose={handleCloseCreateFolderDialog} />
      <ImportDialog open={openImportDialog} onClose={handleCloseImportDialog} accept="audio/*" />
      <ImportDialog open={openImportZipDialog} onClose={handleCloseImportZipDialog} accept=".zip" />

      {selectedRows.length === 1 && (
        <UpdateDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} speaker={selectedRows[0]} />
      )}
      {selectedRows.length !== 0 && (
        <SelectSpeakerFolderDialog
          open={openMoveDialog}
          onClose={handleCloseMoveDialog}
          ids={selectedRows.map((el) => el.id)}
        />
      )}
    </Paper>
  );
};

export default Speakers;
