import { darken } from "@material-ui/core/styles";
import { teal, red, pink, purple, indigo, deepPurple, brown, cyan, blue } from "@material-ui/core/colors";

// Объект с парами цветов для каждого основного цвета
export const colorPairsMap = new Map<string, string>([
  [teal[300], red[400]],
  [red[300], red[600]],
  [pink[200], pink[400]],
  [purple[200], purple[600]],
  [deepPurple[300], deepPurple[600]],
  [indigo[300], indigo[500]],
  [blue[300], blue[600]],
  [cyan[300], cyan[800]],
  // [green[300], purple[300]],
  [brown[300], brown[500]],
  // [grey[500], indigo[300]],
  // [blueGrey[300], deepPurple[300]]
]);

// Вспомогательная функция для получения вторичного цвета
export const getSecondaryColor = (primaryColor: string): string => {
  // Сначала проверяем, есть ли предустановленная пара
  const mappedColor = colorPairsMap.get(primaryColor);
  if (mappedColor) {
    return mappedColor;
  }

  // Если нет, генерируем дополнительный цвет
  return generateComplementaryColor(primaryColor);
};

declare module "@material-ui/core/styles/createPalette" {
  interface PaletteColor {
    gradient?: string;
  }

  interface Palette {
    audioWaveform?: {
      leftChannelColor: string;
      rightChannelColor: string;
      leftSegmentColor: string;
      rightSegmentColor: string;
      pointerColor: string;
    };
  }

  interface PaletteOptions {
    audioWaveform?: {
      leftChannelColor: string;
      rightChannelColor: string;
      leftSegmentColor: string;
      rightSegmentColor: string;
      pointerColor: string;
    };
  }
}

export const createGradient = (color: string) => {
  const darkerColor = darken(color, 0.2);
  if (color === teal[300]) {
    return `linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)`;
  }
  if (color === pink[200]) {
    return `linear-gradient(90deg, rgb(238, 114, 155) 0%, ${color} 50%)`;
  }
  return `linear-gradient(90deg, ${darkerColor} 0%, ${color} 50%)`;
};

// Функция для создания расширенной палитры с градиентами
export const createExtendedPalette = (colorMain: string) => {
  return {
    main: colorMain,
    contrastText: "#FFF",
    gradient: createGradient(colorMain),
  };
};

// Функция для создания цветов аудио-волны на основе основного цвета темы
export const createAudioWaveformColors = (primaryColor: string) => {
  // Для teal используем стандартные цвета
  if (primaryColor === teal[300]) {
    return {
      leftChannelColor: "#008F75",
      rightChannelColor: "#848AE6",
      leftSegmentColor: "#008F75",
      rightSegmentColor: "#848AE6",
      pointerColor: "#C63228",
    };
  }
  // Для indigo также используем стандартные цвета
  else if (primaryColor === indigo[300]) {
    return {
      leftChannelColor: "#848AE6",
      rightChannelColor: "#008F75",
      leftSegmentColor: "#848AE6",
      rightSegmentColor: "#008F75",
      pointerColor: "#C63228",
    };
  }
  // Для других цветов генерируем подходящие
  else {
    // Создаем контрастный цвет для второго канала
    const complementaryColor = generateComplementaryColor(primaryColor);

    return {
      leftChannelColor: primaryColor,
      rightChannelColor: complementaryColor,
      leftSegmentColor: primaryColor,
      rightSegmentColor: complementaryColor,
      pointerColor: "#C63228", // Оставляем красный указатель для всех тем
    };
  }
};

// Вспомогательная функция для создания дополнительного цвета и цвета второго канала в плеере
export function generateComplementaryColor(hexColor: string): string {
  // Преобразуем hex в RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Создаем дополнительный цвет (простой вариант)
  const compR = 255 - r;
  const compG = 255 - g;
  const compB = 255 - b;

  // Преобразуем обратно в hex
  return `#${compR.toString(16).padStart(2, "0")}${compG.toString(16).padStart(2, "0")}${compB
    .toString(16)
    .padStart(2, "0")}`;
}
// Альтернатива
//   export function generateShiftedColor(hexColor: string, degrees: number = 180): string {
//     // Преобразуем hex в HSL, сдвигаем оттенок и преобразуем обратно
//     const r = parseInt(hexColor.slice(1, 3), 16) / 255;
//     const g = parseInt(hexColor.slice(3, 5), 16) / 255;
//     const b = parseInt(hexColor.slice(5, 7), 16) / 255;

//     const max = Math.max(r, g, b);
//     const min = Math.min(r, g, b);
//     let h, s, l = (max + min) / 2;

//     if (max === min) {
//       h = s = 0; // оттенок не определен для оттенков серого
//     } else {
//       const d = max - min;
//       s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

//       switch (max) {
//         case r: h = (g - b) / d + (g < b ? 6 : 0); break;
//         case g: h = (b - r) / d + 2; break;
//         case b: h = (r - g) / d + 4; break;
//         default: h = 0;
//       }
//       h /= 6;
//     }

//     // Сдвигаем оттенок
//     h = (h * 360 + degrees) % 360 / 360;

//     // Преобразуем обратно в RGB
//     let r1, g1, b1;

//     if (s === 0) {
//       r1 = g1 = b1 = l;
//     } else {
//       const hue2rgb = (p: number, q: number, t: number) => {
//         if (t < 0) t += 1;
//         if (t > 1) t -= 1;
//         if (t < 1/6) return p + (q - p) * 6 * t;
//         if (t < 1/2) return q;
//         if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
//         return p;
//       };

//       const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
//       const p = 2 * l - q;

//       r1 = hue2rgb(p, q, h + 1/3);
//       g1 = hue2rgb(p, q, h);
//       b1 = hue2rgb(p, q, h - 1/3);
//     }

//     // Преобразуем в hex
//     const toHex = (x: number) => {
//       const hex = Math.round(x * 255).toString(16);
//       return hex.length === 1 ? '0' + hex : hex;
//     };

//     return `#${toHex(r1)}${toHex(g1)}${toHex(b1)}`;
//   }
