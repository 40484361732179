import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1.15",
  },
  src: {
    borderBottom: "1px solid #BBC2CA",
  },
  dst: {},
  separator: {
    margin: "4 0px",
    color: "#666",
  },
}));

const PortRenderer: FC<any> = (props) => {
  const classes = useStyles();
  const { srcPort, dstPort } = props.data;

  if (srcPort === 0 && dstPort === 0) return <></>;

  return (
    <div className={classes.container}>
      <div className={classes.src}>{srcPort === 0 ? "-" : srcPort}</div>
      <div className={classes.dst}>{dstPort === 0 ? "-" : dstPort}</div>
    </div>
  );
};

export default PortRenderer;
