import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState, WheelEvent } from "react";
import clsx from "clsx";
import { normalizeTime } from "functions/common";
import { useDispatch, useSelector } from "react-redux";
import { RootState, SET_ACTIVECHANNELS } from "redux/types";
import { useResizeDetector } from "react-resize-detector";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import { setPlaybackSpeed } from "redux/actions/settingsActions";
import { useAudioHotkeys } from "../../../hooks/audioPlayer/useAudioHotkeys";
import { AudioPlayerSegment, PlayerProps } from "types/player";
import { useSegmentFiltering } from "hooks/audioPlayer/useSegmentsFilter";

// components
import Scroll from "./Scroll";
import PlayerSettings from "./PlayerSettings";
import ChannelButtons from "./ChannelButtons";
import KeywordsOverlay from "./KeywordsOverlay";
import LoadingAudio from "./LoadingAudio";

// icons
import StopIcon from "@material-ui/icons/Stop";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Pause from "@material-ui/icons/Pause";
import PlayArrow from "@material-ui/icons/PlayArrow";
// import GetAppIcon from "@material-ui/icons/GetApp";

// material ui
import { darken, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { green, grey } from "@material-ui/core/colors";
import VolumeControl from "./VolumeControl";

const useStyles = makeStyles((theme: Theme) => ({
  player: {
    height: 115,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
  },
  btns: {
    margin: "9px 12px 0 16px",
  },
  channelsContainer: {
    display: "flex",
    alignItems: "center",
  },
  channelButton: {
    width: 18,
    height: 36,
    cursor: "pointer",
    "&.disabled": {
      cursor: "not-allowed",
      pointerEvents: "none",
    },
  },
  inactiveChannel: {
    backgroundColor: grey[400],
  },
  btn: {
    cursor: "pointer",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.15), // Затемняем основной цвет на 15%
    },
  },
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  icon: {
    color: "white",
  },
  mr5: {
    marginRight: 5,
  },
  waveform: {
    flexGrow: 1,
    position: "relative",
  },
  canvas: {
    width: "100%",
    height: 100,
    transition: "top ease-out 3s",
  },
  mTop15: {
    marginTop: "-15px",
  },
  time: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: 125,
    marginTop: 8,
    padding: "0 20px",
    color: grey[700],
    fontSize: theme.typography.fontSize + 5,
  },
  row: {
    display: "flex",
    marginBottom: 5,
  },
  kv: {
    height: 25,
    display: "flex",
    margin: "0 -16px 0 -16px",
    backgroundColor: "#f2f2f2",
  },
  fs: {
    width: 275,
  },
  kvl: {
    width: 2025,
    position: "relative",
  },
  w1: {
    border: "1px solid " + grey[600],
    position: "absolute",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
    height: 25,
    zIndex: theme.zIndex.modal,
    cursor: "pointer",
    fontSize: theme.typography.fontSize + 4,
    color: grey[800],
    padding: "0 5px",
    "&:hover": {
      zIndex: theme.zIndex.modal + 10,
    },
  },
  p1: {
    position: "absolute",
    left: 142,
    width: 1,
    height: 125,
    backgroundColor: grey[600],
    zIndex: theme.zIndex.modal + 1,
  },
  vad: {
    position: "absolute",
    zIndex: 1,
    height: 100,
    backgroundColor: green[300],
    opacity: 0.5,
  },

  speedIcon: {
    fontWeight: 600,
    fontSize: "17px",
    marginTop: "5px",
  },
  activeSpeedBtn: {
    backgroundColor: darken(theme.palette.primary.main, 0.15), // Затемняем основной цвет на 15%
  },
  progressCanvas: {
    zIndex: 2,
  },
  top34: {
    top: 34,
  },
  mr8: {
    marginRight: "8px",
  },
  ml8: {
    marginLeft: "8px",
  },
  loading: {
    position: "absolute",
    top: 8,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(242, 242, 242, 0.8)",
    zIndex: 3,
    transition: "opacity 0.3s ease",
  },
  loadingHidden: {
    opacity: 0,
    pointerEvents: "none",
  },
}));

const Player: FC<PlayerProps> = ({
  audioUrl,
  keywords,
  fileName,
  channelCount,
  segments,
  showSegments,
  selectNextOrPrevRow,
  handleAudioError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  // refs
  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [samples, setSamples] = useState<number[][]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [scrollBarWidth, setScrollBarWidth] = useState<number>(10);
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // каналы
  // const [activeChannels, setActiveChannels] = useState<ChannelState>({ left: true, right: true });
  const activeChannels = useSelector((state: RootState) => state.settings.activeChannels);
  const isMono = channelCount === 1;

  // скорость
  const [playbackSpeeds] = useState([0.8, 1, 1.2, 1.5, 1.8, 2]);
  const currentSpeed = useSelector((state: RootState) => state.settings.playbackSpeed);
  const autoPlayEnabled = useSelector((state: RootState) => state.settings.autoplay);
  const loopingEnabled = useSelector((state: RootState) => state.settings.looping);

  const totalSamples = useMemo(() => (samples.length === 0 ? 0 : samples[0].length), [samples]);

  const { ref } = useResizeDetector();

  // Аудио контекст
  const audioContext = useRef<AudioContext | undefined>(undefined);
  const audioSource = useRef<MediaElementAudioSourceNode | undefined>(undefined);

  // сегменты
  const { filteredSegments } = useSegmentFiltering(segments, activeChannels, channelCount);
  // воспроизведение без пауз
  const playerPlayOnlyVoiceSegments = useSelector((state: RootState) => state.settings.playerPlayOnlyVoiceSegments);

  //громкость
  const [leftVolume, setLeftVolume] = useState(1);
  const [rightVolume, setRightVolume] = useState(1);

  const handleLeftVolumeChange = (volume: number) => {
    setLeftVolume(volume);
  };

  const handleRightVolumeChange = (volume: number) => {
    setRightVolume(volume);
  };
  const handleSelectPrev = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);
    selectNextOrPrevRow("prev");
  }, [isLoading, selectNextOrPrevRow]);

  const handleSelectNext = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);
    selectNextOrPrevRow("next");
  }, [isLoading, selectNextOrPrevRow]);

  // обработчик клика кнопки Play
  const play = useCallback(() => {
    if (!audioRef) return;
    if (!audioRef.current) return;
    audioRef.current.play().then(() => setIsPlaying(true));
  }, [audioRef]);

  // обработчик клика кнопки Pause
  const pause = useCallback(() => {
    if (!audioRef) return;
    if (!audioRef.current) return;
    audioRef.current.pause();
    setIsPlaying(false);
  }, [audioRef]);

  // play pause
  const playPause = useCallback(() => (isPlaying ? pause() : play()), [isPlaying, play, pause]);

  // обработчик клика кнопки Stop
  const handleStop = () => {
    if (!audioRef) return;
    if (!audioRef.current) return;
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const goToPreviousSegment = useCallback(() => {
    if (!segments.length || currentTime === 0) return;
    if (filteredSegments.length === 0) return;

    // Сортируем отфильтрованные сегменты по времени начала
    const sortedSegments = [...filteredSegments].sort((a, b) => a.start - b.start);

    // Ищем текущий или предыдущий сегмент
    let previousSegment: AudioPlayerSegment | undefined;

    for (let i = sortedSegments.length - 1; i >= 0; i--) {
      if (sortedSegments[i].start < currentTime - 0.1) {
        previousSegment = sortedSegments[i];
        break;
      }
    }

    if (previousSegment) {
      currTimeSubscriber.next(previousSegment.start);
    } else {
      currTimeSubscriber.next(sortedSegments[sortedSegments.length - 1].start);
    }
  }, [currentTime, segments, filteredSegments]);

  const goToNextSegment = useCallback(() => {
    if (!segments.length || currentTime >= duration) return;
    if (filteredSegments.length === 0) return;

    // Сортируем отфильтрованные сегменты по времени начала
    const sortedSegments = [...filteredSegments].sort((a, b) => a.start - b.start);

    // Ищем следующий сегмент
    let nextSegment: AudioPlayerSegment | undefined;

    for (let segment of sortedSegments) {
      if (segment.start > currentTime + 0.1) {
        nextSegment = segment;
        break;
      }
    }

    if (nextSegment) {
      currTimeSubscriber.next(nextSegment.start);
      if (playerPlayOnlyVoiceSegments && !isPlaying) {
        play(); // Автоматически запускаем воспроизведение если режим "без пауз" активен
      }
    } else if (playerPlayOnlyVoiceSegments && isPlaying) {
      // Если это был последний сегмент и режим "без пауз" активен
      // Переходим к первому сегменту или останавливаем воспроизведение
      if (loopingEnabled && sortedSegments.length > 0) {
        currTimeSubscriber.next(sortedSegments[0].start);
      } else {
        handleStop();
      }
    } else if (sortedSegments.length > 0) {
      currTimeSubscriber.next(sortedSegments[0].start);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, duration, segments, filteredSegments, playerPlayOnlyVoiceSegments, isPlaying, loopingEnabled]);

  // Функция для проверки, находится ли текущее время в каком-либо сегменте
  const isInAnySegment = useCallback(
    (time: number) => {
      if (!filteredSegments.length) return false;

      return filteredSegments.some((segment) => time >= segment.start && time <= segment.end);
    },
    [filteredSegments]
  );

  // Функция для определения конца текущего сегмента
  const getCurrentSegmentEnd = useCallback(() => {
    if (!filteredSegments.length) return null;

    const currentSegment = filteredSegments.find(
      (segment) => currentTime >= segment.start && currentTime <= segment.end
    );

    return currentSegment ? currentSegment.end : null;
  }, [currentTime, filteredSegments]);

  // обработчик нажатия кнопки переключение каналов
  const toggleChannel = (channel: "left" | "right") => {
    const newState = { ...activeChannels, [channel]: !activeChannels[channel] };

    // Если оба канала неактивны, активируем оба
    if (!newState.left && !newState.right) {
      dispatch({ type: SET_ACTIVECHANNELS, payload: { left: true, right: true } });
    } else {
      dispatch({ type: SET_ACTIVECHANNELS, payload: newState });
    }
  };

  // увеличить
  const zoomIn = useCallback(() => {
    const newZoom = Math.min(zoomLevel * 2, 64);
    const canvas = canvasRef.current;
    if (!canvas || !audioRef.current) return;

    const newScrollBarWidth = Math.max(10, canvas.width * (1 / newZoom));
    setScrollBarWidth(newScrollBarWidth);

    // Позиция бегунка в сэмплах
    const currentTimeSample = (currentTime / duration) * totalSamples;

    // const visibleSamplesBeforeZoom = totalSamples / zoomLevel;
    const visibleSamplesAfterZoom = totalSamples / newZoom;

    // Новое смещение должно быть таким, чтобы бегунок оставался на том же месте
    const newScrollOffset = currentTimeSample - visibleSamplesAfterZoom / 2;

    // Ограничиваем новое смещение, чтобы оно не выходило за пределы допустимого диапазона
    const adjustedScrollOffset = Math.max(0, Math.min(newScrollOffset, totalSamples - visibleSamplesAfterZoom));

    // Устанавливаем новый zoom и scrollOffset
    setZoomLevel(newZoom);
    setScrollOffset(adjustedScrollOffset);
  }, [currentTime, duration, zoomLevel, totalSamples]);

  // уменьшить
  const zoomOut = useCallback(() => {
    const newZoom = Math.max(zoomLevel / 2, 1);
    const canvas = canvasRef.current;
    if (!canvas || !audioRef.current) return;

    const newScrollBarWidth = Math.max(10, canvas.width * (1 / newZoom));
    setScrollBarWidth(newScrollBarWidth);

    // Позиция бегунка в сэмплах
    const currentTimeSample = (currentTime / duration) * totalSamples;

    // const visibleSamplesBeforeZoom = totalSamples / zoomLevel;
    const visibleSamplesAfterZoom = totalSamples / newZoom;

    // Новое смещение должно быть таким, чтобы бегунок оставался на том же месте
    const newScrollOffset = currentTimeSample - visibleSamplesAfterZoom / 2;

    // Ограничиваем новое смещение, чтобы оно не выходило за пределы допустимого диапазона
    const adjustedScrollOffset = Math.max(0, Math.min(newScrollOffset, totalSamples - visibleSamplesAfterZoom));

    // Устанавливаем новый zoom и scrollOffset
    setZoomLevel(newZoom);
    setScrollOffset(adjustedScrollOffset);
  }, [currentTime, duration, zoomLevel, totalSamples]);

  // функция для изменения скорости
  const handleSpeedChange = useCallback(() => {
    const currentIndex = playbackSpeeds.indexOf(currentSpeed);
    const newIndex = (currentIndex + 1) % playbackSpeeds.length;
    const newSpeed = playbackSpeeds[newIndex];
    dispatch(setPlaybackSpeed(newSpeed));

    if (audioRef.current) {
      audioRef.current.playbackRate = newSpeed;
    }
  }, [currentSpeed, dispatch, playbackSpeeds]);

  // скачать файл
  // const download = useCallback(() => {
  //   fetch(audioUrl).then((res) => {
  //     res.blob().then((blob) => {
  //       let name = fileName;
  //       if (!name.endsWith(".wav")) {
  //         name += ".wav";
  //       }

  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = name;
  //       a.click();
  //       a.remove();
  //     });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [audioUrl]);

  // видимая зона
  const maxScrollOffset = useMemo(() => {
    const visibleSamples = totalSamples / zoomLevel;
    return Math.max(0, totalSamples - visibleSamples);
  }, [zoomLevel, totalSamples]);

  const handleCanvasClick = useCallback(
    (e: MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current;
      if (!canvas || !audioRef.current) return;

      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const width = canvas.width / (window.devicePixelRatio || 1);

      // Вычисляем позицию клика относительно видимой части
      const clickPosition = x / width;

      // Получаем количество сэмплов, видимых в текущем окне
      const visibleSamples = totalSamples / zoomLevel;

      // Вычисляем индекс сэмпла с учетом scrollOffset и зума
      const clickedSample = Math.floor(scrollOffset + clickPosition * visibleSamples);

      // Переводим индекс сэмпла во время
      const clickTime = (clickedSample / totalSamples) * duration;

      currTimeSubscriber.next(clickTime);
    },
    [zoomLevel, scrollOffset, duration, totalSamples]
  );

  const handleWheel = (e: WheelEvent<HTMLCanvasElement>) => (e.deltaY < 0 ? zoomIn() : zoomOut());

  // отрисовать осциллограмму
  const drawWaveform = (
    zoomLevel: number,
    activeChannels: any,
    channelCount: number,
    scrollOffset: number,
    segments: AudioPlayerSegment[],
    showSegments: boolean,
    totalSamples: number
  ) => {
    if (!audioRef) return;
    if (!audioRef.current) return;
    if (!canvasRef) return;
    if (!canvasRef.current) return;
    if (totalSamples === 0) return;

    const { currentTime, duration } = audioRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d")!;
    const dpr = window.devicePixelRatio || 1;
    const padding = 0;

    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = 100 * dpr;

    ctx.scale(dpr, dpr);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const timelineHeight = 20;
    const width = canvas.width / dpr;
    const height = canvas.height / dpr;
    const barWidth = (width / totalSamples) * zoomLevel;

    const visibleBars = Math.floor(width / barWidth);
    const startIndex = Math.floor(scrollOffset);

    ctx.beginPath();
    ctx.strokeStyle = grey[400];
    ctx.lineWidth = 1;
    ctx.moveTo(0, height / 2 - 7.5);
    ctx.lineTo(width, height / 2 - 7.5);
    ctx.stroke();

    // функция для выбора подходящего временного шага
    const getTimeStep = (visibleDuration: number): number => {
      const steps = [1, 2, 5, 10, 15, 30, 60, 120, 300, 600]; // в секундах
      for (let step of steps) {
        if (visibleDuration / step <= 10) {
          return step;
        }
      }
      return steps[steps.length - 1];
    };

    // таймлайн
    const drawTimeline = (yPosition: number) => {
      const visibleDuration = (visibleBars / totalSamples) * duration;
      const startTime = (startIndex / totalSamples) * duration;
      const endTime = startTime + visibleDuration;
      const timeStep = getTimeStep(visibleDuration);

      ctx.fillStyle = grey[600];
      ctx.font = "10px Arial";
      ctx.textAlign = "center";

      for (let time = Math.ceil(startTime / timeStep) * timeStep; time <= endTime; time += timeStep) {
        const x = ((time - startTime) / visibleDuration) * width;
        ctx.beginPath();
        // Добавил x + 2 везде, чтобы не обрезало ноль. Возможно стоит сделать так только для нулевой и последней секунды потом.
        ctx.moveTo(x + 2, yPosition - 10);
        ctx.lineTo(x + 2, yPosition - 5);
        ctx.strokeStyle = grey[600];
        ctx.stroke();
        ctx.fillText(
          time >= 60 ? `${Math.floor(time / 60)}:${(time % 60).toFixed(0).padStart(2, "0")}` : time.toFixed(0),
          x + 2,
          yPosition + 3
        );
      }
    };

    // сегменты ВАДа
    const drawVADSegments = () => {
      segments.forEach((segment) => {
        const { channelNumber, start, end } = segment;

        if (!((channelNumber === 0 && activeChannels.left) || (channelNumber === 1 && activeChannels.right))) {
          return;
        }

        const startPosition =
          ((start - (startIndex / totalSamples) * duration) / ((visibleBars / totalSamples) * duration)) * width;
        const endPosition =
          ((end - (startIndex / totalSamples) * duration) / ((visibleBars / totalSamples) * duration)) * width;

        if (startPosition < width && endPosition > 0) {
          // Добавил проверку
          const actualStartPosition = Math.max(0, startPosition); // Убедимся что не выходит за границы
          const actualEndPosition = Math.min(width, endPosition);
          const segmentWidth = actualEndPosition - actualStartPosition;

          const yOffset = -15; //высота для сегментов чтобы место было сверху и снизу

          const channelColor =
            channelNumber === 0
              ? theme.palette.audioWaveform?.leftSegmentColor
              : theme.palette.audioWaveform?.rightSegmentColor;

          ctx.fillStyle = hexToRgba(channelColor || "#000000", 0.3); // для прозрачности
          ctx.fillRect(actualStartPosition, yOffset, segmentWidth, height);
        }
      });
    };

    // вспомогательная функция для преобразования HEX в RGBA с прозрачностью
    const hexToRgba = (hex: string, alpha: number): string => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const leftChannelColor = theme.palette.audioWaveform?.leftChannelColor || "#008F75";
    const rightChannelColor = theme.palette.audioWaveform?.rightChannelColor || "#848AE6";
    const pointerColor = theme.palette.audioWaveform?.pointerColor || "#C63228";

    // если количество каналов === 2
    // Проверка наличия данных
    if (samples.length === 0 || !samples[0]) return;

    if (channelCount === 2 && samples.length === 2) {
      // Логика для стерео (только если есть данные для обоих каналов)
      for (let channel = 0; channel < 2; channel++) {
        if ((channel === 0 && !activeChannels.left) || (channel === 1 && !activeChannels.right)) {
          continue;
        }

        // Проверяем наличие данных для канала
        if (!samples[channel]) continue;

        const channelHeight = 20;

        ctx.fillStyle = channel === 0 ? leftChannelColor : rightChannelColor;
        ctx.strokeStyle = channel === 0 ? leftChannelColor : rightChannelColor;

        for (let i = 0; i < visibleBars; i++) {
          const dataIndex = startIndex + i;
          if (dataIndex >= samples[channel].length) break;

          const x = i * barWidth;
          const barHeight = samples[channel][dataIndex] * (height - timelineHeight);

          ctx.beginPath();
          // Добавил отступ в 22.5, чтобы дорожка была по центру и не залезала на таймлайн
          ctx.lineTo(x + barWidth / 2, channelHeight - barHeight / 2 + 22.5);
          ctx.lineTo(x + barWidth / 2, channelHeight + barHeight / 2 + 22.5);
          ctx.stroke();
        }
      }

      // Отрисовка бегунка для стерео
      const samplesPerPixel = totalSamples / (width * zoomLevel);
      const visibleSamples = width * samplesPerPixel;
      const startSample = scrollOffset;
      const currentTimeSample = (currentTime / duration) * totalSamples;
      const progressWidth = ((currentTimeSample - startSample) / visibleSamples) * width;

      if (progressWidth >= 0 && progressWidth <= width) {
        ctx.beginPath();
        ctx.strokeStyle = pointerColor;
        ctx.lineWidth = 1;
        ctx.moveTo(progressWidth, 0);
        ctx.lineTo(progressWidth, height - 15);
        ctx.stroke();
      }

      drawTimeline(95);
    } else if (channelCount === 1 && samples[0]) {
      ctx.fillStyle = leftChannelColor;
      ctx.strokeStyle = leftChannelColor;

      for (let i = 0; i < visibleBars; i++) {
        const dataIndex = startIndex + i;
        if (dataIndex >= totalSamples) break;

        const x = i * barWidth;

        const barHeight = samples[0][dataIndex] * (height - timelineHeight);

        ctx.beginPath();
        // Добавил отступ в 7.5, чтобы дорожка была по центру и не залезала на таймлайн
        ctx.lineTo(x + barWidth / 2, height / 2 + padding - barHeight / 2 - 7.5);
        ctx.lineTo(x + barWidth / 2, height / 2 + padding + barHeight / 2 - 7.5);
        ctx.stroke();
      }

      // расчет позиции бегунка с учетом зума и scrollOffset
      const samplesPerPixel = totalSamples / (width * zoomLevel);
      const visibleSamples = width * samplesPerPixel;
      const startSample = scrollOffset;

      // переводим текущее время в индекс сэмпла
      const currentTimeSample = (currentTime / duration) * totalSamples;

      // вычисляем позицию бегунка относительно видимой области
      const progressWidth = ((currentTimeSample - startSample) / visibleSamples) * width;

      // вертикальный бегунок
      ctx.beginPath();
      ctx.strokeStyle = pointerColor;
      ctx.lineWidth = 1;
      ctx.moveTo(progressWidth, 0);
      ctx.lineTo(progressWidth, height - 15);
      ctx.stroke();

      drawTimeline(95);
    }

    // отобразить сегменты ВАДа
    if (showSegments) drawVADSegments();
  };

  // в колбэк не оборачивать!
  // получить аудио и перевести в сэмплы
  const generateWaveformData = async (audioElement: HTMLAudioElement) => {
    setIsLoading(true);
    setSamples([]);
    if (!audioElement) {
      setIsLoading(false);
      return { success: false, error: "No audio element" };
    }
    try {
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const response = await fetch(audioUrl);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const samples = 10000;
      const arr = [];

      for (let channel = 0; channel < channelCount; channel++) {
        const rawData = audioBuffer.getChannelData(channel);
        const blockSize = Math.floor(rawData.length / samples);
        const filteredData = [];
        for (let i = 0; i < samples; i++) {
          let blockStart = blockSize * i;
          let sum = 0;
          for (let j = 0; j < blockSize; j++) {
            sum += Math.abs(rawData[blockStart + j]);
          }
          filteredData.push(sum / blockSize);
        }

        const multiplier = Math.pow(Math.max(...filteredData), -1);
        arr.push(filteredData.map((n) => n * multiplier));
      }

      if (channelCount === 0) {
        console.log("Невозможно отрисовать вейвформу, количество каналов = 0");
        return { success: false, error: "No channels" };
      }

      setSamples(arr);
      return { success: true };
    } catch (error) {
      console.error("Ошибка генерации вейвформы:", error);
      return { success: false, error: "Waveform gen error" };
    } finally {
      setIsLoading(false);
    }
  };

  const { handleKeyDown } = useAudioHotkeys({
    playPause,
    prevSegment: goToPreviousSegment,
    nextSegment: goToNextSegment,
  });

  // подписка, отписка на события Аудио-элемента
  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) return;

    audioElement.loop = loopingEnabled;
    audioElement.playbackRate = currentSpeed;

    const handleTimeUpdate = () => {
      const { currentTime } = audioElement;
      setCurrentTime(currentTime);
      currTimeSubscriber.next(currentTime);

      // Логика для воспроизведения только сегментов
      if (playerPlayOnlyVoiceSegments && isPlaying) {
        // Если мы не в сегменте и воспроизведение активно
        if (!isInAnySegment(currentTime)) {
          goToNextSegment(); // Переходим к следующему сегменту
        } else {
          // Если мы в сегменте, проверяем, не закончился ли он
          const segmentEnd = getCurrentSegmentEnd();
          if (segmentEnd !== null && Math.abs(currentTime - segmentEnd) < 0.1) {
            // Если текущее время близко к концу сегмента, переходим к следующему. Тут значение произвольное поставил, можно поиграться.
            setTimeout(() => goToNextSegment(), 100);
          }
        }
      }
    };

    const handleLoadedMetadata = async () => {
      setDuration(audioElement.duration);
      setIsAudioLoaded(true);
      const result = await generateWaveformData(audioElement);
      if (result.success && autoPlayEnabled && audioElement.paused) {
        audioElement.play().catch((error) => {
          console.error("Ошибка воспроизведения:", error);
        });
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    };

    const handleEnded = () => {
      if (!loopingEnabled) {
        setIsPlaying(false);
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
        }
        setCurrentTime(0);
        currTimeSubscriber.next(0);
      }
    };

    audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    audioElement.addEventListener("timeupdate", handleTimeUpdate);
    audioElement.addEventListener("ended", handleEnded);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      audioElement.removeEventListener("ended", handleEnded);
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl, handleKeyDown, autoPlayEnabled, loopingEnabled, playerPlayOnlyVoiceSegments]);

  // подписка на событие смены текущего времени воспроизведения
  useEffect(() => {
    const subscription = currTimeSubscriber.subscribe((time) => {
      if (!audioRef) return;
      if (!audioRef.current) return;
      if (audioRef.current.readyState !== 4) return;
      if (audioRef.current.currentTime === time) return;
      audioRef.current.currentTime = time;
    });
    return () => subscription.unsubscribe();
  }, []);

  // слежка за положением курсора для проматывания
  useEffect(() => {
    if (isScrolling) return;
    if (!isPlaying) return;
    if (zoomLevel <= 1) return;
    if (!canvasRef.current) return;
    if (samples.length === 0) return;

    const canvas = canvasRef.current;
    const dpr = window.devicePixelRatio || 1;
    const width = canvas.width / dpr;

    // расчет позиции бегунка с учетом зума и scrollOffset
    const samplesPerPixel = totalSamples / (width * zoomLevel);
    const visibleSamples = width * samplesPerPixel;

    // переводим текущее время в индекс сэмпла
    const currentTimeSample = (currentTime / duration) * totalSamples;
    // количество сэмплов в видимой области
    const maxSamples = scrollOffset + visibleSamples;
    // если остается 10% до конца видимой области
    const trap = (maxSamples / 100) * 90;

    // если текущий воспроизводимы сэмпл попал в зону для переноса
    if (currentTimeSample > trap) {
      // новый офсет = текущий семпл минус 10%
      const newScrollOffset = currentTimeSample - (visibleSamples / 100) * 10;
      setScrollOffset(Math.min(maxScrollOffset, newScrollOffset));
    }
  }, [zoomLevel, currentTime, duration, maxScrollOffset, scrollOffset, samples, isPlaying, isScrolling, totalSamples]);

  // отрисовка осциллограммы
  useEffect(() => {
    if (isAudioLoaded && duration > 0) {
      drawWaveform(zoomLevel, activeChannels, channelCount, scrollOffset, segments, showSegments, totalSamples);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel, activeChannels, channelCount, scrollOffset, segments, showSegments, totalSamples, currentTime]);

  // левый и правый канал по центру
  useEffect(() => {
    if (!audioRef) return;
    if (!audioRef.current) return;

    if (!audioContext.current) {
      audioContext.current = new AudioContext();
    }

    if (!audioSource.current) {
      audioSource.current = audioContext.current.createMediaElementSource(audioRef.current);
    }

    const splitter = audioContext.current.createChannelSplitter(2);
    const merger = audioContext.current.createChannelMerger(2);

    const leftGain = audioContext.current.createGain();
    const rightGain = audioContext.current.createGain();

    leftGain.gain.value = activeChannels.left ? leftVolume : 0;
    rightGain.gain.value = activeChannels.right ? rightVolume : 0;

    // Отключаем старое соединение и создаем новое
    audioSource.current.disconnect();
    audioSource.current.connect(splitter);

    // Подключаем левый канал к обоим выходам через leftGain
    splitter.connect(leftGain, 0);
    leftGain.connect(merger, 0, 0); // к левому выходу
    leftGain.connect(merger, 0, 1); // к правому выходу

    // Подключаем правый канал к обоим выходам через rightGain
    splitter.connect(rightGain, 1);
    rightGain.connect(merger, 0, 0); // к левому выходу
    rightGain.connect(merger, 0, 1); // к правому выходу

    // Подключаем мерджер к выходу аудиоконтекста
    merger.connect(audioContext.current.destination);
  }, [activeChannels, leftVolume, rightVolume, audioRef]);

  //чтобы при переключении срабатывало значение по умолчанию, а редакс использовался только для отображения в стт
  useEffect(() => {
    if (isMono) {
      dispatch({ type: SET_ACTIVECHANNELS, payload: { left: true, right: false } });
    } else {
      dispatch({ type: SET_ACTIVECHANNELS, payload: { left: true, right: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMono]);

  return (
    <div className={classes.player}>
      <div className={classes.btns}>
        <div className={classes.row}>
          <div
            className={clsx(classes.btn, { disabled: !segments })}
            title="Предыдущая запись"
            onClick={handleSelectPrev}
          >
            <SkipPreviousIcon className={classes.icon} />
          </div>

          {/* Предыдущий сегмент */}
          <div className={classes.btn} title="Предыдущий сегмент" onClick={goToPreviousSegment}>
            <RotateLeftIcon className={classes.icon} />
          </div>

          {/* Плэй/Пауза */}
          <div
            className={classes.btn}
            onClick={playPause}
            title={isPlaying ? "Остановить воспроизведение" : "Воспроизвести"}
          >
            {isPlaying ? (
              <Pause fontSize="large" className={classes.icon} />
            ) : (
              <PlayArrow fontSize="large" className={classes.icon} />
            )}
          </div>

          <div className={classes.btn} title="Стоп" onClick={handleStop}>
            <StopIcon className={classes.icon} />
          </div>

          {/* Следующий сегмент */}
          <div className={classes.btn} title="Следующий сегмент" onClick={goToNextSegment}>
            <RotateRightIcon className={classes.icon} />
          </div>

          <div
            className={clsx(classes.btn, { disabled: !segments })}
            title="Следующая запись"
            onClick={handleSelectNext}
          >
            <SkipNextIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.row}>
          {/* Настройки */}
          <PlayerSettings />
          {/* Каналы */}
          <ChannelButtons activeChannels={activeChannels} isMono={isMono} toggleChannel={toggleChannel} />
          <div className={classes.btn}>
            <IconButton color="primary" size="medium" title="Увеличить масштаб" onClick={zoomIn}>
              <img src="/img/iconzoomin.svg" alt="Увеличить масштаб" width={24} className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.btn}>
            <IconButton color="primary" size="medium" title="Уменьшить масштаб" onClick={zoomOut}>
              <img
                src="/img/iconzoomout.svg"
                alt="Уменьшить масштаб"
                width={24}
                className={classes.icon}
                style={{ strokeWidth: 3 }}
              />
            </IconButton>
          </div>
          <div
            className={clsx(classes.btn, currentSpeed !== 1 && classes.activeSpeedBtn)}
            onClick={handleSpeedChange}
            title="Скорость воспроизведения"
          >
            <span className={clsx(classes.icon, classes.speedIcon)} style={{ userSelect: "none" }}>
              {currentSpeed}x
            </span>
          </div>
          {/* Громкость */}
          <div className={clsx(classes.btn)} title="Громкость">
            {/* <GetAppIcon className={classes.icon} /> */}
            <VolumeControl
              leftVolume={leftVolume}
              rightVolume={rightVolume}
              setLeftVolume={handleLeftVolumeChange}
              setRightVolume={handleRightVolumeChange}
              activeChannels={activeChannels}
            />
          </div>
        </div>
      </div>

      <audio ref={audioRef} src={audioUrl} crossOrigin="anonymous" onError={handleAudioError} />
      <Divider orientation="vertical" className={classes.mr8} />
      <div ref={ref} className={classes.waveform}>
        {zoomLevel > 1 ? (
          <Scroll
            scrollBarWidth={scrollBarWidth}
            scrollOffset={scrollOffset}
            setScrollOffset={setScrollOffset}
            maxScrollOffset={maxScrollOffset}
            setIsScrolling={setIsScrolling}
          />
        ) : (
          <KeywordsOverlay markers={keywords} duration={duration} onClick={(s: number) => currTimeSubscriber.next(s)} />
        )}
        <canvas ref={canvasRef} onClick={handleCanvasClick} className={classes.canvas} onWheel={handleWheel} />
        <div className={clsx(classes.loading, !isLoading && classes.loadingHidden)}>
          <LoadingAudio />
        </div>
      </div>
      <Divider orientation="vertical" className={classes.ml8} />
      {/* Время */}
      <div className={classes.time}>
        {currentTime >= 0 ? <div>{normalizeTime(currentTime)}</div> : <div>00:00</div>}
        <div>{normalizeTime(duration)}</div>
      </div>
    </div>
  );
};

export default Player;
