import React, { FC } from "react";
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import { LmModel } from "types/admin/lmModel";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import CheckboxRenderer from "components/agGrid/renderers/CheckboxRenderer";
import StatusRenderer from "components/agGrid/renderers/StatusRenderer";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { langGetter } from "../../../../components/agGrid/valueGetters";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "№",
    field: "id",
    width: 100,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Статус",
    width: 100,
    field: "status",
    cellRenderer: "StatusRenderer",
  },
  {
    headerName: "Сообщение",
    width: 300,
    field: "message",
  },
  {
    headerName: "Имя",
    field: "name",
  },
  {
    headerName: "По умолчанию",
    field: "isDefault",
    cellRenderer: "CheckboxRenderer",
  },
  {
    headerName: "Язык",
    field: "lang",
    valueGetter: langGetter,
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: LmModel[];
  setGridApi: Function;
  onSelection: Function;
}

const LmModelsTable: FC<Props> = ({ rowData, setGridApi, onSelection }) => {
  const classes = useStyles();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: LmModel) => String(data.id);

  return (
    <div className={clsx(classes.root, "ag-theme-balham")}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        frameworkComponents={{
          DateRenderer,
          CheckboxRenderer,
          StatusRenderer,
        }}
      />
    </div>
  );
};

export default LmModelsTable;
