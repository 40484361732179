import React, { FC, useCallback } from "react";
import clsx from "clsx";
import { Label as TLabel } from "types/label";
import { setPageLabelsTableSettings } from "redux/actions/pageSettingsActions";
import { RootState } from "redux/types";
import { useDispatch, useSelector } from "react-redux";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import ColorRender from "components/agGrid/renderers/ColorRenderer";
import LabelNameRenderer from "components/agGrid/renderers/LabelNameRenderer";
import { ColumnResizedEvent } from "ag-grid-community";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 151px)",
  },
}));

const defaultColDef = {
  resizable: true,
  sortable: true,
  whiteSpace: "normal",
  cellClass: "centeredText",
};

const rowHeight = 50;

const columnDefs = [
  {
    headerName: "№",
    field: "id",
    width: 100,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Имя",
    field: "name",
    // width: 400,
    flex: 1,
    cellRenderer: "LabelNameRenderer",
  },
  {
    headerName: "Цвет",
    field: "color",
    cellRenderer: "ColorRender",
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Создатель",
    field: "ownerId",
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: TLabel[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
}

const LabelsTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { labels: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: TLabel) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageLabelsTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div className={clsx(classes.root, "ag-theme-balham")} style={{ fontSize: "14px" }}>
      <AgGridReact
        rowHeight={rowHeight}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        suppressMovableColumns
        // suppressColumnMoveAnimation
        // suppressCopyRowsToClipboard
        scrollbarWidth={8}
        frameworkComponents={{
          DateRenderer,
          ColorRender,
          LabelNameRenderer,
        }}
      />
    </div>
  );
};

export default LabelsTable;
