import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import useLabels from "hooks/useLabels";
import { Label as TLabel } from "types/label";
import { addRow, removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { useDispatch } from "react-redux";
import LabelService from "services/LabelService";
import { setPageLabelsTableSettings } from "redux/actions/pageSettingsActions";
import { catchError, checkError } from "functions/common";

// components
import LabelsTable from "./components/LabelsTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import CreateLabelDialog from "./components/CreateLabelDialog";
import UpdateLabelDialog from "./components/UpdateLabelDialog";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    flexGrow: 1,
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  mr10: {
    marginRight: 10,
  },
}));

const Labels: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { labels, error } = useLabels();

  const [selectedRows, setSelectedRows] = useState<TLabel[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageLabelsTableSettings(colState));
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((e) => e.id).join(",");
      LabelService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      LabelService.create(data)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch(catchError);
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data) {
      LabelService.update(data)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.actions}>
          <div className={classes.buttons}>
            <IconButton
              className={classes.mr10}
              onClick={() => setOpenSettingsTableDialog(true)}
              title="Настройка колонок таблицы"
              size="small"
            >
              <GridOnIcon fontSize="inherit" />
            </IconButton>
            <div className={classes.searchWrapper}>
              <TextField
                className={classes.search}
                placeholder="Имя"
                onChange={onQuickFilterChanged}
                InputProps={{
                  className: classes.searchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => setOpenDeleteDialog(true)}
                disabled={selectedRows.length === 0}
              >
                Удалить
              </Button>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => setOpenUpdateDialog(true)}
                disabled={selectedRows.length !== 1}
              >
                Редактировать
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.button}
                onClick={() => setOpenCreateDialog(true)}
                style={{ marginRight: 0 }}
              >
                Создать
              </Button>
            </div>
          </div>
        </div>

        <LabelsTable rowData={labels} setGridApi={setGridApi} setColumnApi={setColumnApi} onSelection={onSelection} />

        <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
        <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
        <CreateLabelDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />

        {selectedRows.length === 1 && (
          <UpdateLabelDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} label={selectedRows[0]} />
        )}
      </Paper>
    </div>
  );
};

export default Labels;
