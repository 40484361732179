import React, { FC } from "react";
import { useHistory } from "react-router-dom";

// components
import User from "./User";
import AllServices from "./AllServices";
import DownloadRO from "components/DownloadRO";
import MessagesCount from "./messages/MessagesCount";
import WsConnect from "./WsConnect";
import FileUpload from "./fileUpload/FileUpload";
import SystemError from "components/SystemError";

// material ui
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 64,
    color: "white",
  },
  img: {
    width: 48,
    height: 48,
  },
  link: {
    textDecoration: "none",
    color: "#ffffff",
    fontSize: theme.typography.fontSize * 1.2,
    marginRight: 20,
  },
  questions: {
    marginRight: 10,
  },
  messages: {
    marginRight: 20,
  },
  user: {
    // marginRight: 20,
  },
  logoLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  allServices: {
    // marginRight: 10,
  },
  wsConnect: {
    marginRight: 10,
  },
  fileUpload: {
    marginRight: 10,
  },
  freeSpace: {
    flex: 1,
    padding: "0 20px",
    display: "flex",
    // justifyContent: "center",
  },
}));

const Header: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const logoClick = () => {
    history.push("/");
  };

  return (
    <section className={classes.container}>
      <div className={classes.logoLink} onClick={logoClick}>
        <img src="/logo.ico" alt="Replica 7" className={classes.img} />
        <Typography variant="h6" noWrap className={classes.title}>
          Технологии обработки речи
        </Typography>
      </div>

      <div className={classes.freeSpace}>
        <SystemError />
      </div>

      <div className={classes.questions}>
        <DownloadRO />
      </div>

      <div className={classes.allServices}>
        <AllServices />
      </div>

      <div className={classes.fileUpload}>
        <FileUpload />
      </div>

      <div className={classes.wsConnect}>
        <WsConnect />
      </div>

      <div className={classes.messages}>
        <MessagesCount />
      </div>

      <div className={classes.user}>
        <User />
      </div>
    </section>
  );
};

export default Header;
