import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { DownloadTextType, Record, RecordDetail, RecordFileViewType, Segment as SegmentType } from "types/record";
import useSegments from "hooks/records/useSegments";
import useTranslatedSegments from "hooks/records/useTranslateSegments";
import useKeywords from "hooks/records/useKeywords";
import { setSttShowTranslate, setSttEditeMode } from "redux/actions/settingsActions";
import { TechType, TaskDetailType } from "types/task";
import TaskService from "services/TaskService";
import RecordService from "services/RecordService";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import clsx from "clsx";
import { copyToClipboard } from "functions/common";

// components
import STTSettings from "../STTSettings";
import CreateTaskSttDialog from "../CreateTaskSttDialog";
import DiarizationSettings from "../DiarizationSettings";
import CopyToClipboard from "../CopyToClipboard";
import TextSegments from "./TextSegments";
import TextKeywords from "./TextKeywords";

// icons
import TextFieldsIcon from "@material-ui/icons/TextFields";
import TranslateIcon from "@material-ui/icons/Translate";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 369px)",
  },
  player: {
    padding: theme.spacing(0, 1),
  },
  stt: {
    flexGrow: 1,
    overflow: "auto",
    paddingLeft: theme.spacing(0, 1),
    display: "flex",
    justifyContent: "space-between",
  },
  keywords: {
    flexBasis: 300,
    flexGrow: 0,
    flexShrink: 0,
  },
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    padding: "4px 0px 4px 4px",
    alignItems: "center",
  },
  translate: {
    marginRight: 10,
    width: 100,
  },
  flagButton: {
    margin: "5px",
    padding: "0px",
    height: "fit-content",
  },
  fc: {
    height: "fit-content",
  },
  ml10: {
    marginLeft: 10,
  },
  ml20: {
    marginLeft: 20,
  },
  mr10: {
    margin: "5px",
  },
  mr30: {
    marginRight: 30,
  },
  settings: {
    flexGrow: 1,
    textAlign: "right",
  },
  list: {
    width: 100,
  },
  body: {
    width: 315,
    padding: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  segments: {
    height: "100%",
  },
  flex50: {
    flexBasis: "50%",
  },
  fg1: {
    flexGrow: 1,
  },
  divider: {
    margin: "0 13px",
  },
  actions: {
    flexGrow: 1,
    textAlign: "left",
  },
  lastButton: {
    margin: "5px 0px 5px 5px",
  },
  fixPadding: {
    padding: "3px 9px",
  },
}));

interface Props {
  queryFilter: string;
  viewType: RecordFileViewType.TEXT;
  record: Record;
  filterv2: string; // поисковая строка
  recordDetail: RecordDetail;
}

const TextSTT: FC<Props> = ({ record, filterv2, recordDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    playerChannel,
    sttShowKeywords,
    sttShowTranslate,
    sttBackgroundColor,
    diarizationHighlight,
    sttFont,
    sttFontSize,
    sttTextColor,
    editMode,
  } = useSelector((state: RootState) => state.settings);

  const [anchorSettings, setAnchorSettings] = useState<null | HTMLElement>(null);
  const [anchorCopyToClipboard, setAnchorCopyToClipboard] = useState<null | HTMLElement>(null);
  const [anchorDownloadFile, setAnchorDownloadFile] = useState<null | HTMLElement>(null);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [diarizationSpeakers, setDiarizationSpeakers] = useState<string[]>([]);

  // query string для сегментов
  const segmentsQueryString = useMemo(() => {
    const highlight = diarizationHighlight ? 1 : 0;
    const speakers = diarizationSpeakers.join(",");
    const channel = record.channelCount === 1 ? -1 : playerChannel;
    return `?channel=${channel}&isHighlightDiarization=${highlight}&diarizationSpeakers=${speakers}`;
  }, [playerChannel, diarizationHighlight, diarizationSpeakers, record]);
  // сегменты
  const { segments, loading: loadingSegments, error, setState: setSegments } = useSegments(
    record.id,
    segmentsQueryString
  );
  // перевод
  const { segments: tSegments } = useTranslatedSegments(record.id, segmentsQueryString);

  // query string для ключевых слов
  const keywordsQueryString = useMemo(() => `?filterv2=${filterv2}&channel=${playerChannel}`, [
    playerChannel,
    filterv2,
  ]);
  // ключевые слова
  const { keywords, loading: loadingKeywords, error: errorKeywords } = useKeywords(record.id, keywordsQueryString);

  const segmentsStyle = useMemo(
    () => ({
      flexGrow: 1,
      display: "flex",
      justifyContent: sttShowTranslate ? "" : "center",
      backgroundColor: sttBackgroundColor,
      fontFamily: sttFont,
      fontSize: sttFontSize,
      color: sttTextColor,
      padding: "0px 8px",
    }),
    [sttBackgroundColor, sttShowTranslate, sttFont, sttFontSize, sttTextColor]
  );

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const handleCloseCreateTaskDialog = (data?: any) => {
    setOpenCreateTaskDialog(false);
    if (data !== undefined) {
      const { techSTT, techVad } = data;
      const { lang, translate, languageModelId } = techSTT;
      const { vadType } = techVad;
      const techs: any = [
        { type: TechType.STT, IsEnableWord2Num: true, lang, languageModelId },
        { type: TechType.VAD, vadType },
      ];
      if (translate) {
        techs.push({ type: TechType.TRANSLATE });
      }
      const body: any = {
        name: "Автоматическое задание",
        comment: "",
        isActive: true,
        taskDetail: {
          type: TaskDetailType.Process,
          filterDetail: `Идентификатор=${record.id}`,
          techDetail: {
            isForce: true,
            techs,
          },
        },
      };
      TaskService.create(body)
        .then(() => {
          dispatch(showSuccessAlert("Задание создано и поставлено в очередь обработки."));
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const copyResultToClipboard = useCallback(
    (type: DownloadTextType) => {
      RecordService.getSTTResultAsText(record.id, type)
        .then(({ data }) => {
          if (copyToClipboard(data)) {
            dispatch(showSuccessAlert("Скопировано в буфер обмена."));
            setAnchorCopyToClipboard(null);
          }
        })
        .catch((err) => catchError(err.response.data));
    },
    [catchError, record, dispatch]
  );

  const downloadSttText = useCallback(
    (type: DownloadTextType) => {
      RecordService.getSTTResultAsFile(record.id, type)
        .then(({ data }) => {
          const href = window.URL.createObjectURL(data);
          const a = document.createElement("a");
          const noExtension: string = record.name.slice(0, record.name.lastIndexOf("."));
          a.href = href;
          //так как в рекорд нэйм есть разширение, то нужно его обрезать до точки + добавить тхт
          a.download = noExtension + ".txt";
          a.click();
          a.remove();
          URL.revokeObjectURL(href);
        })
        .catch((err) => catchError(err.response.data));
    },
    [catchError, record]
  );

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event: any) => {
      if (!editMode) return;
      event.stopPropagation();
      const { key } = event;
      if (key === "Enter") event.preventDefault();
    },
    [editMode]
  );

  const updateSegment = (segment: SegmentType) => {
    setSegments((prev) => {
      const { segments } = prev;
      const updatedSegments = segments.map((s) => (s.id === segment.id ? segment : s));
      return { ...prev, segments: updatedSegments };
    });
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (errorKeywords === undefined) return;
    catchError(errorKeywords?.response?.data);
  }, [errorKeywords, catchError]);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <div className={classes.actions}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={clsx(classes.mr10, classes.fc, "responsive-button")}
            title="Сохранить текст в буфер обмена"
            onClick={(e) => setAnchorCopyToClipboard(e.currentTarget)}
          >
            <FileCopyIcon />
          </Button>
          <Button
            className={clsx(classes.mr10, classes.fc, "responsive-button")}
            color="primary"
            size="small"
            variant="contained"
            onClick={(e) => setAnchorDownloadFile(e.currentTarget)}
            title="Сохранить текст в файл"
          >
            <GetAppIcon />
          </Button>
          {record.isDiarization && (
            <DiarizationSettings
              recordId={record.id}
              diarizationSpeakers={diarizationSpeakers}
              setDiarizationSpeakers={setDiarizationSpeakers}
            />
          )}
          <Button
            className={clsx(classes.mr10, classes.fc, "responsive-button")}
            color="primary"
            variant="contained"
            size="small"
            title="Настройки отображения текста"
            onClick={(e) => setAnchorSettings(e.currentTarget)}
          >
            <TextFieldsIcon />
          </Button>
        </div>
        <Button
          color="primary"
          variant={editMode ? "contained" : "outlined"}
          className={clsx(classes.mr10, classes.fc, "responsive-button")}
          size="small"
          title="Редактирование"
          onClick={() => dispatch(setSttEditeMode(!editMode))}
        >
          <EditIcon />
        </Button>
        <Button
          color="primary"
          variant={sttShowTranslate ? "contained" : "outlined"}
          size="small"
          className={clsx(classes.fc, classes.lastButton, classes.fixPadding, "responsive-button")}
          title="Показать перевод"
          onClick={() => dispatch(setSttShowTranslate(!sttShowTranslate))}
        >
          <TranslateIcon />
        </Button>
      </div>

      <div className={classes.stt}>
        <div style={segmentsStyle} className={clsx("app_stt-segments")}>
          <TextSegments
            segments={segments}
            tSegments={tSegments}
            loading={loadingSegments}
            error={error}
            keywords={keywords}
            alwaysOnDisplay
            showTranslate={sttShowTranslate}
            recordDetail={recordDetail}
            updateSegment={updateSegment}
          />
        </div>

        <div className={clsx(classes.keywords, { [classes.hidden]: !sttShowKeywords })}>
          <TextKeywords keywords={keywords} loading={loadingKeywords} />
        </div>
      </div>

      <STTSettings anchorEl={anchorSettings} setAnchorEl={setAnchorSettings} />
      <CopyToClipboard
        anchorEl={anchorCopyToClipboard}
        setAnchorEl={setAnchorCopyToClipboard}
        onClick={copyResultToClipboard}
      />

      <CopyToClipboard anchorEl={anchorDownloadFile} setAnchorEl={setAnchorDownloadFile} onClick={downloadSttText} />

      <CreateTaskSttDialog open={openCreateTaskDialog} onClose={handleCloseCreateTaskDialog} sttLang={record.lang} />
    </div>
  );
};

export default TextSTT;
