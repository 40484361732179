import React, { FC, useEffect, useMemo, useState } from "react";
import { API_URL, records } from "http/urls";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { Record, RecordDetail, RecordFileViewType } from "types";
import EmptyPlayer from "./EmptyPlayer";
import useKeywords from "hooks/records/useKeywords";
import useSegments from "hooks/records/useSegments";
import Player from "./Player";
import { setAudioSrc } from "redux/actions/settingsActions";
import { showErrorAlert } from "redux/actions/alertActions";

interface Props {
  recordDetail: RecordDetail | undefined;
  record: Record | undefined;
  filterv2: string;
  selectNextOrPrevRow: (der: "prev" | "next") => void;
}

const PlayerWrapper: FC<Props> = ({ recordDetail, record, filterv2, selectNextOrPrevRow }) => {
  if (
    record !== undefined &&
    recordDetail !== undefined &&
    recordDetail.codec !== "txt" &&
    recordDetail.codec !== "doc" &&
    recordDetail.codec !== "docx" &&
    recordDetail.codec !== "pdf" &&
    recordDetail.codec !== "c2v" &&
    recordDetail.codec !== "tiff" &&
    recordDetail.codec !== "xls" &&
    recordDetail.codec !== "jpeg" &&
    recordDetail.codec !== "png"
  ) {
    return (
      <InternalElement
        recordDetail={recordDetail}
        record={record}
        filterv2={filterv2}
        selectNextOrPrevRow={selectNextOrPrevRow}
      />
    );
  }

  return <EmptyPlayer />;
};

interface InternalElementProps {
  recordDetail: RecordDetail;
  record: Record;
  filterv2: string;
  selectNextOrPrevRow: (der: "prev" | "next") => void;
}

const InternalElement: FC<InternalElementProps> = ({ recordDetail, record, filterv2, selectNextOrPrevRow }) => {
  const dispatch = useDispatch();
  const { filter } = recordDetail;
  const viewType: RecordFileViewType = RecordFileViewType.AUDIO;

  const { accessToken } = useSelector((state: RootState) => state.auth);
  const {
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerChannel,
    playerGain,
  } = useSelector((state: RootState) => state.settings);

  const audioSrc = useMemo(() => {
    const url = API_URL + records.getFileData;
    const isPan = playerIsPan === undefined ? 1 : playerIsPan;
    const isAdeClick = playerIsAdeClick === undefined ? 0 : playerIsAdeClick;
    const isKTCH = playerIsKTCH === undefined ? 0 : playerIsKTCH;
    const isNoise = playerIsNoise === undefined ? 0 : playerIsNoise;
    const isEcho = playerIsEcho === undefined ? 0 : playerIsEcho;
    const channel = playerChannel === undefined ? -1 : playerChannel;
    const gain = playerGain === undefined ? 1 : playerGain;
    const playerSettings = `&isPan=${isPan}&isAdeClick=${isAdeClick}&isNoise=${isNoise}&channel=${channel}&volume=${gain}&isKTCH=${isKTCH}&isEcho=${isEcho}`;
    return decodeURI(
      url.replace(":viewType", viewType) + `?q=${filter}` + playerSettings + `&authorization=${accessToken}`
    );
  }, [
    filter,
    viewType,
    accessToken,
    playerIsPan,
    playerIsAdeClick,
    playerIsKTCH,
    playerIsEcho,
    playerIsNoise,
    playerChannel,
    playerGain,
  ]);

  // query string для ключевых слов
  const keywordsQueryString = useMemo(() => `?filterv2=${filterv2}&channel=${playerChannel}`, [
    playerChannel,
    filterv2,
  ]);

  // ключевые слова
  const { keywords } = useKeywords(record.id, keywordsQueryString);
  // сегменты
  const { segments } = useSegments(record.id, `?filterv2=${filterv2}`);

  const showSegments = useSelector((state: RootState) => state.settings.showSegments);

  const audioPlayerSegments = segments.map((segment) => ({
    id: segment.id,
    recordId: segment.recordId,
    channelNumber: segment.channelNumber,
    start: segment.start,
    end: segment.end,
  }));

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
    dispatch(setAudioSrc(audioSrc));
  }, [audioSrc, dispatch]);

  const handleAudioError = () => {
    setHasError(true);
    dispatch(showErrorAlert(`Ошибка загрузки аудио для файла ${record.name}`));
  };

  if (hasError) {
    return <EmptyPlayer />;
  }

  return (
    <Player
      audioUrl={audioSrc}
      keywords={keywords}
      fileName={record.name}
      channelCount={record.channelCount}
      segments={audioPlayerSegments}
      showSegments={showSegments}
      selectNextOrPrevRow={selectNextOrPrevRow}
      handleAudioError={handleAudioError}
    />
  );
};

export default PlayerWrapper;
