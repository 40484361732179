import React, { useState, FC, useCallback, ChangeEvent, useEffect, useMemo } from "react";
import { ExportTask, Task, TaskDetailType } from "types";
import { useDispatch } from "react-redux";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import useTasks from "hooks/task/useTasks";
import { showSuccessAlert } from "redux/actions/alertActions";
import TaskService from "services/TaskService";
import { addRow, removeRows, setQuickFilter, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { Permission } from "types/permission";
import { Group } from "types/queryBuilder";
import { catchError, checkError, generateUuid } from "functions/common";
import { setPageTasksTableSettings } from "redux/actions/pageSettingsActions";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import TasksTable from "./components/TasksTable";
import CreateTaskDialog from "./components/CreateTaskDialog";
import CreateExportTaskDialog from "./components/CreateExportTaskDialog";
import UpdateTaskDialog from "./components/UpdateTaskDialog";
import SetTaskPermissions from "./components/SetTaskPermissions";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  mr10: {
    marginRight: 10,
  },
}));

const Tasks: FC = () => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState<Task[]>([]);

  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openCreateExportDialog, setOpenCreateExportDialog] = useState(false);
  const [openSetPermissionsDialog, setOpenSetPermissionsDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const { error, tasks: rowData, getTasks } = useTasks();

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageTasksTableSettings(colState));
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQuickFilter(value, gridApi);
  };

  const onCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteConfirmationDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      TaskService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data !== undefined) {
      const { name, comment, isActive, isForce, techs, filter } = data;
      const body: any = {
        name,
        comment,
        isActive,
        taskDetail: {
          type: TaskDetailType.Process,
          filterDetail: filter,
          techDetail: {
            isForce,
            techs,
          },
          exports: data.exports,
        },
      };
      TaskService.create(body)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateExportDialog = (data?: ExportTask) => {
    setOpenCreateExportDialog(false);
    if (data !== undefined) {
      TaskService.create(data)
        .then(({ data }) => {
          addRow(data, gridApi);
        })
        .catch(catchError);
    }
  };

  const handleCloseEditExportTaskDialog = (data?: ExportTask) => {
    setOpenEditDialog(false);
    if (data !== undefined) {
      TaskService.update(data)
        .then(({ data }) => {
          updateRow(data, gridApi);
        })
        .catch(catchError);
    }
  };

  const handleCloseEditTaskDialog = (data?: any) => {
    setOpenEditDialog(false);
    if (data !== undefined) {
      const { id, name, comment, isActive, isForce, techs, filter } = data;
      const body: any = {
        id,
        name,
        comment,
        isActive,
        taskDetail: {
          type: TaskDetailType.Process,
          filterDetail: filter,
          techDetail: {
            isForce,
            techs,
          },
          exports: data.exports,
        },
      };
      TaskService.update(body)
        .then((res) => {
          updateRow(res.data, gridApi);
        })
        .catch(catchError);
    }
  };

  const isEditDisabled = () => {
    if (selectedRows.length !== 1) return true;
    const task = selectedRows[0];
    return !(task.taskDetail.type === TaskDetailType.Process || task.taskDetail.type === TaskDetailType.Export);
  };

  const isDisabledRemove = useCallback(() => {
    if (selectedRows.length === 0) return true;
    return !selectedRows.every((n) => n.isCanWrite);
  }, [selectedRows]);

  const isDisabledUpdate = useCallback(() => {
    if (selectedRows.length !== 1) return true;
    return !selectedRows[0].isCanWrite;
  }, [selectedRows]);

  // изменение прав
  const handleCloseChangePermissionsDialog = (data?: Permission[]) => {
    setOpenSetPermissionsDialog(false);
    if (data !== undefined && selectedRows.length === 1) {
      const { id } = selectedRows[0];
      TaskService.setPermissions(id, data)
        .then(() => {
          dispatch(showSuccessAlert("Права изменены"));
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.actions}>
              <div className={classes.buttons}>
                <div>
                  <IconButton
                    className={classes.mr10}
                    onClick={() => setOpenSettingsTableDialog(true)}
                    title="Настройка колонок таблицы"
                    size="small"
                  >
                    <GridOnIcon fontSize="inherit" />
                  </IconButton>
                  <Button className={classes.mr10} color="primary" size="small" variant="contained" onClick={getTasks}>
                    Обновить
                  </Button>
                  <TextField
                    className={classes.search}
                    placeholder="Имя задания"
                    onChange={onQuickFilterChanged}
                    InputProps={{
                      className: classes.searchInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.mr10}
                    onClick={() => setOpenCreateExportDialog(true)}
                  >
                    Экспорт
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.mr10}
                    disabled={isDisabledUpdate()}
                    onClick={() => setOpenSetPermissionsDialog(true)}
                  >
                    Права
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.mr10}
                    disabled={isDisabledRemove()}
                    onClick={() => setOpenDeleteConfirmationDialog(true)}
                  >
                    Удалить
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.mr10}
                    disabled={isEditDisabled()}
                    onClick={() => setOpenEditDialog(true)}
                  >
                    Редактировать
                  </Button>
                  <Button color="primary" variant="contained" size="small" onClick={() => setOpenCreateDialog(true)}>
                    Создать
                  </Button>
                </div>
              </div>
            </div>

            <TasksTable
              rowData={rowData}
              setGridApi={setGridApi}
              setColumnApi={setColumnApi}
              onSelection={onSelection}
            />
          </Paper>
        </Grid>
      </Grid>

      <ConfirmationDialog open={openDeleteConfirmationDialog} onClose={onCloseDeleteDialog} />
      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      {openCreateDialog && (
        <CreateTaskDialog
          filterDetail={defaultFilter}
          open={openCreateDialog}
          onClose={handleCloseCreateDialog}
          showCheckboxes
          isForce={false}
        />
      )}
      <CreateExportTaskDialog
        filterDetail={defaultFilter}
        open={openCreateExportDialog}
        onClose={handleCloseCreateExportDialog}
      />

      {selectedRows.length === 1 && (
        <>
          <UpdateTaskDialog
            open={openEditDialog}
            onCloseTaskEdit={handleCloseEditTaskDialog}
            onCloseExportTaskEdit={handleCloseEditExportTaskDialog}
            task={selectedRows[0]}
          />
          <SetTaskPermissions
            open={openSetPermissionsDialog}
            onClose={handleCloseChangePermissionsDialog}
            id={selectedRows[0].id}
          />
        </>
      )}
    </div>
  );
};

export default Tasks;
