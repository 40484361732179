import { useMemo } from "react";
import { AudioPlayerSegment, ChannelState } from "types/player";

export const useSegmentFiltering = (
  segments: AudioPlayerSegment[],
  activeChannels: ChannelState,
  channelCount: number
) => ({
  filteredSegments: useMemo(
    () =>
      segments.filter(
        (segment) =>
          channelCount === 1 ||
          (activeChannels.left && segment.channelNumber === 0) ||
          (activeChannels.right && segment.channelNumber === 1)
      ),
    [segments, activeChannels, channelCount]
  ),
});
