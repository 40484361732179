import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import useFilters from "hooks/useFilters";
import FilterService from "services/FilterService";
import { showSuccessAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { Filter } from "types/filter";
import { Permission } from "types/permission";
import { updateColumnOrder } from "components/agGrid/functions";
import { setPageFilterTableSettings } from "redux/actions/pageSettingsActions";
import { catchError, checkError } from "functions/common";

// components
import ConfirmationDialog from "components/ConfirmationDialog";
import FiltersTable from "./components/FiltersTable";
import CreateFilterDialog from "./components/CreateFilterDialog";
import UpdateFilterDialog from "./components/UpdateFilterDialog";
import SetFilterPermissions from "./components/SetFilterPermissions";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: 10,
  },
  searchWrapper: {
    flexGrow: 1,
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  mr10: {
    marginRight: 10,
  },
}));

const Filters: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { filters: rowData, error, getFilters } = useFilters();

  const [selectedRows, setSelectedRows] = useState<Filter[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSetPermissionsDialog, setOpenSetPermissionsDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageFilterTableSettings(colState));
    }
  };

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      FilterService.remove(ids)
        .then(() => {
          setSelectedRows([]);
          getFilters();
        })
        .catch(catchError);
    }
  };

  const handleCloseCreateDialog = (data?: any) => {
    setOpenCreateDialog(false);
    if (data) {
      FilterService.create(data)
        .then(() => {
          setSelectedRows([]);
          getFilters();
        })
        .catch(catchError);
    }
  };

  const handleCloseUpdateDialog = (data?: any) => {
    setOpenUpdateDialog(false);
    if (data) {
      FilterService.update(data)
        .then(() => {
          setSelectedRows([]);
          getFilters();
        })
        .catch(catchError);
    }
  };

  const isDisabledRemove = useCallback(() => {
    if (selectedRows.length === 0) return true;
    return !selectedRows.every((n) => n.isCanWrite);
  }, [selectedRows]);

  const isDisabledUpdate = useCallback(() => {
    if (selectedRows.length !== 1) return true;
    return !selectedRows[0].isCanWrite;
  }, [selectedRows]);

  // изменение прав
  const handleCloseChangePermissionsDialog = (data?: Permission[]) => {
    setOpenSetPermissionsDialog(false);
    if (data !== undefined && selectedRows.length === 1) {
      const { id } = selectedRows[0];
      FilterService.setPermissions(id, data)
        .then(() => {
          dispatch(showSuccessAlert("Права изменены"));
        })
        .catch(catchError);
    }
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.actions}>
          <div className={classes.buttons}>
            <IconButton
              className={classes.mr10}
              onClick={() => setOpenSettingsTableDialog(true)}
              title="Настройка колонок таблицы"
              size="small"
            >
              <GridOnIcon fontSize="inherit" />
            </IconButton>
            <div className={classes.searchWrapper}>
              <TextField
                className={classes.search}
                placeholder="Имя"
                onChange={onQuickFilterChanged}
                InputProps={{
                  className: classes.searchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                disabled={isDisabledUpdate()}
                onClick={() => setOpenSetPermissionsDialog(true)}
              >
                Права
              </Button>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => setOpenDeleteDialog(true)}
                disabled={isDisabledRemove()}
              >
                Удалить
              </Button>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => setOpenUpdateDialog(true)}
                disabled={selectedRows.length !== 1}
              >
                Редактировать
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.button}
                onClick={() => setOpenCreateDialog(true)}
              >
                Создать
              </Button>
            </div>
          </div>
        </div>

        <FiltersTable rowData={rowData} setGridApi={setGridApi} setColumnApi={setColumnApi} onSelection={onSelection} />

        <ConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} />
        <CreateFilterDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
        <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />

        {selectedRows.length === 1 && (
          <>
            <UpdateFilterDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} filter={selectedRows[0]} />
            <SetFilterPermissions
              open={openSetPermissionsDialog}
              onClose={handleCloseChangePermissionsDialog}
              id={selectedRows[0].id}
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default Filters;
