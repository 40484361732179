import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import { Group } from "types/queryBuilder";
import { generateUuid } from "functions/common";

// components
import Filter from "components/filter/Filter";
import HistoryFilters from "components/search/HistoryFilters";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  buttons: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    flexGrow: 1,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
}

interface State {
  name: string;
  comment: string;
}

const CreateFilterDialog: FC<Props> = ({ open, onClose }) => {
  const initialState: State = useMemo(
    () => ({
      name: "",
      comment: "",
    }),
    []
  );
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );
  const classes = useStyles();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [state, setState] = useState<State>(initialState);
  const [filter, setFilter] = useState<Group>(defaultFilter);
  const { name, comment } = state;
  const disabled = name.length === 0 || filter.filters.length === 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onClose({ ...state, filter: JSON.stringify(filter) });
  };

  useEffect(() => {
    setState(initialState);
  }, [open, initialState]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Создать фильтр</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              value={name}
              autoFocus
            />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom>Фильтр</Typography>
            <div className={classes.filter}>
              <div className={classes.input}>
                <Filter filter={filter} setFilter={setFilter} availableFilters={availableFilters} />
              </div>
              <HistoryFilters setFilter={setFilter} />
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>
        </Grid>

        <div className={classes.buttons} title={disabled ? "Укажите имя и фильтр" : ""}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFilterDialog;
