import {
  SETTINGS_SET_ROWS_PER_PAGE,
  SETTINGS_SET_STT_FONT,
  SETTINGS_SET_STT_FONT_SIZE,
  SETTINGS_SET_STT_TEXT_COLOR,
  SETTINGS_SET_STT_BACKGROUND_COLOR,
  SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER,
  SETTINGS_SET_STT_SHOW_TRANSLATE,
  SETTINGS_SET_STT_EDITE_MODE,
  SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT,
  SETTINGS_SET_RESULT_PANEL_SIZE,
  SETTINGS_SET_ALL,
  SETTINGS_SET_PLAYER_IS_PAN,
  SETTINGS_SET_PLAYER_IS_ADE_CLICK,
  SETTINGS_SET_PLAYER_IS_NORMALIZE,
  SETTINGS_SET_PLAYER_IS_NOISE,
  SETTINGS_SET_PLAYER_IS_KTCH,
  SETTINGS_SET_PLAYER_IS_ECHO,
  SETTINGS_SET_PLAYER_CHANNEL,
  SETTINGS_SET_PLAYER_GAIN,
  SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS,
  SETTINGS_SET_PLAYER_WAVEFORM_TYPE,
  SETTINGS_SET_ZOOM,
  SETTINGS_SET_SORT_NAME,
  SETTINGS_SET_SORT_ORDER,
  SETTINGS_SET_DIARIZATION_HIGHLIGHT,
  SettingsAction,
  SettingsState,
  SET_SHOW_SEGMENTS,
  SET_PLAYBACK_SPEED,
  SET_AUTOPLAY,
  SET_ACTIVECHANNELS,
  SET_LOOPING,
  SET_APP_FILTER,
  SET_RATIO_RATE,
  SET_DICTIONARY,
  SET_AUDIO_SRC,
  SETTINGS_SET_THEME_PRIMARY_COLOR,
  SETTINGS_SET_THEME_TYPOGRAPHY,
  SETTINGS_SET_THEME_COMPLETE,
} from "redux/types/settingsTypes";
import { grey, red, teal } from "@material-ui/core/colors";
import SettingsService from "services/admin/SettingsService";
import { WaveformType } from "types/player";
import { Key } from "types/settings";
import { ChannelState } from "types/player";
import { Dictionary } from "types/keyword";
import { getSecondaryColor } from "functions/theme";

export const defaultState: SettingsState = {
  rowsPerPage: 500,
  resultPanelSize: 1000,
  debounce: 1000,
  sttFont: "Arial, sans-serif",
  sttFontSize: 16,
  sttBackgroundColor: grey[200],
  sttTextColor: grey[800],
  sttSyncTextAndPlayer: true,
  sttShowKeywords: false,
  sttShowTranslate: true,
  playerIsPan: 0,
  playerIsAdeClick: 0,
  playerIsNormalize: 0,
  playerIsNoise: 0,
  playerIsKTCH: 0,
  playerIsEcho: 0,
  playerChannel: -1,
  playerGain: 1,
  playerPlayOnlyVoiceSegments: false,
  playerWaveformType: "waveform",
  playerZoom: 1,
  sortName: "Идентификатор",
  sortOrder: "asc",
  diarizationHighlight: true,
  editMode: false,
  confidenceHighlight: 1,
  showSegments: false,
  playbackSpeed: 1,
  autoplay: false,
  activeChannels: { left: true, right: true },
  looping: false,
  ratioRate: "50%",
  appFilter: "",
  dictionary: [],
  audioSrc: "",
  themePrimaryColor: teal[300],
  themeSecondaryColor: red[400],
  themeFontFamily: "Arial, sans-serif",
};

function save(s: SettingsState) {
  SettingsService.setByKye({ key: Key.SETTINGS, value: JSON.stringify(s) }).catch(console.error);
}

export default (state = defaultState, action: SettingsAction) => {
  const { type, payload } = action;

  if (type === SETTINGS_SET_ALL) {
    return { ...(payload as SettingsState) };
  }

  if (type === SETTINGS_SET_PLAYER_IS_PAN) {
    const settings: SettingsState = {
      ...state,
      playerIsPan: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_ADE_CLICK) {
    const settings: SettingsState = {
      ...state,
      playerIsAdeClick: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_NORMALIZE) {
    const settings: SettingsState = {
      ...state,
      playerIsNormalize: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_NOISE) {
    const settings: SettingsState = {
      ...state,
      playerIsNoise: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_KTCH) {
    const settings: SettingsState = {
      ...state,
      playerIsKTCH: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_IS_ECHO) {
    const settings: SettingsState = {
      ...state,
      playerIsEcho: payload as 0 | 1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_CHANNEL) {
    const settings: SettingsState = {
      ...state,
      playerChannel: payload as 0 | 1 | -1,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_GAIN) {
    const settings: SettingsState = {
      ...state,
      playerGain: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAYER_WAVEFORM_TYPE) {
    const settings: SettingsState = {
      ...state,
      playerWaveformType: payload as WaveformType,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_ZOOM) {
    const settings: SettingsState = {
      ...state,
      playerZoom: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_ROWS_PER_PAGE) {
    const settings: SettingsState = {
      ...state,
      rowsPerPage: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_FONT) {
    const settings: SettingsState = {
      ...state,
      sttFont: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_FONT_SIZE) {
    const settings: SettingsState = {
      ...state,
      sttFontSize: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_TEXT_COLOR) {
    const settings: SettingsState = {
      ...state,
      sttTextColor: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_BACKGROUND_COLOR) {
    const settings: SettingsState = {
      ...state,
      sttBackgroundColor: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_SYNC_TEXT_AND_PLAYER) {
    const settings: SettingsState = {
      ...state,
      sttSyncTextAndPlayer: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_SHOW_TRANSLATE) {
    const settings: SettingsState = {
      ...state,
      sttShowTranslate: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_EDITE_MODE) {
    const settings: SettingsState = {
      ...state,
      editMode: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_STT_CONFIDENCE_HIGHLIGHT) {
    const settings: SettingsState = {
      ...state,
      confidenceHighlight: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_RESULT_PANEL_SIZE) {
    const settings: SettingsState = {
      ...state,
      resultPanelSize: payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_PLAY_ONLY_VOICE_SEGMENTS) {
    const settings: SettingsState = {
      ...state,
      playerPlayOnlyVoiceSegments: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_SORT_NAME) {
    const settings: SettingsState = {
      ...state,
      sortName: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_SORT_ORDER) {
    const settings: SettingsState = {
      ...state,
      sortOrder: payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_DIARIZATION_HIGHLIGHT) {
    const settings: SettingsState = {
      ...state,
      diarizationHighlight: payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SET_SHOW_SEGMENTS) {
    const settings: SettingsState = {
      ...state,
      showSegments: action.payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SET_PLAYBACK_SPEED) {
    const settings: SettingsState = {
      ...state,
      playbackSpeed: action.payload as number,
    };
    save(settings);
    return settings;
  }

  if (type === SET_AUTOPLAY) {
    const settings: SettingsState = {
      ...state,
      autoplay: action.payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SET_ACTIVECHANNELS) {
    const settings: SettingsState = {
      ...state,
      activeChannels: action.payload as ChannelState,
    };
    save(settings);
    return settings;
  }

  if (type === SET_LOOPING) {
    const settings: SettingsState = {
      ...state,
      looping: action.payload as boolean,
    };
    save(settings);
    return settings;
  }

  if (type === SET_RATIO_RATE) {
    const settings: SettingsState = {
      ...state,
      ratioRate: action.payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SET_APP_FILTER) {
    const settings: SettingsState = {
      ...state,
      appFilter: action.payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SET_DICTIONARY) {
    const settings: SettingsState = {
      ...state,
      dictionary: action.payload as Dictionary[],
    };
    save(settings);
    return settings;
  }

  if (type === SET_AUDIO_SRC) {
    const settings: SettingsState = {
      ...state,
      audioSrc: action.payload as string,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_THEME_PRIMARY_COLOR) {
    const settings: SettingsState = {
      ...state,
      themePrimaryColor: payload as string,
      // Можно автоматически вычислить secondaryColor
      themeSecondaryColor: getSecondaryColor(payload as string),
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_THEME_TYPOGRAPHY) {
    const typographyPayload = payload as { fontFamily: string };

    const settings: SettingsState = {
      ...state,
      themeFontFamily: typographyPayload.fontFamily,
    };
    save(settings);
    return settings;
  }

  if (type === SETTINGS_SET_THEME_COMPLETE) {
    const { primaryColor, fontFamily } = payload as { primaryColor: string; fontFamily: string };
    const settings: SettingsState = {
      ...state,
      themePrimaryColor: primaryColor,
      themeSecondaryColor: getSecondaryColor(primaryColor),
      themeFontFamily: fontFamily,
    };
    save(settings);
    return settings;
  }

  return state;
};
