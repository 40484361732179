import React, { FC, useMemo } from "react";
import { AdminTech } from "types/admin";
import {
  TaskTech,
  TaskTechDiarization,
  TaskTechLid,
  TaskTechSid,
  TaskTechStt,
  TaskTechVad,
  TechType,
} from "types/task";
import { getDefaultTaskTech } from "functions/task";

// material ui
import Badge from "@material-ui/core/Badge";
import SettingsIcon from "@material-ui/icons/Settings";

interface Props {
  adminTech: AdminTech;
  technologies: TaskTech[];
}

// количество настроек технологии которые отличаются от настроек по умолчанию
const TechBadge: FC<Props> = ({ adminTech, technologies }) => {
  const badgeContent = useMemo(() => {
    const taskTech = technologies.find((t) => t.id === adminTech.id);
    if (taskTech === undefined) return null;

    let count = 0;

    if (taskTech.type === TechType.STT) {
      const defaultTaskTech = getDefaultTaskTech(adminTech) as TaskTechStt;
      if (defaultTaskTech.lang !== taskTech.lang) count++;
      if (defaultTaskTech.isEnableWord2Num !== taskTech.isEnableWord2Num) count++;
      if (defaultTaskTech.languageModelId !== taskTech.languageModelId) count++;
    }

    if (taskTech.type === TechType.DIARIZATION) {
      const defaultTaskTech = getDefaultTaskTech(adminTech) as TaskTechDiarization;
      if (defaultTaskTech.speakerCount !== taskTech.speakerCount) count++;
    }

    if (taskTech.type === TechType.LID) {
      const defaultTaskTech = getDefaultTaskTech(adminTech) as TaskTechLid;
      if (defaultTaskTech.langs.length !== taskTech.langs.length) count++;
    }

    if (taskTech.type === TechType.VAD) {
      const defaultTaskTech = getDefaultTaskTech(adminTech) as TaskTechVad;
      if (defaultTaskTech.vadType !== taskTech.vadType) count++;
    }

    if (taskTech.type === TechType.SID) {
      const defaultTaskTech = getDefaultTaskTech(adminTech) as TaskTechSid;
      if (defaultTaskTech.ids.length !== taskTech.ids.length) count = taskTech.ids.length;
    }
    return count;
  }, [adminTech, technologies]);

  return (
    <Badge badgeContent={badgeContent} color="secondary">
      <SettingsIcon fontSize="inherit" />
    </Badge>
  );
};

export default TechBadge;
