import React, { useState, useEffect, useRef, FC, ChangeEvent } from "react";
import useWords from "hooks/useWords";

// material ui
import { Dialog, DialogContent, DialogActions, Button, Tabs, Tab, Input } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";

//components
import ListSearchSection from "./wordsFilter/ListSearchSection";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 100,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  sectionButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  ml5: {
    marginLeft: "5px",
  },
  mb16: {
    marginBottom: theme.spacing(2),
  },
  marginTopLeft16: {
    margin: "16px 0px 0px 16px",
  },
  paddingRight0: {
    paddingRight: "0px",
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sectionWrapper: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  faBackground: {
    backgroundColor: "#fafafa",
  },
  flex: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
  input: {
    display: "flex",
    alignItems: "flex-start",
    border: "1px solid #ced4da",
    padding: "10px 12px",
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    width: "100%",
    minHeight: "calc(100vh - 469px)",
    marginTop: "8px",
    resize: "none",
    verticalAlign: "top",
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "start",
    },
  },
}));

export interface SearchSettings {
  strong: string[];
  phrase: string[];
  vector: string[];
  dictionary: string[];
}

interface InputDialogWordSearchProps {
  onClose: (searchString: string, actionType: "save" | "cancel" | "closeOutside") => void;
  value: string;
}

const parseSearchString = (value: string): SearchSettings => {
  try {
    return JSON.parse(value);
  } catch {
    return {
      strong: [],
      vector: [],
      phrase: [],
      dictionary: [],
    };
  }
};

// Функция для парсинга введенных строк на вектора и фразы
const splitLinesToWordsAndPhrases = (lines: string[]): { wordsArray: string[]; phraseArray: string[] } => {
  const wordsArray: string[] = [];
  const phraseArray: string[] = [];

  lines.forEach((line) => {
    const words = line.trim().split(/\s+/);
    if (words.length > 1) {
      // Если больше одного слова - это фраза
      phraseArray.push(line.trim());
    } else {
      // Если одно слово - добавляем в массив слов
      wordsArray.push(line.trim());
    }
  });

  return { wordsArray, phraseArray };
};

const InputDialogWordsTab: FC<InputDialogWordSearchProps> = ({ onClose, value }) => {
  const classes = useStyles();
  const [searchSettings, setSearchSettings] = useState<SearchSettings>(parseSearchString(value));
  const { words } = useWords();
  const [activeTab, setActiveTab] = useState<"vector" | "strong" | "dictionary">("vector");
  const [inputValue, setInputValue] = useState("");
  const [inputStrongValue, setInputStrongValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  // Сохранение (кнопка "ОК")
  const handleSave = () => {
    const vectorLines = inputValue.split("\n").filter((item) => item.trim() !== "");
    const strongLines = inputStrongValue.split("\n").filter((item) => item.trim() !== "");

    const vectorResult = splitLinesToWordsAndPhrases(vectorLines);
    // Если парсить фразы из прямого вхождения и проверка на дубликаты:

    // const strongResult = splitLinesToWordsAndPhrases(strongLines);

    // Удаляем дубликаты фраз
    // const uniquePhrases = Array.from(new Set([
    //   ...vectorResult.phraseArray,
    //   ...strongResult.phraseArray
    // ]));

    const newFilter = {
      ...searchSettings,
      vector: vectorResult.wordsArray,
      // strong: strongResult.wordsArray,
      strong: strongLines,
      // phrase: uniquePhrases,
      phrase: vectorResult.phraseArray,
    };

    setSearchSettings(newFilter);
    onClose(JSON.stringify(newFilter), "save");
  };

  // Отмена (кнопка "Отмена")
  const handleCancel = () => {
    // Возвращаем исходное значение фильтра при нажатии на "Отмена"
    onClose(JSON.stringify(searchSettings), "cancel");
  };

  // Обработка изменений списка ключевых слов
  const handleSearchSettingsChange = (newSettings: string[]) => {
    setSearchSettings((prevSettings) => ({
      ...prevSettings,
      dictionary: newSettings,
    }));
  };

  // Обработка изменения в инпуте
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  // Обработка изменения в инпуте
  const handleChangeStrong = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputStrongValue(event.target.value);
  };

  // Инициализация значений из prop value
  useEffect(() => {
    const parsedSettings = parseSearchString(value);
    setSearchSettings(parsedSettings);

    setInputValue([...parsedSettings.vector, ...parsedSettings.phrase].join("\n"));
    setInputStrongValue(parsedSettings.strong.join("\n"));

    if (parsedSettings.vector.length > 0 || parsedSettings.phrase.length > 0) {
      setActiveTab("vector");
    } else if (parsedSettings.strong.length > 0) {
      setActiveTab("strong");
    } else if (parsedSettings.dictionary.length > 0) {
      setActiveTab("dictionary");
    }
  }, [value]);

  return (
    <Dialog open={true} maxWidth="lg" fullWidth onClose={() => onClose("", "closeOutside")}>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Однокоренные" value="vector" />
          <Tab label="Прямое вхождение" value="strong" />
          <Tab label="Список ключевых слов" value="dictionary" />
        </Tabs>
        {activeTab === "vector" && (
          <Input
            id="standard-multiline-flexible"
            placeholder="Ключевые слова"
            multiline
            fullWidth
            autoFocus
            className={classes.input}
            value={inputValue}
            onChange={handleChange}
            inputRef={inputRef}
            onKeyDown={(e) => e.stopPropagation()}
          />
        )}
        {activeTab === "strong" && (
          <Input
            id="standard-multiline-flexible"
            placeholder="Ключевые слова"
            multiline
            fullWidth
            autoFocus
            className={classes.input}
            value={inputStrongValue}
            onChange={handleChangeStrong}
            inputRef={inputRef}
            onKeyDown={(e) => e.stopPropagation()}
          />
        )}
        {activeTab === "dictionary" && (
          <ListSearchSection
            words={words}
            handleSearchSettingsChange={handleSearchSettingsChange}
            idToSelect={searchSettings.dictionary}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" onClick={handleCancel} className={classes.button}>
          Отмена
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleSave}
          className={classes.button}
          disabled={
            inputValue.length < 1 &&
            searchSettings.vector.length < 1 &&
            searchSettings.dictionary.length < 1 &&
            searchSettings.strong.length < 1 &&
            inputStrongValue.length < 1
          }
        >
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputDialogWordsTab;
