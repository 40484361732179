import React, { ChangeEvent, FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import clsx from "clsx";
import {
  setPlayerIsEcho,
  setPlayerIsAdeClick,
  setPlayerIsKTCH,
  setPlayerIsNoise,
  setPlayerIsNormalize,
  setPlayerIsPan,
  setPlayOnlyVoiceSegments,
  setSttSyncTextAndPlayer,
  setShowSegments,
  setAutoPlay,
  setLoopingEnable,
} from "redux/actions/settingsActions";
import InfoDialog from "./InfoDialog";

// icons
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// material ui
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";
import { Divider } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    settings: {
      width: 400,
      padding: theme.spacing(2),
      margin: 0,
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    button: {},
    popover: {
      overflow: "hidden",
    },
    colorGrey: {
      color: grey[600],
    },
    diff: {
      width: 3,
      height: 15,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
      marginLeft: -13,
    },
    item: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    hidden: {
      display: "none",
    },
    btn: {
      cursor: "pointer",
      color: "white",
      backgroundColor: theme.palette.primary.main,
      width: 40,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 5,
      borderRadius: 5,
      "&:hover": {
        backgroundColor: darken(theme.palette.primary.main, 0.15), // Затемняем основной цвет на 15%
      },
    },
  })
);

const Diff: FC<{ hidden: boolean }> = ({ hidden }) => {
  const classes = useStyles();
  return <div className={clsx(classes.diff, { [classes.hidden]: !hidden })} />;
};

const PlayerSettings: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerPlayOnlyVoiceSegments,
    playerGain,
    playerWaveformType,
    sttSyncTextAndPlayer,
    showSegments,
    autoplay,
    looping,
  } = useSelector((state: RootState) => state.settings);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    event.currentTarget.blur();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const payload = checked ? 1 : 0;
    if (name === "isPan") {
      dispatch(setPlayerIsPan(payload));
    }
    if (name === "isAdeClick") {
      dispatch(setPlayerIsAdeClick(payload));
    }
    if (name === "isNormalize") {
      dispatch(setPlayerIsNormalize(payload));
    }
    if (name === "isNoise") {
      dispatch(setPlayerIsNoise(payload));
    }
    if (name === "isKTCH") {
      dispatch(setPlayerIsKTCH(payload));
    }
    if (name === "isEcho") {
      dispatch(setPlayerIsEcho(payload));
    }
    if (name === "playerPlayOnlyVoiceSegments") {
      dispatch(setPlayOnlyVoiceSegments(checked));
    }
    if (name === "showSegments") {
      dispatch(setShowSegments(checked));
    }
    if (name === "sttSyncTextAndPlayer") {
      dispatch(setSttSyncTextAndPlayer(checked));
    }
    if (name === "autoplay") {
      dispatch(setAutoPlay(checked));
    }
    if (name === "looping") {
      dispatch(setLoopingEnable(checked));
    }
  };

  const countNotDefaultSettings = useMemo(() => {
    let count = 0;
    if (playerIsPan !== 0) {
      count += 1;
    }
    if (playerIsAdeClick !== 0) {
      count += 1;
    }
    if (playerIsNoise) {
      count += 1;
    }
    if (playerIsKTCH !== 0) {
      count += 1;
    }
    if (playerIsEcho !== 0) {
      count += 1;
    }
    if (playerGain !== 1) {
      count += 1;
    }
    if (playerPlayOnlyVoiceSegments) {
      count += 1;
    }
    if (playerWaveformType !== "waveform") {
      count += 1;
    }
    if (showSegments !== false) {
      count += 1;
    }
    if (sttSyncTextAndPlayer !== false) {
      count += 1;
    }
    if (autoplay !== false) {
      count += 1;
    }
    if (looping !== false) {
      count += 1;
    }
    return count;
  }, [
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerGain,
    playerPlayOnlyVoiceSegments,
    playerWaveformType,
    showSegments,
    sttSyncTextAndPlayer,
    autoplay,
    looping,
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const handleOpenInfoDialog = (filter: string) => {
    setSelectedFilter(filter);
    setIsOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div onClick={handleClick} className={classes.btn} title="Настройки">
        <Badge badgeContent={countNotDefaultSettings} color="secondary" max={1000}>
          <SettingsIcon />
        </Badge>
      </div>

      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container spacing={2} className={classes.settings}>
          <Grid item xs={12}>
            <div className={classes.container}>
              <Diff hidden={sttSyncTextAndPlayer !== false} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sttSyncTextAndPlayer}
                    onChange={handleCheckbox}
                    color="primary"
                    name="sttSyncTextAndPlayer"
                  />
                }
                label="Синхронизировать текст и аудио"
              />
            </div>
            <div className={classes.container}>
              <Diff hidden={showSegments !== false} />
              <FormControlLabel
                control={
                  <Checkbox checked={showSegments} onChange={handleCheckbox} color="primary" name="showSegments" />
                }
                label="Показывать сегменты речи"
              />
            </div>
            {/* <div className={classes.container}>
              <Diff hidden={looping !== false} />
              <FormControlLabel
                control={
                  <Checkbox checked={looping} onChange={handleCheckbox} color="primary" name="loopingEnabled" />
                }
                label="Автоматический повтор"
              />
            </div> */}
            <div className={classes.container}>
              <Diff hidden={autoplay !== false} />
              <FormControlLabel
                control={<Checkbox checked={autoplay} onChange={handleCheckbox} color="primary" name="autoplay" />}
                label="Автоматическое воспроизведение"
              />
            </div>
            <div className={classes.container}>
              <Diff hidden={playerPlayOnlyVoiceSegments !== false} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={playerPlayOnlyVoiceSegments}
                    onChange={handleCheckbox}
                    color="primary"
                    name="playerPlayOnlyVoiceSegments"
                  />
                }
                label="Воспроизведение без пауз"
              />
            </div>
            <Divider />
            <div className={classes.container}>
              <Diff hidden={playerIsEcho !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsEcho === 1} onChange={handleCheckbox} color="primary" name="isEcho" />
                  }
                  label="Эхо компенсация"
                />
                <IconButton className={classes.button} onClick={() => handleOpenInfoDialog("Эхо компенсация")}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerIsNoise !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsNoise === 1} onChange={handleCheckbox} color="primary" name="isNoise" />
                  }
                  label="Фильтр широкополосного шума"
                />
                <IconButton
                  className={classes.button}
                  onClick={() => handleOpenInfoDialog("Фильтр широкополосного шума")}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerIsAdeClick !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={playerIsAdeClick === 1}
                      onChange={handleCheckbox}
                      color="primary"
                      name="isAdeClick"
                    />
                  }
                  label="Фильтр импульсных помех"
                />
                <IconButton className={classes.button} onClick={() => handleOpenInfoDialog("Фильтр импульсных помех")}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.container}>
              <Diff hidden={playerIsKTCH !== 0} />
              <div className={classes.item}>
                <FormControlLabel
                  control={
                    <Checkbox checked={playerIsKTCH === 1} onChange={handleCheckbox} color="primary" name="isKTCH" />
                  }
                  label="Фильтр канала тональной частоты"
                />
                <IconButton
                  className={classes.button}
                  onClick={() => handleOpenInfoDialog("Фильтр канала тональной частоты")}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </Popover>
      {isOpen && <InfoDialog onClose={handleCloseInfoDialog} selectedFilter={selectedFilter} />}
    </div>
  );
};

export default PlayerSettings;
