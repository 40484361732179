import React, { FC, useMemo, useState } from "react";
import { Keywords as KeywordsType, Record } from "types/record";
import { GridApi } from "ag-grid-community";
import { currTimeSubscriber } from "subscribers/PlayerSubscriber";
import useKeywords from "hooks/records/useKeywords";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import KeywordsTable from "../tabSTT/KeywordsTable";

interface Props {
  selectedRecord: Record;
  filterv2: string;
}

const TabKeywords: FC<Props> = ({ selectedRecord, filterv2 }) => {
  const { settings } = useSelector((state: RootState) => state);

  const { playerChannel } = settings;

  // query string для ключевых слов
  const keywordsQueryString = useMemo(() => `?filterv2=${filterv2}&channel=${playerChannel}`, [
    playerChannel,
    filterv2,
  ]);
  // ключевые слова
  const { keywords } = useKeywords(selectedRecord.id, keywordsQueryString);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const onClicked = () => {
    if (gridApi === undefined) return;
    const rows = gridApi.getSelectedRows();
    const kw: KeywordsType = rows[0];
    const { start } = kw.words[0];
    currTimeSubscriber.next(start);
  };

  return <KeywordsTable rowData={keywords} setGridApi={setGridApi} onClicked={onClicked} />;
};

export default TabKeywords;
