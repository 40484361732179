import React, { FC, useCallback } from "react";
import { setPageNbsCategoryTableSettings } from "redux/actions/pageSettingsActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import { ColumnResizedEvent } from "ag-grid-community";

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "№",
    field: "id",
    width: 100,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: "Имя",
    field: "name",
    flex: 1,
  },
  {
    headerName: "Комментарий",
    field: "comment",
    width: 400,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

interface Props {
  rowData: any[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  height: string | number;
}

const NbsCategoryTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection, height }) => {
  const dispatch = useDispatch();
  const { nbsCategory: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: any) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageNbsCategoryTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div className={"ag-theme-balham"} style={{ height }}>
      <AgGridReact
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        suppressMovableColumns
        suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        frameworkComponents={{
          DateRenderer,
        }}
      />
    </div>
  );
};

export default NbsCategoryTable;
