import React, { FC, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { drawerClose, drawerOpen } from "redux/actions/drawerActions";

// components
import AdminNavigation from "layouts/admin/navigation/AdminNavigation";
import AdminHeader from "layouts/admin/header/AdminHeader";
import Notification from "components/Notification";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

// icons
import TwitchCloseIcon from "components/icons/TwitchCloseIcon";
import TwitchOpenIcon from "components/icons/TwitchOpenIcon";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import ErrorBoundary from "components/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "64px 1fr",
    gridTemplateColumns: "auto 1fr",
    height: "100vh",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  aside: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    gridColumn: "1 / 2",
    borderRight: "1px solid #bdc3c7",
  },
  header: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgb(29,145,136)",
    // background: "linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)",
    background:
      theme.palette.primary.gradient ?? "linear-gradient(90deg, rgba(29,145,136,1) 0%, rgba(77,182,172,1) 50%)",
    gridColumn: "1 / 3",
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  navigation: {},
  content: {
    boxSizing: "border-box",
    overflow: "hidden",
    gridColumn: "2 / 3",
    padding: 16,
  },
  toggleButton: {
    height: 45,
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    cursor: "pointer",
  },
  menuTitle: {
    margin: 0,
    padding: 0,
  },
  alert: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "30vw",
    zIndex: theme.zIndex.drawer + 10,
  },
  sidebarOpen: {
    width: 280,
  },
  sidebarClosed: {
    width: 56,
    gridColumn: "1 / 2",
  },
}));

interface Props {
  children: ReactNode;
}

const AdminLayout: FC<Props> = ({ children }) => {
  const widthOpen = 370;
  const widthClose = 56;
  const classes = useStyles();
  const open = useSelector((state: RootState) => state.drawer.open);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(drawerOpen());
  };

  const handleDrawerClose = () => {
    dispatch(drawerClose());
  };

  const handleOpen = () => {
    open ? handleDrawerClose() : handleDrawerOpen();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <header className={classes.header}>
        <AdminHeader />
      </header>

      <aside
        style={{
          width: open ? widthOpen : widthClose,
        }}
        className={clsx(classes.aside, "app_aside", open ? classes.sidebarOpen : classes.sidebarClosed)}
      >
        <div
          onClick={handleOpen}
          className={classes.toggleButton}
          style={{ justifyContent: open ? "flex-end" : "center" }}
        >
          {open ? <TwitchCloseIcon /> : <TwitchOpenIcon />}
        </div>
        <div className={classes.navigation}>
          <AdminNavigation />
        </div>
      </aside>

      <main className={clsx(classes.content, "main-content")}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>

      <Notification />
    </div>
  );
};

export default AdminLayout;
