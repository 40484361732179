import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Records from "./records/Records";
import { AdminTypeFilter } from "types/admin";
import { RecordDetail, Record, RecordFileViewType } from "types";
import { Group } from "types/queryBuilder";
import useQueryString from "hooks/useQueryString";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import AccessService from "services/admin/AccessService";
import { useDispatch, useSelector } from "react-redux";
import {
  setRemoveRecordsAccess,
  setShowAutoInformatorsName,
  setShowNameSpaceName,
  setShowSpeakersName,
} from "redux/actions/accessActions";
import { generateUuid } from "functions/common";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import ResultDetail from "./resultDetail/ResultDetail";
import Search from "components/search/Search";
import PlayerWrapper from "./player/PlayerWrapper";
import { RootState } from "redux/types";
import useRecords from "hooks/records/useRecords";
import { GridApi } from "ag-grid-community";
import { setRatioRate } from "redux/actions/settingsActions";

// Стили для компонентов
const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "80px 1fr 125px",
    height: "100%",
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: "16px",
  },
  splitWrapper: {
    display: "flex",
    overflow: "hidden",
    transition: "height 0.3s ease",
  },
  records: {
    flex: 1,
    overflowY: "auto",
    transition: "height 0.3s ease",
    marginLeft: 16,
    borderTop: "1px solid #bdc3c7",
  },
  detail: {
    overflowY: "auto",
    transition: "height 0.3s ease",
    marginRight: 16,
  },
  superPlayerWrapper: {
    transition: "height 0.3s ease, left 0.3s ease",
  },
  ratioButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  ratioBorderColor: {
    borderColor: theme.palette.primary.main,
  },
  player: {
    boxShadow: "0px -3px 10px 0px rgba(34, 60, 80, 0.2)",
    backgroundColor: "#f2f2f2",
  },
}));

interface Props {
  filterString: string;
  availableFilters: AdminTypeFilter[];
}

const Results: FC<Props> = ({ filterString, availableFilters }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  const [filter, setFilter] = useState<Group>(defaultFilter);

  const [recordDetail, setRecordDetail] = useState<RecordDetail | undefined>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<Record | undefined>(undefined);

  const basis = useSelector((state: RootState) => state.settings.ratioRate);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const { rowsPerPage: perPage, sortName, sortOrder, editMode } = useSelector((state: RootState) => state.settings);
  const [offset, setOffset] = useState(0);
  const queryString = useMemo(() => `?filterv2=${filterString}&sortName=${sortName}&sortOrder=${sortOrder}`, [
    filterString,
    sortName,
    sortOrder,
  ]);

  const { records, getRecords, loading, error } = useRecords(offset, perPage, queryString);
  const [showRecordFiles, setShowRecordFiles] = useState(false);

  // запустит поиск по фильтру
  const handleSearch = (filter: Group) => {
    history.push(routes.results.path + "?f=" + JSON.stringify(filter));
  };

  // Для изменения типа отображеия
  const [currentViewType, setCurrentViewType] = useState<RecordFileViewType>(RecordFileViewType.AUDIO);

  const handleRatioChange = (newRatio: string) => {
    dispatch(setRatioRate(newRatio));
  };

  const getPrevRow = useCallback(
    (record: Record) => {
      let prevRec = undefined;
      for (let i = 0; i < records.length; i++) {
        const curr = records[i];
        if (curr.id === record.id && i !== 0) {
          prevRec = records[i - 1];
          break;
        }
      }
      return prevRec;
    },
    [records]
  );

  const getNextRow = useCallback(
    (record: Record) => {
      let nextRec = undefined;
      for (let i = 0; i < records.length; i++) {
        const curr = records[i];
        if (curr.id === record.id && i !== records.length - 1) {
          nextRec = records[i + 1];
          break;
        }
      }
      return nextRec;
    },
    [records]
  );

  const selectRow = useCallback(
    (row: Record) => {
      if (gridApi) {
        const rowNode = gridApi.getRowNode(String(row.id));
        gridApi.deselectAll();
        setShowRecordFiles(false);
        rowNode.setSelected(true);
      }
    },
    [gridApi]
  );

  const selectNextOrPrevRow = useCallback(
    (der: "prev" | "next") => {
      if (gridApi) {
        const rows = gridApi.getSelectedRows();
        if (rows.length > 0) {
          const row = rows[0];
          const nextRow = der === "next" ? getNextRow(row) : getPrevRow(row);
          if (nextRow) selectRow(nextRow);
        }
      }
    },
    [gridApi, getNextRow, getPrevRow, selectRow]
  );

  useEffect(() => {
    if (recordDetail) {
      setCurrentViewType(recordDetail.viewType);
    }
  }, [recordDetail]);

  useEffect(() => {
    try {
      const filter: Group = JSON.parse(filterString);
      setFilter(filter);
    } catch (e) {}
  }, [filterString]);

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <div style={{ flexGrow: 1 }}>
          <Search
            filterString={filterString}
            handleSearch={handleSearch}
            availableFilters={availableFilters}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      </div>

      <div className={classes.splitWrapper}>
        <div className={classes.records} style={{ flex: `3 3 ${basis}` }}>
          <Records
            setFilter={setFilter}
            availableFilters={availableFilters}
            filterString={filterString}
            recordDetail={recordDetail}
            setRecordDetail={setRecordDetail}
            setSelectedRecord={setSelectedRecord}
            gridApi={gridApi}
            setGridApi={setGridApi}
            setOffset={setOffset}
            queryString={queryString}
            records={records}
            getRecords={getRecords}
            showRecordFiles={showRecordFiles}
            setShowRecordFiles={setShowRecordFiles}
            selectNextOrPrevRow={selectNextOrPrevRow}
            editMode={editMode}
            error={error}
            loading={loading}
          />
        </div>
        <div className={classes.detail} style={{ flex: `1 1 ${basis}` }}>
          <ResultDetail
            recordDetail={recordDetail}
            selectedRecord={selectedRecord}
            filterString={filterString}
            viewType={currentViewType}
            onRatioChange={handleRatioChange}
            onViewTypeChange={setCurrentViewType}
            currentRatio={basis}
          />
        </div>
      </div>
      <div className={classes.player}>
        <PlayerWrapper
          recordDetail={recordDetail}
          filterv2={filterString}
          record={selectedRecord}
          selectNextOrPrevRow={selectNextOrPrevRow}
        />
      </div>
    </div>
  );
};

// получть query параметры
const GetQueryParams: FC<{ availableFilters: AdminTypeFilter[] }> = ({ availableFilters }) => {
  const [filterString, setFilterString] = useState<undefined | string>(undefined);
  const queryParams = useQueryString();

  // проверка на наличие query параметра f
  useEffect(() => {
    const filterString = queryParams.get("f");

    if (filterString !== null) {
      setFilterString(filterString);
    }
    if (filterString === null) {
      setFilterString("");
    }
  }, [queryParams]);

  if (filterString === undefined) {
    return null;
  }
  return <Results filterString={filterString} availableFilters={availableFilters} />;
};

const GetAvailableFilters: FC = () => {
  const dispatch = useDispatch();
  const { filters, loading } = useAvailableFilters("records");

  useEffect(() => {
    AccessService.getUserAccessRemoveRecords().then(({ data }) => {
      dispatch(setRemoveRecordsAccess(data.isCanDelete));
      dispatch(setShowNameSpaceName(data.isShowNameSpaceName));
      dispatch(setShowSpeakersName(data.isShowSpeakersName));
      dispatch(setShowAutoInformatorsName(data.isShowAutoInformatorsName));
    });
  }, [dispatch]);

  if (loading) {
    return null;
  }
  return <GetQueryParams availableFilters={filters} />;
};

export default GetAvailableFilters;
