import React, { FC, useState, useEffect } from "react";
import { Word } from "types/word";
import clsx from "clsx";

// material ui
import { Theme, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Sort as SortIcon } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  sectionWrapper: {
    flex: 1,
  },
  ml10: {
    marginLeft: 10,
  },
  search: {
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    fontSize: 14,
  },
  textBlock: {
    whiteSpace: "pre-line",
    fontSize: "1rem",
    fontFamily: "Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
}));

interface Props {
  open: boolean;
  onClose: (data?: Word) => void;
  words: string;
}

const ShowWordsDialog: FC<Props> = ({ open, onClose, words }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [filteredWords, setFilteredWords] = useState<string[]>([]);

  useEffect(() => {
    const wordsArray = words.split("\n");
    let filtered = wordsArray.filter((word) => word.toLowerCase().includes(searchTerm.toLowerCase()));
    filtered.sort((a, b) => {
      if (sortAscending) {
        return a.localeCompare(b);
      } else {
        return b.localeCompare(a);
      }
    });
    setFilteredWords(filtered);
  }, [words, searchTerm, sortAscending]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSortToggle = () => {
    setSortAscending(!sortAscending);
  };

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.root} fullWidth maxWidth="lg">
      <DialogTitle>Ключевые слова</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.searchWrapper}>
              <TextField
                className={classes.search}
                fullWidth
                variant="outlined"
                placeholder="Поиск..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  className: classes.searchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={handleSortToggle} style={{ borderRadius: "10px", marginLeft: "8px" }}>
                <SortIcon />
                {sortAscending ? "А" : "Я"}
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} style={{ height: "calc(100vh - 284px)" }}>
            <div className={classes.sectionWrapper}>
              <div className={clsx(classes.textBlock, "words-container")}>{filteredWords.join("\n")}</div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={() => onClose()}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ShowWordsDialog;
